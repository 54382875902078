import React, { Fragment, useState } from "react";
import { Modal } from "../../../components";
import { gql, useQuery } from "@apollo/client";
import { useUser } from "../../../user-context";
import { NumberInput } from "@tremor/react";
import fuzzysort from "fuzzysort";
import {
  GetLocationChargeTemplates,
  GetLocationChargeTemplatesVariables,
  GetLocationChargeTemplates_chargeTemplates as ChargeTemplate,
} from "../../../generated/GetLocationChargeTemplates";
import {
  GetEstimateFormData,
  GetEstimateFormDataVariables,
  GetEstimateFormData_appointment as Appointment,
  GetEstimateFormData_appointment_estimates_estimatedCharges as EstimatedCharge,
  GetEstimateFormData_appointment_estimates as Estimate,
} from "../../../generated/GetEstimateFormData";
import { GetSaltedAppointment_appointment as SaltedAppointment } from "../../../generated/GetSaltedAppointment";
import {
  classNames,
  cn,
  formatRelativeDay,
  formatUSD,
  formatDateMMDDYYYY,
  isDefined,
  mapNullable,
} from "../../../utils";
import { OvalSpinner } from "../../../components/loading";
import {
  CurrencyDollarIcon,
  PencilAltIcon,
  XIcon,
} from "@heroicons/react/outline";
import { Transition, Dialog, Combobox } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { format, parseISO } from "date-fns";
import { CreateEstimateWithoutChargesForm } from "../../worklists/policies/create-estimate-task";
import {
  APPOINTMENT_LIST_FRAGMENT,
  COVERAGE_BENEFIT_FIELDS,
  ESTIMATE_FIELDS,
  INSURANCE_POLICY_SUMMARY_FIELDS,
  VISIT_COLLECTION_REQUEST_FIELDS,
} from "../../../graphql";
import { Tab } from "@headlessui/react";
import { PatientSummary } from "../../patients/profile";
import { EstimateForm } from "./estimate-form";
import { GET_FEESCHEDULES } from "../../fee-schedules";
import {
  GetFeeSchedules,
  GetFeeSchedulesVariables,
  GetFeeSchedules_feeSchedules as FeeSchedule,
} from "../../../generated/GetFeeSchedules";
import {
  GetLocationChargemasterGroups,
  GetLocationChargemasterGroups_chargemasterGroupsByLastUsed_chargemasterGroup as ChargemasterGroup,
} from "../../../generated/GetLocationChargemasterGroups";
import { Badge } from "../../../components/ui/badge";
import { SyncStatusBadge } from "../../estimations/columns";

export const EstimateDollarInput = React.forwardRef<
  HTMLInputElement,
  React.ComponentProps<typeof NumberInput>
>((props, ref) => {
  const notDefined =
    // @ts-expect-error
    !isDefined(ref?.current?.value) || ref?.current?.value === "";
  const error = props.required && notDefined;
  return (
    <NumberInput
      ref={ref}
      className={cn(
        "min-w-[4rem] [&>input]:!px-1",
        notDefined && "bg-gray-100"
      )}
      placeholder=""
      error={error}
      icon={() => (
        <CurrencyDollarIcon className="ml-2 tremor-NumberInput-icon shrink-0 text-tremor-content-subtle dark:text-dark-tremor-content-subtle h-5 w-5" />
      )}
      defaultValue={props.defaultValue ?? "0.00"}
      step="0.01"
      min={0}
      onValueChange={(val) => {
        props.onValueChange?.(val);
      }}
      enableStepper={false}
      {...props}
    />
  );
});

export const GET_ESTIMATE_FORM_DATA = gql`
  ${COVERAGE_BENEFIT_FIELDS}
  ${VISIT_COLLECTION_REQUEST_FIELDS}
  ${INSURANCE_POLICY_SUMMARY_FIELDS}
  query GetEstimateFormData($appointmentId: String!) {
    appointment(where: { id: $appointmentId }) {
      id
      start
      end
      appointmentLabelings {
        id
        appointmentLabel {
          id
          name
        }
      }
      account {
        id
        accountType {
          id
          name
        }
        patient {
          id
          displayName
          dateOfBirth
          birthSex
        }
      }
      provider {
        id
        displayName
      }
      recommendedDeposit {
        type
        amount
        rule {
          id
          name
          providerServiceConfiguration {
            id
          }
        }
      }
      recommendedChargeTemplate {
        id
        name
      }
      recommendedFeeSchedule {
        id
        name
      }
      recommendedSaltingCharges {
        saltedAppointmentId
        saltedBillId
        charges {
          id
          customCode
          chargemaster {
            id
            code
            modifier1
            modifier2
            modifier3
            modifier4
            description
            chargemasterGroupId
          }
          units
          allowedAmount
        }
      }
      bill {
        id
        charges {
          id
          customCode
          chargemaster {
            id
            code
            modifier1
            modifier2
            modifier3
            modifier4
            description
            chargemasterGroupId
          }
          units
          allowedAmount
        }
      }
      estimates(orderBy: { createdAt: desc }, take: 1) {
        id
        chargeTemplate {
          id
          name
        }
        feeSchedule {
          id
          name
        }
        chargesDifferFromBill
        policiesChanged
        estimatedCharges(orderBy: { priority: asc }) {
          id
          chargemaster {
            id
            code
            modifier1
            modifier2
            modifier3
            modifier4
            description
            chargemasterGroupId
          }
          units
          allowedAmount
          # Benefits
          coinsurance
          copay
          remainingDeductible
          deductibleApplies
          remainingOutOfPocket
          coverageBenefitId
          estimatedInsurancePolicy {
            id
            active
            insurancePolicyId
          }
          scheduledServiceFee {
            id
            name
            feeSchedule {
              id
              name
            }
          }
        }
      }
      insurancePolicies {
        id
        priority
        active
        memberId
        ...InsurancePolicySummaryFields
        payer {
          id
          name
          eligibilityEnabled
        }
        planType {
          id
          insuranceType
          medicareType
          medigapPlanType
          medicaidType
          tricareType
        }
        appointmentInNetwork(appointmentId: $appointmentId)
        # Get coverage benefits for both in and out of network
        inNetworkBenefits: mostRecentCoverageBenefits(inNetwork: true) {
          id
          combinedCoverageBenefit {
            # Don't fetch id otherwise it'll collied with actual coverage benefit
            # id
            copay
            coinsurance
            remainingDeductible
            remainingOutOfPocket
            remainingVisits
            deductibleApplies
            nonCovered
            adjustedRemainingDeductible
            adjustedRemainingOutOfPocket
          }
          providerServiceConfiguration {
            id
            name
          }
          ...CoverageBenefitFields
        }
        outOfNetworkBenefits: mostRecentCoverageBenefits(inNetwork: false) {
          id
          combinedCoverageBenefit {
            # Don't fetch id otherwise it'll collied with actual coverage benefit
            # id
            copay
            coinsurance
            remainingDeductible
            remainingOutOfPocket
            remainingVisits
            deductibleApplies
            nonCovered
            adjustedRemainingDeductible
            adjustedRemainingOutOfPocket
          }
          providerServiceConfiguration {
            id
            name
          }
          ...CoverageBenefitFields
        }
        mostRecentEligibilityRequest {
          id
          createdAt
          automated
          eligible
          allRequestsErrored
          requestedBy {
            id
            firstName
            lastName
          }
          reverificationStatus {
            needsReverification
            reason
            reasonCode
          }
          deduplicatedErrors {
            id
            field
            description
            followupAction
          }
        }
      }
      matchingBenefitMappings {
        insurancePolicy {
          id
        }
        benefitMappings {
          id
          name
          priority
          providerServiceConfiguration {
            id
            name
          }
        }
      }
      mostRecentVisitCollectionRequest {
        ...VisitCollectionRequestFields
        estimate {
          id
          # TODO: When we add the benefits changed alert to the estimate form
          recommendedBenefitsDiffer
        }
      }
      visitCollectionRequests(orderBy: { createdAt: desc }) {
        id
        amount
        lastSavedToIntegrationAt
        savedToIntegration {
          id
          name
        }
        savedToIntegrationBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const CALCULATE_ESTIMATE = gql`
  query CalculateEstimate(
    $inputs: [EstimateCalculationInput!]!
    $policyIds: [EstimatePolicyInput!]!
  ) {
    calculateEstimate(inputs: $inputs, policyIds: $policyIds) {
      insuranceResponsibilities {
        insurancePolicy {
          id
          memberId
          payer {
            id
            name
          }
        }
        insurance
      }
      totalPatientResponsibility
      copayAmount
      coinsuranceAmount
      deductibleAmount
      outOfPocketAmount
      otherAmount
    }
  }
`;

export const CREATE_ESTIMATE = gql`
  ${ESTIMATE_FIELDS}
  ${APPOINTMENT_LIST_FRAGMENT}
  mutation CreateEstimateFromForm(
    $inputs: [EstimateCalculationInput!]!
    $policyIds: [EstimatePolicyInput!]!
    $appointmentId: String!
    $chargeTemplateId: String
    $feeScheduleId: String
    $saltedBillId: String
    $isManual: Boolean
  ) {
    createEstimate(
      inputs: $inputs
      policyIds: $policyIds
      appointmentId: $appointmentId
      chargeTemplateId: $chargeTemplateId
      feeScheduleId: $feeScheduleId
      saltedBillId: $saltedBillId
      isManual: $isManual
    ) {
      estimate {
        id
        status
        type
        ...EstimateFields
        estimatedCharges(orderBy: { priority: asc }) {
          id
          chargemaster {
            id
            code
            modifier1
            modifier2
            modifier3
            modifier4
            chargemasterGroupId
          }
          units
          allowedAmount
          copayAmount
          coinsuranceAmount
          deductibleAmount
          outOfPocketAmount
          insuranceAmount
        }
        appointment {
          ...AppointmentListFragment
        }
      }
    }
  }
`;

export const GET_LOCATION_CHARGEMASTER_GROUPS = gql`
  query GetLocationChargemasterGroups {
    chargemasterGroupsByLastUsed {
      lastUsedAt
      chargemasterGroup {
        id
        code
        modifier1
        modifier2
        modifier3
        modifier4
        description
        primaryChargemaster {
          id
          code
          modifier1
          modifier2
          modifier3
          modifier4
          description
          chargemasterGroupId
        }
      }
    }
  }
`;

const sortChargemasterGroups = ({
  searchQuery,
  chargemasterGroups,
}: {
  searchQuery: string;
  chargemasterGroups: ChargemasterGroup[];
}) => {
  // If search query is empty, return the first 10
  if (searchQuery === "") {
    return chargemasterGroups.slice(0, 10);
  }
  const sorted = fuzzysort
    .go(searchQuery, chargemasterGroups, {
      keys: ["code", "description"],
      threshold: -2500,
      limit: 50,
    })
    .map((val) => val.obj);
  return sorted;
};

export const CodeSearchCommand: React.FC<{
  open: boolean;
  setOpen: (open: boolean) => void;
  onSelect: (chargemasterGroup: ChargemasterGroup) => void;
}> = ({ open, setOpen, onSelect }) => {
  const user = useUser()!;
  const [query, setQuery] = useState("");

  // This works because we dedupe and use the cache
  const { data, loading } = useQuery<
    GetLocationChargemasterGroups,
    GetLocationChargemasterGroups
  >(GET_LOCATION_CHARGEMASTER_GROUPS, {
    fetchPolicy: "cache-first",
  });

  const rows = data?.chargemasterGroupsByLastUsed ?? [];
  const chargemasterGroups = rows.map((r) => ({
    ...r.chargemasterGroup,
    lastUsedAt: r.lastUsedAt,
  }));

  const filtered = sortChargemasterGroups({
    searchQuery: query,
    chargemasterGroups,
  });

  return (
    <Transition.Root
      show={open}
      as={Fragment}
      afterLeave={() => setQuery("")}
      appear
    >
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="flex items-center justify-center fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="min-w-[36rem] transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox
                onChange={(value: ChargemasterGroup) => {
                  onSelect(value);
                  setOpen(false);
                }}
              >
                <div className="relative">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    onChange={(event) => setQuery(event.target.value)}
                  />
                </div>

                {filtered.length > 0 && (
                  <Combobox.Options
                    static
                    className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
                  >
                    {filtered.map((chargemasterGroup) => {
                      const modifiers = [
                        chargemasterGroup.modifier1,
                        chargemasterGroup.modifier2,
                        chargemasterGroup.modifier3,
                        chargemasterGroup.modifier4,
                      ].filter((m) => !!m);
                      return (
                        <Combobox.Option
                          key={chargemasterGroup.id}
                          value={chargemasterGroup}
                          className={({ active }) =>
                            classNames(
                              "cursor-default select-none px-4 py-2 flex justify-between",
                              active && "bg-indigo-600 text-white"
                            )
                          }
                        >
                          <div className="flex flex-col w-full">
                            <div className="flex justify-between">
                              <div className="font-medium">
                                {chargemasterGroup.code}{" "}
                                {modifiers.length > 0 && (
                                  <>({modifiers.join(" | ")})</>
                                )}
                              </div>
                              <div className="text-sm font-light">
                                {chargemasterGroup.description}
                              </div>
                            </div>
                            <div>
                              <span className="text-sm text-gray-300">
                                Last used{" "}
                                {mapNullable((date: string) =>
                                  formatRelativeDay(parseISO(date))
                                )(chargemasterGroup.lastUsedAt) ?? "never"}
                              </span>
                            </div>
                          </div>
                        </Combobox.Option>
                      );
                    })}
                  </Combobox.Options>
                )}

                {query !== "" && filtered.length === 0 && (
                  <p className="p-4 text-sm text-gray-500">No codes found.</p>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const buildInsuranceServiceLines = ({
  policies,
  charges,
  estimatedCharges,
}: {
  policies: { id: string | null }[];
  charges: { chargemaster: Chargemaster; units: string }[];
  estimatedCharges: EstimateCharge[];
}) => {
  const insuranceServiceLines = policies.map((policy) => {
    const policyCharges = estimatedCharges.filter(
      (charge) =>
        (charge.estimatedInsurancePolicy?.insurancePolicyId ?? null) ===
        policy.id
    );
    return {
      insurancePolicyId: policy.id,
      serviceLines: charges.map(({ chargemaster, units }) => {
        const charge = policyCharges.find(
          (c) => c.chargemaster.id === chargemaster.id
        );

        return {
          insurancePolicyId: policy.id,
          chargemaster,
          units: charge?.units ?? units ?? 1,
          charge,
          // description: charge.description,
          coverageBenefitId: charge?.coverageBenefitId ?? null,
          copay: charge?.copay ?? null,
          coinsurance: charge?.coinsurance ?? null,
          remainingDeductible: charge?.remainingDeductible ?? null,
          deductibleApplies: charge?.deductibleApplies ?? null,
          remainingOutOfPocket: charge?.remainingOutOfPocket ?? null,
          allowedAmount: charge?.allowedAmount ?? null,
        };
      }),
    };
  });

  return insuranceServiceLines;
};

export const GET_LOCATION_CHARGE_TEMPLATES = gql`
  query GetLocationChargeTemplates($locationId: String!) {
    chargeTemplates(
      where: { locationId: { equals: $locationId }, pending: { equals: false } }
    ) {
      id
      name
      chargeTemplateCharges(orderBy: { priority: asc }) {
        id
        chargemasterGroup {
          id
          code
          modifier1
          modifier2
          modifier3
          modifier4
          description
          primaryChargemaster {
            id
            code
            modifier1
            modifier2
            modifier3
            modifier4
            description
            chargemasterGroupId
          }
        }
        units
      }
    }
  }
`;

type EstimateCharge = Pick<
  EstimatedCharge,
  | "chargemaster"
  | "units"
  | "copay"
  | "coinsurance"
  | "remainingDeductible"
  | "remainingOutOfPocket"
  | "deductibleApplies"
  | "coverageBenefitId"
> & {
  allowedAmount: number | null;
  estimatedInsurancePolicy: {
    insurancePolicyId: string;
  } | null;
};

type DefaultEstimatedCharge = {
  code: string;
  units: number;
} & Partial<EstimateCharge>;

type Chargemaster = {
  id: string;
  code: string;
  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
  chargemasterGroupId: string | null;
};

export const EstimateDialogInner: React.FC<{
  appointment: Appointment;
  estimateId: string | null;
  chargeTemplates: ChargeTemplate[];
  defaultChargeTemplateId?: string;
  feeSchedules: FeeSchedule[];
  defaultEstimatedCharges?: DefaultEstimatedCharge[];
  onComplete?: () => void;
}> = ({
  appointment,
  estimateId,
  chargeTemplates,
  defaultChargeTemplateId,
  feeSchedules,
  defaultEstimatedCharges,
  onComplete,
}) => {
  const [defaultEstimate, setDefaultEstimate] = useState<Estimate | null>(
    appointment.estimates.find((e) => e.id === estimateId) ?? null
  );
  const [existingEstimate, setExistingEstimate] = useState(
    appointment.mostRecentVisitCollectionRequest?.estimate ?? null
  );

  const existingEstimatePolicies = defaultEstimate
    ? Array.from(
        defaultEstimate?.estimatedCharges.reduce(
          (
            acc: Map<
              string | null,
              {
                id: string | null;
                active: boolean;
              }
            >,
            ec
          ) => {
            if (
              acc.has(ec.estimatedInsurancePolicy?.insurancePolicyId ?? null)
            ) {
              return acc;
            }
            acc.set(ec.estimatedInsurancePolicy?.insurancePolicyId ?? null, {
              id: ec.estimatedInsurancePolicy?.insurancePolicyId ?? null,
              active: ec.estimatedInsurancePolicy?.active ?? true,
            });
            return acc;
          },
          new Map()
        )
      ).map(([_, { id, active }]) => ({ id, active }))
    : null;

  // If there's an existing estimate, default the policies

  let policies: { id: string | null; active: boolean }[] =
    existingEstimatePolicies ?? appointment.insurancePolicies;
  // If there are no policies, default to cash
  if (policies.length === 0) {
    policies = [
      {
        id: null,
        active: true,
      },
    ];
  }

  let defaultInsurancePolicyIds = policies
    .filter((p) => p.active)
    .map((p) => p.id);
  if (defaultEstimate) {
    defaultInsurancePolicyIds = Array.from(
      new Set(
        defaultEstimate.estimatedCharges
          .filter((charge) => charge.estimatedInsurancePolicy?.active !== false)
          .map(
            (charge) =>
              charge.estimatedInsurancePolicy?.insurancePolicyId ?? null
          )
      )
    );
  }

  const recommendedSaltingCharges =
    appointment.recommendedSaltingCharges.charges ?? [];

  const billedCharges = appointment.bill?.at(0)?.charges ?? [];

  // First look for the charge template passed as the defualtChargeTemplateId
  // Then look for the template used for any exising estimates
  // Then look for the recommended charge template
  const defaultChargeTemplate =
    // If a default charge template was explicitly passed in
    defaultChargeTemplateId
      ? chargeTemplates.find((ct) => ct.id === defaultChargeTemplateId)
      : // If existing estimate w/ charge template use that
      defaultEstimate?.chargeTemplate
      ? chargeTemplates.find(
          (ct) => ct.id === defaultEstimate?.chargeTemplate?.id
        )
      : // If charges entered or estimate SALTed, don't set a default charge template
      billedCharges.length > 0 || recommendedSaltingCharges.length > 0
      ? null
      : // If recommended charge template use that
      appointment.recommendedChargeTemplate
      ? chargeTemplates.find(
          (ct) => ct.id === appointment.recommendedChargeTemplate?.id
        )
      : null;

  const defaultFeeSchedule =
    feeSchedules.find((fs) => fs.id === defaultEstimate?.feeSchedule?.id) ??
    feeSchedules.find(
      (fs) => fs.id === appointment.recommendedFeeSchedule?.id
    ) ??
    null;

  // If there's an existing estimate with charges, find the first policy id
  let primaryEstimatePolicyId =
    defaultEstimate?.estimatedCharges?.at(0)?.estimatedInsurancePolicy
      ?.insurancePolicyId ?? null;

  let defaultUniqueCharges: {
    chargemaster: Chargemaster | null;
    units: number;
  }[] = [];
  // If prop passed in use charges
  if (defaultEstimatedCharges) {
    defaultUniqueCharges = defaultEstimatedCharges.map((c) => ({
      ...c,
      chargemaster: c.chargemaster ?? null,
    }));
  } else if (defaultEstimate) {
    // Else use the estimate charges
    defaultUniqueCharges = defaultEstimate.estimatedCharges.filter(
      (charge) =>
        (charge.estimatedInsurancePolicy?.insurancePolicyId ?? null) ===
        primaryEstimatePolicyId
    );
  } else if (billedCharges.length > 0) {
    defaultUniqueCharges = billedCharges.map((charge) => ({
      chargemaster: charge.chargemaster!,
      units: charge.units!,
      allowedAmount: charge.allowedAmount,
    }));
  } else if (recommendedSaltingCharges.length > 0) {
    defaultUniqueCharges = recommendedSaltingCharges.map((charge) => ({
      chargemaster: charge.chargemaster!,
      units: charge.units!,
      allowedAmount: charge.allowedAmount,
    }));
  } else if (defaultChargeTemplate) {
    // Else use the charge tempalte charges
    defaultUniqueCharges = defaultChargeTemplate.chargeTemplateCharges.map(
      (c) => ({
        ...c,
        description: c.chargemasterGroup?.description ?? null,
        chargemaster: c.chargemasterGroup.primaryChargemaster,
      })
    );
  }
  const defaultChargesWithChargemaster = defaultUniqueCharges.filter((c) =>
    isDefined(c.chargemaster)
  );

  const defaultInsuranceServiceLines = buildInsuranceServiceLines({
    policies,
    charges: defaultChargesWithChargemaster.map((charge) => ({
      chargemaster: charge.chargemaster!,
      units: charge.units.toString(),
    })),
    estimatedCharges: defaultEstimate?.estimatedCharges ?? [],
  });

  // Get current and previous visit collection requests
  const currentRequest = appointment.visitCollectionRequests[0];
  const previousRequest = appointment.visitCollectionRequests.find(
    (req) => req.id !== currentRequest?.id && req.lastSavedToIntegrationAt
  );

  const pastRenewalDate =
    appointment.insurancePolicies.find((policy) => {
      return new Date(policy.renewalDate) < new Date(appointment.start);
    })?.renewalDate ?? null;
  const pastRenewalDateTimestamp = pastRenewalDate
    ? formatDateMMDDYYYY(pastRenewalDate)
    : null;

  return (
    <EstimateForm
      key={defaultEstimate?.id ?? appointment.id}
      appointment={appointment}
      existingEstimate={existingEstimate}
      chargeTemplates={chargeTemplates}
      defaultChargeTemplate={defaultChargeTemplate}
      feeSchedules={feeSchedules}
      defaultFeeSchedule={defaultFeeSchedule}
      defaultInsuranceServiceLines={defaultInsuranceServiceLines}
      defaultInsurancePolicyIds={defaultInsurancePolicyIds}
      saltedBillId={appointment.recommendedSaltingCharges.saltedBillId}
      saltedAppointmentId={
        appointment.recommendedSaltingCharges.saltedAppointmentId
      }
      chargesEntered={billedCharges.length > 0}
      visitCollectionRequest={currentRequest}
      previousVisitCollectionRequest={previousRequest}
      pastRenewalDateTimestamp={pastRenewalDateTimestamp}
      onComplete={onComplete}
      resetForm={() => {
        setDefaultEstimate(null);
        setExistingEstimate(null);
      }}
    />
  );
};

export const AppointmentDisplay: React.FC<{
  appointment: Appointment | SaltedAppointment;
  salted?: Boolean;
  visitCollectionRequest?: {
    amount: number;
    lastSavedToIntegrationAt: string | null;
    savedToIntegration: {
      id: string;
      name: string;
    } | null;
    savedToIntegrationBy: {
      firstName: string;
      lastName: string;
    } | null;
  } | null;
  previousVisitCollectionRequest?: {
    amount: number;
    lastSavedToIntegrationAt: string | null;
    savedToIntegration: {
      id: string;
      name: string;
    } | null;
    savedToIntegrationBy: {
      firstName: string;
      lastName: string;
    } | null;
  } | null;
}> = ({
  appointment,
  salted,
  visitCollectionRequest,
  previousVisitCollectionRequest,
}) => {
  const user = useUser()!;
  const estimateSavingIntegrationEnabled =
    user.activeLocation.integrations.some(
      (i) => i.savingVisitCollectionRequestsEnabled
    );
  const showIntegrationStatus =
    estimateSavingIntegrationEnabled && visitCollectionRequest;

  return (
    <div
      className={cn(
        "w-full grid gap-4",
        salted
          ? "grid-cols-2"
          : showIntegrationStatus
          ? "grid-cols-5"
          : "grid-cols-4"
      )}
    >
      {!salted && (
        <div className={showIntegrationStatus ? "col-span-5" : "col-span-4"}>
          <PatientSummary patient={appointment.account.patient} />
        </div>
      )}
      <div>
        <div className="text-sm text-gray-500">Date</div>
        <div className="text-base text-gray-900">
          {format(parseISO(appointment.start), "MMM do h:mm aa")}
          {isDefined(appointment.end) &&
            `- ${format(parseISO(appointment.end), "h:mm aa")}`}
        </div>
      </div>
      <div>
        <div className="text-sm text-gray-500">Account</div>
        <div className="text-base text-gray-900">
          {appointment.account.accountType?.name}
        </div>
      </div>
      <div>
        <div className="text-sm text-gray-500">Appointment Labels</div>
        <div className="text-base text-gray-900">
          {appointment.appointmentLabelings.length > 0 ? (
            <div className="flex items-center gap-1">
              {appointment.appointmentLabelings.map((labeling) => (
                <Badge
                  key={labeling.appointmentLabel.id}
                  className="px-1 py-[1px] font-normal"
                >
                  {labeling.appointmentLabel.name}
                </Badge>
              ))}
            </div>
          ) : (
            "None"
          )}
        </div>
      </div>
      <div>
        <div className="text-sm text-gray-500">Provider</div>
        <div className="text-base text-gray-900">
          {appointment.provider?.displayName}
        </div>
      </div>

      {showIntegrationStatus && (
        <div>
          <div className="text-sm text-gray-500">Sync Status</div>
          <div className="text-base text-gray-900">
            <SyncStatusBadge
              visitCollectionRequest={visitCollectionRequest}
              previousVisitCollectionRequest={previousVisitCollectionRequest}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const CreateEstimateWizard: React.FC<{
  appointment: Appointment;
  chargeTemplates: ChargeTemplate[];
  defaultChargeTemplateId?: string;
  feeSchedules: FeeSchedule[];
  defaultEstimatedCharges?: EstimateCharge[];
  defaultDeposit?: {
    useCopay: boolean;
    amount: number | null;
    providerServiceConfigurationId: string | null;
  };
  onComplete?: () => void;
}> = ({
  appointment,
  chargeTemplates,
  defaultChargeTemplateId,
  feeSchedules,
  defaultEstimatedCharges,
  defaultDeposit,
  onComplete,
}) => {
  let deposit = appointment.mostRecentVisitCollectionRequest;
  const estimate = deposit?.estimate;
  const existingEstimate = isDefined(estimate);

  const recommendedDeposit = appointment.recommendedDeposit;
  if (!deposit && recommendedDeposit) {
    deposit = {
      amount: recommendedDeposit?.amount ?? null,
      coverageBenefit: null,
      rule: recommendedDeposit?.rule ?? null,
    };
  }
  const existingDeposit = isDefined(deposit) && !existingEstimate;

  // Get current and previous visit collection requests
  const currentRequest = appointment.visitCollectionRequests.at(0);
  const previousSyncedVisitCollectionRequest =
    appointment.visitCollectionRequests.find(
      (vcr) => vcr.id !== currentRequest?.id && vcr.lastSavedToIntegrationAt
    );

  const policies = appointment.insurancePolicies;
  const primaryInsurance = policies.at(0);

  return (
    <div className="min-h-[30vh] flex flex-col">
      <div className="px-8 border-b-2 pb-2">
        <AppointmentDisplay
          key={appointment.id}
          appointment={appointment}
          visitCollectionRequest={currentRequest}
          previousVisitCollectionRequest={previousSyncedVisitCollectionRequest}
        />
      </div>
      <Tab.Group defaultIndex={existingDeposit ? 1 : 0}>
        <div className="m-2">
          <Tab.List className="isolate flex divide-x divide-gray-200 rounded-lg shadow border">
            <Tab as={Fragment}>
              {({ selected }) => (
                <button
                  type="button"
                  className={classNames(
                    selected
                      ? "text-gray-900"
                      : "text-gray-500 hover:text-gray-700",
                    "rounded-l-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm lg:text-base font-medium text-center hover:bg-gray-50 focus:z-10"
                  )}
                >
                  Create Estimate with Charges
                  <span
                    aria-hidden="true"
                    className={classNames(
                      selected ? "bg-indigo-500" : "bg-transparent",
                      "absolute inset-x-0 bottom-0 h-1"
                    )}
                  />
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button
                  type="button"
                  className={classNames(
                    selected
                      ? "text-gray-900"
                      : "text-gray-500 hover:text-gray-700",
                    "rounded-r-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm lg:text-base font-medium text-center hover:bg-gray-50 focus:z-10"
                  )}
                >
                  Create Estimate without Charges
                  <span
                    aria-hidden="true"
                    className={classNames(
                      selected ? "bg-indigo-500" : "bg-transparent",
                      "absolute inset-x-0 bottom-0 h-1"
                    )}
                  />
                </button>
              )}
            </Tab>
          </Tab.List>
        </div>

        <Tab.Panels>
          <Tab.Panel>
            <EstimateDialogInner
              appointment={appointment}
              chargeTemplates={chargeTemplates}
              estimateId={estimate?.id ?? null}
              defaultChargeTemplateId={defaultChargeTemplateId}
              feeSchedules={feeSchedules}
              defaultEstimatedCharges={defaultEstimatedCharges}
              onComplete={onComplete}
            />
          </Tab.Panel>
          <Tab.Panel>
            <div className="max-w-lg pt-16 pb-24 mx-auto">
              <CreateEstimateWithoutChargesForm
                appointment={appointment}
                defaultDeposit={deposit ?? null}
                defaultPolicyId={primaryInsurance?.id! ?? null}
                policies={policies}
                patientId={appointment.account.patient.id}
                closeWizard={() => {
                  onComplete?.();
                }}
              />
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

// TODO crate dialog w/ embedded wizard that can be started at any point in the flow
export const CreateEstimateWizardDialog: React.FC<
  React.PropsWithChildren<{
    appointmentId: string;
    setOpen: (open: boolean) => void;
    defaultChargeTemplateId?: string;
    defaultEstimatedCharges?: EstimateCharge[];
    onComplete?: () => void;
  }>
> = ({
  appointmentId,
  setOpen,
  defaultChargeTemplateId,
  defaultEstimatedCharges,
  onComplete,
}) => {
  const user = useUser()!;
  const estimateResult = useQuery<
    GetEstimateFormData,
    GetEstimateFormDataVariables
  >(GET_ESTIMATE_FORM_DATA, {
    variables: {
      appointmentId: appointmentId,
    },
  });

  const chargeTemplatesResult = useQuery<
    GetLocationChargeTemplates,
    GetLocationChargeTemplatesVariables
  >(GET_LOCATION_CHARGE_TEMPLATES, {
    variables: {
      locationId: user.activeLocation.id,
    },
  });

  const feeSchedulesResult = useQuery<
    GetFeeSchedules,
    GetFeeSchedulesVariables
  >(GET_FEESCHEDULES, {
    variables: {
      locationId: user.activeLocation.id,
      pending: false,
    },
  });

  const loading =
    estimateResult.loading ||
    chargeTemplatesResult.loading ||
    feeSchedulesResult.loading;

  const chargeTemplates = chargeTemplatesResult.data?.chargeTemplates ?? [];
  const feeSchedules = feeSchedulesResult.data?.feeSchedules ?? [];
  const appointment = estimateResult.data?.appointment;

  return (
    <Modal
      open={true}
      setOpen={setOpen}
      // Disable the close except by clicking the button
      onClose={() => {
        return false;
      }}
      size="xl"
      className="!px-0 !pb-0"
    >
      <div className="min-w-1/2">
        <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={() => setOpen(false)}
          >
            <span className="sr-only">Close</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        {loading ? (
          <div className="flex w-full h-[40vh]">
            <div className="m-auto flex items-center gap-2">
              Loading Estimate <OvalSpinner className="w-8" />
            </div>
          </div>
        ) : (
          <CreateEstimateWizard
            appointment={appointment!}
            chargeTemplates={chargeTemplates}
            defaultChargeTemplateId={defaultChargeTemplateId}
            feeSchedules={feeSchedules}
            defaultEstimatedCharges={defaultEstimatedCharges}
            onComplete={onComplete}
          />
        )}
      </div>
    </Modal>
  );
};

// TODO: rename to create estimate dialog button
export const CreateEstimateWizardDialogButton: React.FC<
  React.PropsWithChildren<{
    appointmentId: string;
    text?: string;
  }>
> = ({ appointmentId, text }) => {
  const [open, setOpen] = useState(false);
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <button
        className="inline-flex justify-center items-center min-w-[8em] rounded-md border border-transparent px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed"
        onClick={() => setOpen(true)}
      >
        {text ?? <>Create Estimate</>}
      </button>
      {open && (
        <CreateEstimateWizardDialog
          appointmentId={appointmentId}
          setOpen={setOpen}
          onComplete={() => {
            setOpen(false);
          }}
        />
      )}
    </div>
  );
};

export const EditEstimateWizardDialogButton: React.FC<
  React.PropsWithChildren<{
    appointmentId: string;
  }>
> = ({ appointmentId }) => {
  const [open, setOpen] = useState(false);
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <button
        className="flex items-center rounded-full p-1 hover:bg-gray-100"
        onClick={() => setOpen(true)}
      >
        <PencilAltIcon className="h-4 w-4 mr-1" />
      </button>
      {open && (
        <CreateEstimateWizardDialog
          appointmentId={appointmentId}
          setOpen={setOpen}
          onComplete={() => {
            setOpen(false);
          }}
        />
      )}
    </div>
  );
};

export const EstimateDialog: React.FC<
  React.PropsWithChildren<{
    appointmentId: string;
    estimateId: string | null;
    setOpen: (open: boolean) => void;
    defaultChargeTemplateId?: string;
    defaultEstimatedCharges?: { code: string; units: number }[];
    onComplete?: () => void;
  }>
> = ({
  appointmentId,
  estimateId,
  setOpen,
  defaultChargeTemplateId,
  defaultEstimatedCharges,
  onComplete,
}) => {
  const user = useUser()!;
  const estimateResult = useQuery<
    GetEstimateFormData,
    GetEstimateFormDataVariables
  >(GET_ESTIMATE_FORM_DATA, {
    variables: {
      appointmentId: appointmentId,
    },
  });

  const chargeTemplatesResult = useQuery<
    GetLocationChargeTemplates,
    GetLocationChargeTemplatesVariables
  >(GET_LOCATION_CHARGE_TEMPLATES, {
    variables: {
      locationId: user.activeLocation.id,
    },
  });

  const feeSchedulesResult = useQuery<
    GetFeeSchedules,
    GetFeeSchedulesVariables
  >(GET_FEESCHEDULES, {
    variables: {
      locationId: user.activeLocation.id,
      pending: false,
    },
  });

  const loading =
    estimateResult.loading ||
    chargeTemplatesResult.loading ||
    feeSchedulesResult.loading;

  const chargeTemplates = chargeTemplatesResult.data?.chargeTemplates ?? [];
  const feeSchedules = feeSchedulesResult.data?.feeSchedules ?? [];
  const appointment = estimateResult.data?.appointment;

  return (
    <Modal open={true} setOpen={setOpen} size="xl" className="!p-0">
      <div className="min-w-1/2">
        {loading ? (
          <div className="flex w-full h-[40vh]">
            <div className="m-auto flex items-center gap-2">
              Loading Estimate <OvalSpinner className="w-8" />
            </div>
          </div>
        ) : (
          <EstimateDialogInner
            appointment={appointment!}
            chargeTemplates={chargeTemplates}
            feeSchedules={feeSchedules}
            estimateId={estimateId}
            defaultChargeTemplateId={defaultChargeTemplateId}
            defaultEstimatedCharges={defaultEstimatedCharges}
            onComplete={onComplete}
          />
        )}
      </div>
    </Modal>
  );
};

// TODO: rename to create estimate dialog button
export const EstimateDialogButton: React.FC<
  React.PropsWithChildren<{
    appointmentId: string;
  }>
> = ({ appointmentId }) => {
  const [open, setOpen] = useState(false);
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <button
        className="inline-flex justify-center items-center min-w-[8em] rounded-md border border-transparent px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed"
        onClick={() => setOpen(true)}
      >
        Create Estimate
      </button>
      {open && (
        <EstimateDialog
          appointmentId={appointmentId}
          estimateId={null}
          setOpen={setOpen}
        />
      )}
    </div>
  );
};

export const EditEstimateDialogButton: React.FC<
  React.PropsWithChildren<{
    appointmentId: string;
    estimateId: string;
  }>
> = ({ appointmentId, estimateId }) => {
  const [open, setOpen] = useState(false);
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <button
        className="flex items-center rounded-full p-1 hover:bg-gray-100"
        onClick={() => setOpen(true)}
      >
        <PencilAltIcon className="h-4 w-4 mr-1" />
      </button>
      {open && (
        <EstimateDialog
          appointmentId={appointmentId}
          estimateId={estimateId}
          setOpen={setOpen}
        />
      )}
    </div>
  );
};
