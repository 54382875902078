import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { PencilAltIcon } from "@heroicons/react/outline";

import {
  formatPercentage,
  formatUSD,
  isDefined,
  mapNullable,
  toCents,
} from "../../utils";
import {
  COVERAGE_BENEFIT_FIELDS,
  ELIGIBILITY_REQUEST_STATUS_FIELD,
  INSURANCE_POLICY_SUMMARY_FIELDS,
} from "../../graphql";
import { SubmitButton, Modal } from "../../components";
import { toast } from "react-toastify";
import {
  CoverageBenefitUpdateInput,
  UserEnteredBenefitType,
} from "../../generated/globalTypes";
import Select from "react-select";
import { DollarInput } from "../../components/input";
import {
  GetPatientInsuranceDetails_patient_insurancePolicies as InsurancePolicy,
  GetPatientInsuranceDetails_patient_insurancePolicies_coverageBenefits as CoverageBenefit,
  GetPatientInsuranceDetails_patient_insurancePolicies_coverageBenefits_providerServiceConfiguration as ProviderServiceConfiguration,
} from "../../generated/GetPatientInsuranceDetails";
import {
  CreateCoverageBenefit,
  CreateCoverageBenefitVariables,
} from "../../generated/CreateCoverageBenefit";
import {
  UpdateCoverageBenefit,
  UpdateCoverageBenefitVariables,
} from "../../generated/UpdateCoverageBenefit";

// const CoverageBenefitButton: React.FC<{
//   coverage: Coverage;
//   providerServiceConfiguration: ProviderServiceConfiguration;
//   networkStatus: boolean;
//   coverageBenefit: CoverageBenefit | null;
//   name: string;
//   property: keyof CoverageBenefitUpdateInput;
// }> = ({
//   coverage,
//   providerServiceConfiguration,
//   networkStatus,
//   coverageBenefit,
//   children,
//   name,
//   property,
// }) => {
//   const [open, setOpen] = useState(false);
//   return (
//     <>
//       <button
//         onClick={() => setOpen(true)}
//         className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"
//       >
//         {children}
//       </button>
//       {open && (
//         <BenefitEditModal
//           coverage={coverage}
//           providerServiceConfiguration={providerServiceConfiguration}
//           networkStatus={networkStatus}
//           coverageBenefit={coverageBenefit}
//           open={open}
//           setOpen={setOpen}
//           name={name}
//           property={property}
//         />
//       )}
//     </>
//   );
// };

const PLAN_TYPE_OPTIONS = [
  {
    label: "Copay",
    value: "Copay",
  },
  {
    label: "Coinsurance",
    value: "Coinsurance",
  },
  {
    label: "Not Covered",
    value: "Not Covered",
  },
];

// const BenefitDisplayButton: React.FC<
//   React.PropsWithChildren<{
//     insurancePolicy: InsurancePolicy;
//     providerServiceConfiguration: ProviderServiceConfiguration;
//     coverageBenefit: CoverageBenefit | null;
//     benefitType: UserEnteredBenefitType;
//   }>
// > = ({
//   insurancePolicy,
//   providerServiceConfiguration,
//   coverageBenefit,
//   benefitType,
//   children,
// }) => {
//   const [open, setOpen] = useState(false);
//   return (
//     <>
//       <button
//         className="flex justify-between items-center py-1 px-2 w-full border border-gray-100 rounded-md hover:border-gray-300"
//         onClick={() => {
//           setOpen(true);
//         }}
//       >
//         {children}
//       </button>
//       {open && (
//         <BenefitPickerPalette
//           open={open}
//           setOpen={setOpen}
//           insurancePolicyId={insurancePolicy.id}
//           providerServiceConfiguration={providerServiceConfiguration}
//           benefitType={benefitType}
//           coverageBenefit={coverageBenefit}
//         />
//       )}
//     </>
//   );
// };

// const CoverageBenefitForm: React.FC<
//   React.PropsWithChildren<{
//     insurancePolicy: InsurancePolicy;
//     providerServiceConfiguration: ProviderServiceConfiguration;
//     networkStatus: boolean;
//     coverageBenefit: CoverageBenefit | null;
//   }>
// > = ({
//   insurancePolicy,
//   providerServiceConfiguration,
//   networkStatus,
//   coverageBenefit,
// }) => {
//   // TODO: add as db column
//   let defaultPlanType: BenefitScheme = "Not Covered";
//   if (isDefined(coverageBenefit?.copay)) {
//     defaultPlanType = "Copay";
//   } else if (isDefined(coverageBenefit?.coinsurance)) {
//     defaultPlanType = "Coinsurance";
//   }
//   const [selectedPlanType, setSelectedPlanType] =
//     useState<BenefitScheme>(defaultPlanType);
//   console.log({ selectedPlanType });
//   return (
//     <div className="flex gap-2 items-center">
//       <div>
//         <label className="block text-xs text-gray-500">Plan Type</label>
//         <Select
//           className="w-36 text-sm"
//           styles={{
//             control: (provided, state) => ({
//               ...provided,
//               background: "#fff",
//               borderColor: "#e5e7eb",
//               minHeight: "30px",
//               height: "30px",
//             }),

//             valueContainer: (provided, state) => ({
//               ...provided,
//               height: "30px",
//               padding: "0 6px",
//             }),

//             input: (provided, state) => ({
//               ...provided,
//               margin: "0px",
//             }),
//             indicatorSeparator: (state) => ({
//               display: "none",
//             }),
//             indicatorsContainer: (provided, state) => ({
//               ...provided,
//               height: "30px",
//             }),
//           }}
//           defaultValue={
//             PLAN_TYPE_OPTIONS.find((o) => o.value === selectedPlanType) ??
//             PLAN_TYPE_OPTIONS[2]
//           }
//           options={PLAN_TYPE_OPTIONS}
//           onChange={(option) => {
//             setSelectedPlanType(option!.value as BenefitScheme);
//           }}
//         />
//       </div>
//       <div className="flex gap-2">
//         {selectedPlanType === "Copay" && (
//           <div className="w-36">
//             <label className="block text-xs text-gray-500">Copay</label>
//             <BenefitDisplayButton
//               insurancePolicy={insurancePolicy}
//               providerServiceConfiguration={providerServiceConfiguration}
//               coverageBenefit={coverageBenefit}
//               benefitType={UserEnteredBenefitType.Copay}
//             >
//               {mapNullable(formatUSD)(coverageBenefit?.copay) ?? (
//                 <span className="text-gray-500">-</span>
//               )}
//               <PencilAltIcon className="w-4 h-4 text-gray-500" />
//             </BenefitDisplayButton>
//           </div>
//         )}
//         {selectedPlanType === "Coinsurance" && (
//           <div className="w-36">
//             <label className="block text-xs text-gray-500">Coinsurance</label>
//             <BenefitDisplayButton
//               insurancePolicy={insurancePolicy}
//               providerServiceConfiguration={providerServiceConfiguration}
//               coverageBenefit={coverageBenefit}
//               benefitType={UserEnteredBenefitType.Coinsurance}
//             >
//               {mapNullable(formatPercentage)(coverageBenefit?.coinsurance) ?? (
//                 <span className="text-gray-500">-</span>
//               )}
//               <PencilAltIcon className="w-4 h-4 text-gray-500" />
//             </BenefitDisplayButton>
//           </div>
//         )}
//         {selectedPlanType !== "Not Covered" && (
//           <>
//             <div className="w-36">
//               <label className="block text-xs text-gray-500">Deductible</label>
//               <BenefitDisplayButton
//                 insurancePolicy={insurancePolicy}
//                 providerServiceConfiguration={providerServiceConfiguration}
//                 coverageBenefit={coverageBenefit}
//                 benefitType={UserEnteredBenefitType.RemainingDeductible}
//               >
//                 {mapNullable(formatUSD)(
//                   coverageBenefit?.remainingDeductible
//                 ) ?? <span className="text-gray-500">-</span>}
//                 <PencilAltIcon className="w-4 h-4 text-gray-500" />
//               </BenefitDisplayButton>
//             </div>
//             <div className="w-36">
//               <label className="block text-xs text-gray-500">
//                 Remaining Visits
//               </label>
//               <BenefitDisplayButton
//                 insurancePolicy={insurancePolicy}
//                 providerServiceConfiguration={providerServiceConfiguration}
//                 coverageBenefit={coverageBenefit}
//                 benefitType={UserEnteredBenefitType.RemainingVisits}
//               >
//                 {isDefined(coverageBenefit?.remainingVisits) ? (
//                   coverageBenefit?.remainingVisits
//                 ) : (
//                   <span className="text-gray-500">-</span>
//                 )}
//                 <PencilAltIcon className="w-4 h-4 text-gray-500" />
//               </BenefitDisplayButton>
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

export const CREATE_COVERAGE_BENEFIT = gql`
  mutation CreateCoverageBenefit($data: CoverageBenefitCreateInput!) {
    createNewCoverageBenefit(data: $data) {
      id
      providerServiceConfiguration {
        id
        name
        serviceType
      }
      networkStatus
      copay
      copayBenefit {
        id
        eligibilityRequestId
      }
      coinsurance
      coinsuranceBenefit {
        id
        eligibilityRequestId
      }
      remainingDeductible
      remainingDeductibleBenefit {
        id
        eligibilityRequestId
      }
      remainingOutOfPocket
      remainingOutOfPocketBenefit {
        id
        eligibilityRequestId
      }
      remainingVisits
      remainingVisitsBenefit {
        id
        eligibilityRequestId
      }
    }
  }
`;

export const UPDATE_COVERAGE_BENEFIT = gql`
  mutation UpdateCoverageBenefit(
    $id: String!
    $data: CoverageBenefitUpdateInput!
  ) {
    updateOneCoverageBenefit(where: { id: $id }, data: $data) {
      id
      providerServiceConfiguration {
        id
        name
        serviceType
      }
      networkStatus
      copay
      copayBenefit {
        id
        eligibilityRequestId
      }
      coinsurance
      coinsuranceBenefit {
        id
        eligibilityRequestId
      }
      remainingDeductible
      remainingDeductibleBenefit {
        id
        eligibilityRequestId
      }
      remainingOutOfPocket
      remainingOutOfPocketBenefit {
        id
        eligibilityRequestId
      }
      remainingVisits
      remainingVisitsBenefit {
        id
        eligibilityRequestId
      }
    }
  }
`;

const BenefitEditModal: React.FC<
  React.PropsWithChildren<{
    insurancePolicy: InsurancePolicy;
    providerServiceConfiguration: ProviderServiceConfiguration;
    networkStatus: boolean;
    coverageBenefit: CoverageBenefit | null;
    open: boolean;
    setOpen: (open: boolean) => void;
    name: string;
    property: keyof CoverageBenefitUpdateInput;
  }>
> = ({
  insurancePolicy,
  providerServiceConfiguration,
  networkStatus,
  coverageBenefit,
  open,
  setOpen,
  name,
  property,
}) => {
  const [createCoverageBenefit, createCoverageBenefitResult] = useMutation<
    CreateCoverageBenefit,
    CreateCoverageBenefitVariables
  >(CREATE_COVERAGE_BENEFIT);
  const [updateCoverageBenefit, updateCoverageBenefitResult] = useMutation<
    UpdateCoverageBenefit,
    UpdateCoverageBenefitVariables
  >(UPDATE_COVERAGE_BENEFIT);
  const [cents, setCents] = useState<number | null>();
  return (
    <Modal open={open} setOpen={setOpen} size="md">
      <div className="mt-3 text-center sm:mt-5">Edit {name}</div>
      <div className="mt-2 py-4">
        <DollarInput
          containerClassName="relative rounded-md border"
          className="rounded-md focus:ring-indigo-500 focus:border-indigo-500 block w-full h-full min-h-[2em] pl-7 py-1 sm:text-sm border-gray-300"
          placeholder="0.00"
          decimalsLimit={2}
          step={0.01}
          min={1}
          defaultValue={cents ?? undefined}
          onValueChange={(amount) => {
            const cents = mapNullable(toCents)(amount as any);
            setCents(cents);
          }}
        />
      </div>
      <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
          onClick={() => setOpen(false)}
        >
          Close
        </button>
        <SubmitButton
          type="button"
          onClick={() => {
            if (coverageBenefit) {
              updateCoverageBenefit({
                variables: {
                  id: coverageBenefit.id,
                  data: {
                    [property]: {
                      set: cents,
                    },
                  },
                },
                onCompleted: () => {
                  toast.success(`${name} updated`);
                  setOpen(false);
                },
                onError: () => {
                  toast.error(`Failed to update ${name}`);
                },
                refetchQueries: [GET_PATIENT_INSURANCE_DETAILS],
              });
            } else {
              createCoverageBenefit({
                variables: {
                  data: {
                    providerServiceConfiguration: {
                      connect: {
                        id: providerServiceConfiguration.id,
                      },
                    },
                    insurancePolicy: {
                      connect: {
                        id: insurancePolicy.id,
                      },
                    },
                    empty: false,
                    nonCovered: false,
                    networkStatus,
                    [property]: cents,
                  },
                },
                onCompleted: () => {
                  toast.success(`${name} updated`);
                  setOpen(false);
                },
                onError: () => {
                  toast.error(`Failed to update ${name}`);
                },
                refetchQueries: [GET_PATIENT_INSURANCE_DETAILS],
              });
            }
          }}
          loading={updateCoverageBenefitResult.loading}
        >
          Save
        </SubmitButton>
      </div>
    </Modal>
  );
};

type BenefitScheme = "Copay" | "Coinsurance" | "Not Covered";

// const FILL_COVERAGE_BENEFITS_FROM_ELIGIBILITY_REQUEST = gql`
//   mutation FillCoverageBenefitsFromEligibilityRequest(
//     $eligibilityRequestId: String!
//   ) {
//     fillCoverageBenefitsFromEligibilityRequest(
//       eligibilityRequestId: $eligibilityRequestId
//     ) {
//       coverage {
//         id
//       }
//       errors {
//         message
//       }
//     }
//   }
// `;

// export const CoverageBenefitsDisplay: React.FC<React.PropsWithChildren<{
//   providerServiceConfigurations: ProviderServiceConfiguration[];
//   insurancePolicy: InsurancePolicy;
// }>> = ({ providerServiceConfigurations, insurancePolicy }) => {
//   //   const [
//   //     fillCoverageBenefitsFromEligibilityRequest,
//   //     fillCoverageBenefitsFromEligibilityRequestResult,
//   //   ] = useMutation<
//   //     FillCoverageBenefitsFromEligibilityRequest,
//   //     FillCoverageBenefitsFromEligibilityRequestVariables
//   //   >(FILL_COVERAGE_BENEFITS_FROM_ELIGIBILITY_REQUEST);

//   const servicesWithBenefits = providerServiceConfigurations.map((config) => {
//     const coverageBenefit = insurancePolicy.coverageBenefits.find(
//       (cb) => cb.providerServiceConfiguration.id === config.id
//     );
//     return {
//       ...config,
//       coverageBenefit,
//     };
//   });
//   const eligibilityRequestId = insurancePolicy.eligibilityRequests[0]?.id;
//   return (
//     <div className="flex flex-col">
//       <div className="flex flex-col gap-1 divide-y">
//         {servicesWithBenefits.map((config) => {
//           return (
//             <div key={config.id} className="flex items-center gap-1 pt-1">
//               <div className="w-[16em] min-w-[8em] truncate text-sm">
//                 {config.name} ({config.serviceType})
//               </div>

//               <CoverageBenefitForm
//                 insurancePolicy={insurancePolicy}
//                 providerServiceConfiguration={config}
//                 networkStatus={
//                   insurancePolicy.payer.organizationInNetworkPayers.length > 0
//                 }
//                 coverageBenefit={config.coverageBenefit ?? null}
//               />
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

export const GET_PATIENT_INSURANCE_DETAILS = gql`
  ${INSURANCE_POLICY_SUMMARY_FIELDS}
  ${ELIGIBILITY_REQUEST_STATUS_FIELD}
  ${COVERAGE_BENEFIT_FIELDS}
  query GetPatientInsuranceDetails($id: String!) {
    patient(where: { id: $id }) {
      id
      organization {
        id
        providerServiceConfiguration {
          id
          priorityOrder
          serviceType
          name
        }
      }
      insurancePolicies {
        ...InsurancePolicySummaryFields
        coverageBenefits {
          ...CoverageBenefitFields
        }
        patient {
          id
        }
        eligibilityRequests(orderBy: { createdAt: desc }) {
          id
          createdAt
          automated
          rawEligibilityRequestResponses {
            id
            requestedServiceTypeCode
            requestedServiceTypeDisplay
          }
          requestedBy {
            id
            firstName
            lastName
          }
          insurancePolicy {
            id
            payer {
              id
              name
            }
            plan {
              id
              name
            }
          }
          ...EligibilityRequestStatusFields
        }
      }
    }
  }
`;
