import { useParams } from "react-router-dom";
import { SettingsLayout } from "..";
import {
  GetMyOrganization_me_organization as Organization,
  GetMyOrganization_me_organization_locations as Location,
  GetMyOrganization_me_organization_locations_verificationWorkflowStatuses as VerificationWorkflowStatus,
} from "../../../generated/GetMyOrganization";
import { Badge, Card, SubmitButton } from "../../../components";
import { gql, useMutation } from "@apollo/client";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  LocationMarkerIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import React from "react";
import { WorkflowStage } from "../../../generated/globalTypes";
import { VerificationWorkflowStatusIndicator } from "../../verifications/list";
import { Button } from "../../../components/ui/button";
import { FormProvider, useForm } from "react-hook-form";
import {
  Dialog,
  DialogTrigger,
  DialogHeader,
  DialogTitle,
  DialogContent,
  DialogDescription,
} from "../../../components/ui/dialog";
import {
  UpsertVerificationWorkflowStatus,
  UpsertVerificationWorkflowStatusVariables,
} from "../../../generated/UpsertVerificationWorkflowStatus";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import { v4 as uuidV4 } from "uuid";
import { cn, isDefined } from "../../../utils";
import {
  RemoveAndReplaceVerificationWorkflowStatus,
  RemoveAndReplaceVerificationWorkflowStatusVariables,
} from "../../../generated/RemoveAndReplaceVerificationWorkflowStatus";
import { Divider } from "@tremor/react";
import { Switch } from "@headlessui/react";
import { OvalSpinner } from "../../../components/loading";
import {
  UpdateLocationAutomatedAccumulatorTracking,
  UpdateLocationAutomatedAccumulatorTrackingVariables,
} from "../../../generated/UpdateLocationAutomatedAccumulatorTracking";
import { useUser } from "../../../user-context";

const UPSERT_VERIFICATION_WORKFLOW_STATUS = gql`
  mutation UpsertVerificationWorkflowStatus(
    $where: VerificationWorkflowStatusWhereUniqueInput!
    $create: VerificationWorkflowStatusCreateInput!
    $update: VerificationWorkflowStatusUpdateInput!
  ) {
    upsertOneVerificationWorkflowStatus(
      where: $where
      create: $create
      update: $update
    ) {
      id
      position
      name
      description
      stage
      location {
        id
        verificationWorkflowStatuses(
          orderBy: [{ stage: asc }, { position: asc }]
        ) {
          id
          position
          name
          description
          stage
          locationDefault {
            id
          }
        }
      }
    }
  }
`;

const REMOVE_AND_REPLACE_VERIFICATION_WORKFLOW_STATUS = gql`
  mutation RemoveAndReplaceVerificationWorkflowStatus(
    $id: String!
    $replacementStatusId: String!
  ) {
    removeAndReplaceVerificationWorkflowStatus(
      id: $id
      replacementStatusId: $replacementStatusId
    ) {
      id
      position
      name
      description
      stage
      location {
        id
        verificationWorkflowStatuses(
          orderBy: [{ stage: asc }, { position: asc }]
        ) {
          id
          position
          name
          description
          stage
          locationDefault {
            id
          }
        }
      }
    }
  }
`;

const RemoveVerificationWorkflowStatusDialogButton: React.FC<{
  location: Location;
  status: VerificationWorkflowStatus;
}> = ({ location, status }) => {
  const [open, setOpen] = useState(false);
  const [
    removeAndReplaceVerificationWorkflowStatus,
    removeAndReplaceVerificationWorkflowStatusResult,
  ] = useMutation<
    RemoveAndReplaceVerificationWorkflowStatus,
    RemoveAndReplaceVerificationWorkflowStatusVariables
  >(REMOVE_AND_REPLACE_VERIFICATION_WORKFLOW_STATUS);
  const [moveStatusId, setMoveStatusId] = useState<string>();

  const stageStatuses = location.verificationWorkflowStatuses.filter(
    (s) => s.stage === status.stage
  );

  const isValid = isDefined(moveStatusId);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className="rounded-md p-1 hover:bg-gray-100">
        <TrashIcon className="w-4 h-4 text-gray-500" />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Remove the {status.name} Verification Workflow Status?
          </DialogTitle>
          <DialogDescription>
            <div>
              Are you sure you want to remove the {status.name} Verification
              Workflow Status? This action cannot be undone.
            </div>
            <div className="flex flex-col gap-1 pt-2">
              <div>
                <label
                  htmlFor="moveStatusId"
                  className="block text-sm font-medium text-gray-700"
                >
                  Status to move {status.name} policies to after deletion
                </label>
                <Select value={moveStatusId} onValueChange={setMoveStatusId}>
                  <SelectTrigger>
                    <SelectValue
                      placeholder={`Select a ${status.stage} status`}
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {stageStatuses
                        .filter((s) => status.id !== s.id)
                        .map((s) => (
                          <SelectItem value={s.id}>{s.name}</SelectItem>
                        ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <div className="flex justify-end gap-2 pt-2">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <div>
                  <SubmitButton
                    onClick={() => {
                      removeAndReplaceVerificationWorkflowStatus({
                        variables: {
                          id: status.id,
                          replacementStatusId: moveStatusId!,
                        },
                        onCompleted: () => {
                          toast.success("Verification Workflow status removed");
                          setOpen(false);
                        },
                        onError: () => {
                          toast.error(
                            "Failed to remove Verification Workflow status"
                          );
                        },
                      });
                    }}
                    disabled={
                      !isValid ||
                      removeAndReplaceVerificationWorkflowStatusResult.loading
                    }
                  >
                    Save
                  </SubmitButton>
                </div>
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

type VerificationWorkflowStatusForm = {
  id: string | null;
  name: string;
  description: string | null;
  stage: WorkflowStage;
  default: boolean;
};

const VerificationWorkflowFormDialogButton: React.FC<{
  stage: WorkflowStage;
  status: VerificationWorkflowStatus | null;
  position: number;
  locationId: string;
  dialogTrigger: React.ReactNode;
}> = ({ stage, status, position, locationId, dialogTrigger }) => {
  const [open, setOpen] = useState(false);
  const [
    upsertVerificationWorkflowStatus,
    upsertVerificationWorkflowStatusResult,
  ] = useMutation<
    UpsertVerificationWorkflowStatus,
    UpsertVerificationWorkflowStatusVariables
  >(UPSERT_VERIFICATION_WORKFLOW_STATUS);
  const form = useForm<VerificationWorkflowStatusForm>({
    defaultValues: {
      id: status?.id ?? uuidV4(),
      name: status?.name,
      description: status?.description,
      default: status?.locationDefault ? true : false,
    },
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className="rounded-md p-1 hover:bg-gray-100">
        {dialogTrigger}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {status ? "Edit" : "Add"} the {stage} Verification Workflow Status
          </DialogTitle>
          <DialogDescription>
            <FormProvider {...form}>
              <form
                onSubmit={form.handleSubmit((data) => {
                  upsertVerificationWorkflowStatus({
                    variables: {
                      where: {
                        id: data.id,
                      },
                      create: {
                        position,
                        name: data.name,
                        description: data.description,
                        stage,
                        location: {
                          connect: { id: locationId },
                        },
                        ...(data.default
                          ? {
                              locationDefault: {
                                connect: { id: locationId },
                              },
                            }
                          : {}),
                      },
                      update: {
                        name: { set: data.name },
                        description: { set: data.description },
                        ...(data.default
                          ? {
                              locationDefault: {
                                connect: { id: locationId },
                              },
                            }
                          : {}),
                      },
                    },
                    onCompleted: () => {
                      toast.success("Verification Workflow status updated");
                      setOpen(false);
                    },
                    onError: () => {
                      toast.error(
                        "Failed to update Verification Workflow status"
                      );
                    },
                  });
                })}
              >
                <div className="flex flex-col gap-1 pt-2">
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      required
                      {...form.register("name")}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Description
                    </label>
                    <input
                      type="text"
                      {...form.register("description")}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  {stage === WorkflowStage.Todo && (
                    <div>
                      <label
                        htmlFor="default"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Default Status
                      </label>
                      <input type="checkbox" {...form.register("default")} />
                    </div>
                  )}
                  <div className="flex justify-end gap-2 pt-2">
                    <Button
                      type="button"
                      variant="outline"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <div>
                      <SubmitButton
                        disabled={
                          upsertVerificationWorkflowStatusResult.loading
                        }
                      >
                        Save
                      </SubmitButton>
                    </div>
                  </div>
                </div>
              </form>
            </FormProvider>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

const MOVE_VERIFICATION_WORKFLOW_STATUS_POSITION_UP = gql`
  mutation MoveVerificationWorkflowStatusPositionUp($id: String!) {
    moveVerificationWorkflowStatusPositionUp(id: $id) {
      id
      position
      name
      description
      stage
      location {
        id
        verificationWorkflowStatuses(
          orderBy: [{ stage: asc }, { position: asc }]
        ) {
          id
          position
          name
          description
          stage
        }
      }
    }
  }
`;
const MOVE_VERIFICATION_WORKFLOW_STATUS_POSITION_DOWN = gql`
  mutation MoveVerificationWorkflowStatusPositionDown($id: String!) {
    moveVerificationWorkflowStatusPositionDown(id: $id) {
      id
      position
      name
      description
      stage
      location {
        id
        verificationWorkflowStatuses(
          orderBy: [{ stage: asc }, { position: asc }]
        ) {
          id
          position
          name
          description
          stage
        }
      }
    }
  }
`;

const UPDATE_LOCATION_VERIFICATION_WORKFLOW_SETTINGS = gql`
  mutation UpdateLocationVerificationWorkflowSettings(
    $locationId: String!
    $data: LocationUpdateInput!
  ) {
    updateOneLocation(where: { id: $locationId }, data: $data) {
      id
      confirmationVerificationWorkflowStatus {
        id
      }
      unconfirmationVerificationWorkflowStatus {
        id
      }
    }
  }
`;

const UPDATE_LOCATION_AUTOMATED_ACCUMULATOR_TRACKING = gql`
  mutation UpdateLocationAutomatedAccumulatorTracking(
    $locationId: String!
    $enabled: Boolean!
  ) {
    updateOneLocation(
      where: { id: $locationId }
      data: { automatedAccumulatorTrackingEnabled: { set: $enabled } }
    ) {
      id
      automatedAccumulatorTrackingEnabled
    }
  }
`;

const LocationVerificationWorkflowSettings: React.FC<
  React.PropsWithChildren<{ location: Location }>
> = ({ location }) => {
  const verificationWorkflowStatuses = location.verificationWorkflowStatuses;
  const [
    moveVerificationWorkflowStatusPositionUp,
    moveVerificationWorkflowStatusPositionUpResult,
  ] = useMutation(MOVE_VERIFICATION_WORKFLOW_STATUS_POSITION_UP);
  const [
    moveVerificationWorkflowStatusPositionDown,
    moveVerificationWorkflowStatusPositionDownResult,
  ] = useMutation(MOVE_VERIFICATION_WORKFLOW_STATUS_POSITION_DOWN);
  const [
    updateLocationVerificationWorkflowSettings,
    updateLocationVerificationWorkflowSettingsResult,
  ] = useMutation(UPDATE_LOCATION_VERIFICATION_WORKFLOW_SETTINGS);

  const [
    confirmationVerificationWorkflowStatusId,
    setConfirmationVerificationWorkflowStatusId,
  ] = useState(location.confirmationVerificationWorkflowStatus?.id ?? "");
  const [
    unconfirmationVerificationWorkflowStatusId,
    setUnconfirmationVerificationWorkflowStatusId,
  ] = useState(location.unconfirmationVerificationWorkflowStatus?.id ?? "");
  const [
    exportVerificationWorkflowStatusId,
    setExportVerificationWorkflowStatusId,
  ] = useState(location.exportVerificationWorkflowStatus?.id ?? "");
  const [
    emailBenefitsVerificationWorkflowStatusId,
    setEmailBenefitsVerificationWorkflowStatusId,
  ] = useState(location.emailBenefitsVerificationWorkflowStatus?.id ?? "");
  const [unconfirmOnBenefitsChange, setUnconfirmOnBenefitsChange] = useState(
    location.unconfirmOnBenefitsChange
  );

  const stages = [
    WorkflowStage.Todo,
    WorkflowStage.InProgress,
    WorkflowStage.Complete,
    WorkflowStage.Canceled,
  ];

  return (
    <div className="flex flex-col gap-4 w-full py-2">
      <div className="text-gray-500 text-sm">
        Your Verification Workflow defines the statuses that policies go through
        during your verification process. You can customize the statuses and the
        order in which they appear in the workflow.
      </div>
      {stages.map((stage) => {
        const stageStatuses = verificationWorkflowStatuses.filter(
          (status) => status.stage === stage
        );
        const lastPosition = Math.max(...stageStatuses.map((s) => s.position));
        const nextLargestPosition = Math.max(
          ...verificationWorkflowStatuses.map((s) => s.position)
        );
        const newPosition =
          nextLargestPosition > lastPosition
            ? // If there are no later positions, just add 1
              nextLargestPosition + 1
            : // Take the middle of the last position of the stage and the next largest position
              (lastPosition + nextLargestPosition) / 2;

        return (
          <div className="flex flex-col gap-2">
            <div className="flex justify-between">
              <div className="font-semibold text-gray-500">{stage}</div>
              <VerificationWorkflowFormDialogButton
                stage={stage}
                status={null}
                position={newPosition}
                locationId={location.id}
                dialogTrigger={<PlusIcon className="w-4 h-4 text-gray-500" />}
              />
              {/* <Button size="icon" onClick={() => {}}>
                <PlusIcon className="w-5 h-5" />
              </Button> */}
            </div>

            {stageStatuses.map((status, i) => {
              return (
                <div className="flex justify-between items-center border border-gray-100 py-1 px-4 rounded-md w-full">
                  <div className="flex items-center gap-2">
                    <VerificationWorkflowStatusIndicator status={status} />
                    {status.name}
                    {status.description && (
                      <span className="text-gray-500 text-sm">
                        {status.description}
                      </span>
                    )}
                  </div>
                  <div className="flex items-center gap-1">
                    {status.locationDefault && (
                      <span className="text-sm text-indigo-500">Default</span>
                    )}
                    <button
                      className="p-1 rounded-md hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
                      disabled={
                        i === 0 ||
                        moveVerificationWorkflowStatusPositionUpResult.loading
                      }
                      onClick={() => {
                        moveVerificationWorkflowStatusPositionUp({
                          variables: {
                            id: status.id,
                          },
                        });
                      }}
                    >
                      <ArrowUpIcon className="w-4 h-4 text-gray-500" />
                    </button>
                    <button
                      className="p-1 rounded-md hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
                      disabled={
                        i === stageStatuses.length - 1 ||
                        moveVerificationWorkflowStatusPositionDownResult.loading
                      }
                      onClick={() => {
                        moveVerificationWorkflowStatusPositionDown({
                          variables: {
                            id: status.id,
                          },
                        });
                      }}
                    >
                      <ArrowDownIcon className="w-4 h-4 text-gray-500" />
                    </button>

                    <VerificationWorkflowFormDialogButton
                      stage={stage}
                      status={status}
                      position={status.position}
                      locationId={location.id}
                      dialogTrigger={
                        <PencilIcon className="w-4 h-4 text-gray-500" />
                      }
                    />

                    {stageStatuses.length > 1 &&
                      !isDefined(status.locationDefault) && (
                        <RemoveVerificationWorkflowStatusDialogButton
                          status={status}
                          location={location}
                        />
                      )}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}

      <Divider />

      <div className="flex flex-col gap-4">
        <h2 className="text-lg">Workflow Automation</h2>

        <div className="border rounded-md p-4 my-2 gap-2 flex flex-col">
          <div className="flex items-center justify-between">
            <div className="text-sm">
              On policy confirmation, update status to...
            </div>
            <Select
              value={confirmationVerificationWorkflowStatusId}
              onValueChange={(value) => {
                const oldStatusId = confirmationVerificationWorkflowStatusId;
                setConfirmationVerificationWorkflowStatusId(value);
                updateLocationVerificationWorkflowSettings({
                  variables: {
                    locationId: location.id,
                    data: {
                      confirmationVerificationWorkflowStatus: {
                        ...(value
                          ? { connect: { id: value } }
                          : { disconnect: true }),
                      },
                    },
                  },
                  onCompleted: () => {
                    toast.success("Status updated");
                  },
                  onError: () => {
                    toast.error("Failed to update status");
                    setConfirmationVerificationWorkflowStatusId(oldStatusId);
                  },
                });
              }}
            >
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="No action" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="">
                    <div className="flex items-center gap-2">No action</div>
                  </SelectItem>
                  {verificationWorkflowStatuses
                    .filter(
                      (s) =>
                        s.stage !== WorkflowStage.Todo &&
                        s.stage !== WorkflowStage.Canceled
                    )
                    .map((status) => (
                      <SelectItem value={status.id}>
                        <div className="flex items-center gap-2">
                          <VerificationWorkflowStatusIndicator
                            status={status}
                          />
                          {status.name}
                        </div>
                      </SelectItem>
                    ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <div className="flex items-center justify-between">
            <div className="text-sm">
              On policy unconfirm, update status to...
            </div>
            <Select
              value={unconfirmationVerificationWorkflowStatusId}
              onValueChange={(value) => {
                const oldStatusId = unconfirmationVerificationWorkflowStatusId;
                setUnconfirmationVerificationWorkflowStatusId(value);
                updateLocationVerificationWorkflowSettings({
                  variables: {
                    locationId: location.id,
                    data: {
                      unconfirmationVerificationWorkflowStatus: {
                        ...(value
                          ? { connect: { id: value } }
                          : { disconnect: true }),
                      },
                    },
                  },
                  onCompleted: () => {
                    toast.success("Status updated");
                  },
                  onError: () => {
                    toast.error("Failed to update status");
                    setUnconfirmationVerificationWorkflowStatusId(oldStatusId);
                  },
                });
              }}
            >
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="No action" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="">
                    <div className="flex items-center gap-2">No action</div>
                  </SelectItem>
                  {verificationWorkflowStatuses
                    .filter(
                      (s) =>
                        s.stage !== WorkflowStage.Complete &&
                        s.stage !== WorkflowStage.Canceled
                    )
                    .map((status) => (
                      <SelectItem value={status.id}>
                        <div className="flex items-center gap-2">
                          <VerificationWorkflowStatusIndicator
                            status={status}
                          />
                          {status.name}
                        </div>
                      </SelectItem>
                    ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <div className="flex items-center justify-between">
            <div className="text-sm">On policy export, update status to...</div>
            <Select
              value={exportVerificationWorkflowStatusId}
              onValueChange={(value) => {
                const oldStatusId = exportVerificationWorkflowStatusId;
                setExportVerificationWorkflowStatusId(value);
                updateLocationVerificationWorkflowSettings({
                  variables: {
                    locationId: location.id,
                    data: {
                      exportVerificationWorkflowStatus: {
                        ...(value
                          ? { connect: { id: value } }
                          : { disconnect: true }),
                      },
                    },
                  },
                  onCompleted: () => {
                    toast.success("Status updated");
                  },
                  onError: () => {
                    toast.error("Failed to update status");
                    setExportVerificationWorkflowStatusId(oldStatusId);
                  },
                });
              }}
            >
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="No action" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="">
                    <div className="flex items-center gap-2">No action</div>
                  </SelectItem>
                  {verificationWorkflowStatuses
                    .filter(
                      (s) =>
                        s.stage !== WorkflowStage.Complete &&
                        s.stage !== WorkflowStage.Canceled
                    )
                    .map((status) => (
                      <SelectItem value={status.id}>
                        <div className="flex items-center gap-2">
                          <VerificationWorkflowStatusIndicator
                            status={status}
                          />
                          {status.name}
                        </div>
                      </SelectItem>
                    ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <div className="flex items-center justify-between">
            <div className="text-sm">
              On policy benefits email sent, update status to...
            </div>
            <Select
              value={emailBenefitsVerificationWorkflowStatusId}
              onValueChange={(value) => {
                const oldStatusId = emailBenefitsVerificationWorkflowStatusId;
                setEmailBenefitsVerificationWorkflowStatusId(value);
                updateLocationVerificationWorkflowSettings({
                  variables: {
                    locationId: location.id,
                    data: {
                      emailBenefitsVerificationWorkflowStatus: {
                        ...(value
                          ? { connect: { id: value } }
                          : { disconnect: true }),
                      },
                    },
                  },
                  onCompleted: () => {
                    toast.success("Status updated");
                  },
                  onError: () => {
                    toast.error("Failed to update status");
                    setEmailBenefitsVerificationWorkflowStatusId(oldStatusId);
                  },
                });
              }}
            >
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="No action" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="">
                    <div className="flex items-center gap-2">No action</div>
                  </SelectItem>
                  {verificationWorkflowStatuses
                    .filter(
                      (s) =>
                        s.stage !== WorkflowStage.Todo &&
                        s.stage !== WorkflowStage.Canceled
                    )
                    .map((status) => (
                      <SelectItem value={status.id}>
                        <div className="flex items-center gap-2">
                          <VerificationWorkflowStatusIndicator
                            status={status}
                          />
                          {status.name}
                        </div>
                      </SelectItem>
                    ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </div>

        <div>
          <div className="text-sm font-semibold text-gray-500">
            Unconfirm on Benefits Change
          </div>
          <div className="text-gray-500 text-sm">
            If enabled, when a policy's benefits change, the policy will be
            unconfirmed in the verification workflow.
          </div>
          <div className="flex items-center justify-between border rounded-md p-4 my-2">
            <div className="text-sm">Unconfirm on Benefits Change</div>
            <Switch
              checked={unconfirmOnBenefitsChange}
              onChange={(val) => {
                const oldVal = unconfirmOnBenefitsChange;
                setUnconfirmOnBenefitsChange(val);
                updateLocationVerificationWorkflowSettings({
                  variables: {
                    locationId: location.id,
                    data: { unconfirmOnBenefitsChange: { set: val } },
                  },
                  onCompleted: () => {
                    toast.success("Unconfirm on benefits change updated");
                  },
                  onError: () => {
                    toast.error(
                      "Failed to update unconfirm on benefits change"
                    );
                    setUnconfirmOnBenefitsChange(oldVal);
                  },
                });
              }}
              className={cn(
                unconfirmOnBenefitsChange ? "bg-indigo-600" : "bg-gray-200",
                "flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              )}
            >
              <span
                aria-hidden="true"
                className={cn(
                  unconfirmOnBenefitsChange
                    ? "translate-x-3.5"
                    : "translate-x-0",
                  "h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          </div>
        </div>

        <div>
          <div className="text-sm font-semibold text-gray-500">
            Automated Verification
          </div>
          <div className="text-gray-500 text-sm">
            Pledge will automatically verify policies used for upcoming
            appointments if they haven't been verified or need reverification.
          </div>
          <div className="flex items-center justify-between border rounded-md p-4 my-2">
            <div className="text-sm">Schedule-based Verification</div>
            <div className="text-sm">7 days ahead</div>
          </div>
        </div>

        <div>
          <div className="text-sm font-semibold text-gray-500">
            Auto-cancel inactive policies
          </div>
          <div className="text-gray-500 text-sm">
            Insurance policies will be automatically moved to the Canceled stage
            of the verification workflow if they are in the verification
            workflow due to the <span className="mono">Reverification</span>{" "}
            reason and all of it's upcoming appointments are in one of the
            configured inactive statuses.
          </div>
          <div className="flex items-center justify-between border rounded-md p-4 my-2">
            <div className="text-sm">Inactive Statuses</div>
            <div className="flex gap-2">
              {["Canceled", "No Show", "Rescheduled"].map((status) => (
                <Badge variant="info" text={status} />
              ))}
            </div>
          </div>
        </div>

        <div>
          <div className="text-sm font-semibold text-gray-500">
            Auto-archive stale policies
          </div>
          <div className="text-gray-500 text-sm">
            Insurance policies will be automatically archived and removed from
            the verification workflow if they are in the verification workflow
            if they have been in the verification workflow beyond the configured
            time period.
          </div>
          <div className="flex items-center justify-between border rounded-md p-4 my-2">
            <div className="text-sm">New Policy</div>
            <div className="text-sm">30 days</div>
          </div>
          <div className="flex items-center justify-between border rounded-md p-4 my-2">
            <div className="text-sm">Reverification</div>
            <div className="text-sm">7 days</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LocationBenefitSettings: React.FC<{ location: Location }> = ({
  location,
}) => {
  const [
    automatedAccumulatorTrackingEnabled,
    setAutomatedAccumulatorTrackingEnabled,
  ] = useState(location.automatedAccumulatorTrackingEnabled);
  const [
    updateLocationAutomatedAccumulatorTracking,
    updateLocationAutomatedAccumulatorTrackingResult,
  ] = useMutation<
    UpdateLocationAutomatedAccumulatorTracking,
    UpdateLocationAutomatedAccumulatorTrackingVariables
  >(UPDATE_LOCATION_AUTOMATED_ACCUMULATOR_TRACKING);

  const toggleLocationBillingSettings = (val: boolean) => {
    if (val) {
      setAutomatedAccumulatorTrackingEnabled(true);
      updateLocationAutomatedAccumulatorTracking({
        variables: { locationId: location.id, enabled: true },
        onCompleted: (data) => {
          toast.success("Automated Accumulator Tracking enabled");
        },
        onError: (error) => {
          toast.error("Error enabling Automated Accumulator Tracking");
          setAutomatedAccumulatorTrackingEnabled(false);
        },
      });
    } else {
      setAutomatedAccumulatorTrackingEnabled(false);
      updateLocationAutomatedAccumulatorTracking({
        variables: { locationId: location.id, enabled: false },
        onCompleted: (data) => {
          toast.success("Automated Accumulator Tracking disabled");
        },
        onError: (error) => {
          toast.error("Error disabling Automated Accumulator Tracking");
          setAutomatedAccumulatorTrackingEnabled(true);
        },
      });
    }
  };

  return (
    <div className="py-2">
      <div className="text-sm font-semibold text-gray-500">
        Automatically adjust benefit accumulators
      </div>
      <div className="text-gray-500 text-sm">
        Adjust the benefit accumulators of the policy based on activity with in
        Pledge. For example, count completed appointments towards a visit limit
        or apply estimates towards a deductible.
      </div>
      <div className="flex items-center justify-between border rounded-md p-4 my-2">
        <div className="text-sm">Automated Accumulator Tracking</div>
        <div className="flex flex-auto items-center justify-end gap-2">
          {updateLocationAutomatedAccumulatorTrackingResult.loading && (
            <OvalSpinner className="w-4 h-4 text-gray-700" />
          )}
          <Switch
            checked={automatedAccumulatorTrackingEnabled}
            onChange={toggleLocationBillingSettings}
            className={cn(
              automatedAccumulatorTrackingEnabled
                ? "bg-indigo-600"
                : "bg-gray-200",
              "flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            )}
            disabled={updateLocationAutomatedAccumulatorTrackingResult.loading}
          >
            <span
              aria-hidden="true"
              className={cn(
                automatedAccumulatorTrackingEnabled
                  ? "translate-x-3.5"
                  : "translate-x-0",
                "h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
              )}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export const WorkspaceLocationVerificationWorkflowSettings: React.FC<{
  organization: Organization;
}> = ({ organization }) => {
  const user = useUser()!;
  const { locationId } = useParams<{ locationId: string }>();
  const location = organization.locations.find((l) => l.id === locationId)!;
  return (
    <SettingsLayout
      title="Verification Workflow Settings"
      description={`Manage ${location.name}'s verification workflow settings.`}
      key={locationId}
    >
      <div className="flex flex-col gap-4">
        <Card>
          <LocationVerificationWorkflowSettings location={location} />
        </Card>
        {user.isPledgeUser && (
          <Card>
            <LocationBenefitSettings location={location} />
          </Card>
        )}
      </div>
    </SettingsLayout>
  );
};
