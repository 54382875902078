import { gql, useMutation, useQuery } from "@apollo/client";
import { Disclosure } from "@headlessui/react";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ChevronDownIcon,
  PlusIcon,
  XIcon,
} from "@heroicons/react/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import { DragHandleDots2Icon } from "@radix-ui/react-icons";
import { Divider } from "@tremor/react";
import { useState } from "react";
import { useFieldArray, useForm, useFormContext } from "react-hook-form";
import { toast } from "react-toastify";
import * as z from "zod";
import { SettingsLayout } from ".";
import { Card, SubmitButton } from "../../components";
import { Button } from "../../components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { SearchableCombobox } from "../../components/ui/searchable-combobox";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import {
  Sortable,
  SortableDragHandle,
  SortableItem,
} from "../../components/ui/sortable";
import { Switch } from "../../components/ui/switch";
import { GetMyOrganization_me_organization as Organization } from "../../generated/GetMyOrganization";
import {
  GetOrganizationBenefitPracticesConfigurations,
  GetOrganizationBenefitPracticesConfigurationsVariables,
  GetOrganizationBenefitPracticesConfigurations_organization as OrganizationWithBenefitPracticesConfigurations,
} from "../../generated/GetOrganizationBenefitPracticesConfigurations";
import { GetPayers, GetPayersVariables } from "../../generated/GetPayers";
import {
  UpdateProviderServiceConfiguration,
  UpdateProviderServiceConfigurationVariables,
} from "../../generated/UpdateProviderServiceConfiguration";
import { useUser } from "../../user-context";
import { cn, pascalCaseToReadable } from "../../utils";
import {
  PlaceOfService,
  ServiceTypeCode,
} from "../patients/eligibilities/types";
import { GET_PAYERS } from "../payers";
import { PayerCombobox } from "../rules/rule-inputs";

// Define the Zod schema for the form
const formSchema = z.object({
  organizationId: z.string(),
  providerServiceConfiguration: z.array(
    z.object({
      id: z.string().optional(),
      priorityOrder: z.number().nullable(),
      name: z.string().min(1, "Name is required"),
      serviceType: z.string().min(1, "Service type is required"),
      placeOfService: z.string().nullable().optional(),
      claimType: z.string().nullable().optional(),
      specialist: z.string().nullable().optional(),
      virtual: z.string().nullable().optional(),
      fallbackServiceTypes: z.array(z.string()),
      archived: z.boolean(),
      providerServiceConfigurationOverrides: z.array(
        z.object({
          id: z.string().optional(),
          payers: z.array(
            z.object({
              value: z.string(),
              label: z.string(),
            })
          ),
          serviceType: z.string(),
          fallbackServiceTypes: z.array(z.string()),
        })
      ),
      benefitNoteRules: z.array(
        z.object({
          id: z.string().optional(),
          value: z.string().min(1, "Value is required"),
          matchType: z.enum(["Contains", "DoesNotContain"]),
          actionType: z.enum(["Exclude", "Include", "Downrank", "Uprank"]),
          rank: z.number().nullable(),
        })
      ),
    })
  ),
});

type FormValues = z.infer<typeof formSchema>;

const PayerProviderServiceConfigurationOverrideForm: React.FC<{
  configIdx: number;
  overrideIdx: number;
  remove: (index: number) => void;
}> = ({ configIdx, overrideIdx, remove }) => {
  const user = useUser()!;
  const { data: payersData } = useQuery<GetPayers, GetPayersVariables>(
    GET_PAYERS,
    {
      variables: {
        organizationId: user.organization.id,
        locationId: user.activeLocation.id,
      },
    }
  );
  const payers = (payersData?.payers ?? []).map((payer) => ({
    id: payer.id,
    name: payer.name,
  }));

  const form = useFormContext<FormValues>();
  const override = form.watch(
    `providerServiceConfiguration.${configIdx}.providerServiceConfigurationOverrides.${overrideIdx}`
  );

  return (
    <Disclosure as="div" className="w-full divide-y border rounded-md p-2">
      {({ open }) => (
        <>
          <Disclosure.Button className="w-full group flex justify-between items-center px-2 py-2 text-base font-medium text-gray-600 group">
            <div className="text-lg group-hover:text-gray-500 text-left">
              Override for{" "}
              {override?.payers?.map((p) => p.label).join(", ") ?? (
                <span className="text-gray-500 italic">Not Available</span>
              )}
            </div>
            <ChevronDownIcon
              className={cn(
                open ? "text-gray-900 rotate-180" : "text-gray-600",
                "ml-auto h-5 w-5 group-hover:text-gray-500"
              )}
              aria-hidden="true"
            />
          </Disclosure.Button>
          <Disclosure.Panel className="px-4 pb-4">
            <div className="flex flex-col gap-4 pt-2">
              <FormField
                control={form.control}
                name={`providerServiceConfiguration.${configIdx}.providerServiceConfigurationOverrides.${overrideIdx}.payers`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Payers</FormLabel>
                    <FormControl>
                      <PayerCombobox
                        value={field.value.map((p) => p.value)}
                        payers={payers}
                        onSelect={(selectedPayerIds) => {
                          const selectedPayers = selectedPayerIds.map((id) => ({
                            value: id,
                            label: payers.find((p) => p.id === id)?.name || "",
                          }));
                          field.onChange(selectedPayers);
                        }}
                        isMulti={true}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={`providerServiceConfiguration.${configIdx}.providerServiceConfigurationOverrides.${overrideIdx}.serviceType`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Primary Service Type</FormLabel>
                    <FormDescription>
                      The first service type to use when requesting benefits.
                    </FormDescription>
                    <FormControl>
                      <SearchableCombobox
                        value={field.value}
                        options={Object.entries(ServiceTypeCode).map(
                          ([name, code]) => ({
                            value: code,
                            label: `${pascalCaseToReadable(name)} (${code})`,
                          })
                        )}
                        onSelect={(value) => field.onChange(value)}
                        placeholder="Select a service type"
                        emptyMessage="No service types found"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={`providerServiceConfiguration.${configIdx}.providerServiceConfigurationOverrides.${overrideIdx}.fallbackServiceTypes`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Fallback Service Types</FormLabel>
                    <FormDescription>
                      If the primary service type is not available, use these in
                      order.
                    </FormDescription>
                    <FormControl>
                      <div className="space-y-2">
                        {field.value.map((serviceType, i) => (
                          <div key={i} className="flex items-center space-x-2">
                            <Button
                              type="button"
                              variant="outline"
                              size="sm"
                              onClick={() => {
                                const newFallbackServiceTypes = [
                                  ...field.value,
                                ];
                                if (i > 0) {
                                  [
                                    newFallbackServiceTypes[i - 1],
                                    newFallbackServiceTypes[i],
                                  ] = [
                                    newFallbackServiceTypes[i],
                                    newFallbackServiceTypes[i - 1],
                                  ];
                                  field.onChange(newFallbackServiceTypes);
                                }
                              }}
                              disabled={i === 0}
                            >
                              <ArrowUpIcon className="h-4 w-4" />
                            </Button>
                            <Button
                              type="button"
                              variant="outline"
                              size="sm"
                              onClick={() => {
                                const newFallbackServiceTypes = [
                                  ...field.value,
                                ];
                                if (i < field.value.length - 1) {
                                  [
                                    newFallbackServiceTypes[i],
                                    newFallbackServiceTypes[i + 1],
                                  ] = [
                                    newFallbackServiceTypes[i + 1],
                                    newFallbackServiceTypes[i],
                                  ];
                                  field.onChange(newFallbackServiceTypes);
                                }
                              }}
                              disabled={i === field.value.length - 1}
                            >
                              <ArrowDownIcon className="h-4 w-4" />
                            </Button>
                            <SearchableCombobox
                              value={serviceType}
                              options={Object.entries(ServiceTypeCode)
                                .filter(
                                  ([_, code]) =>
                                    code !== override.serviceType &&
                                    (code === serviceType ||
                                      !field.value.includes(code))
                                )
                                .map(([name, code]) => ({
                                  value: code,
                                  label: `${pascalCaseToReadable(
                                    name
                                  )} (${code})`,
                                }))}
                              onSelect={(value) => {
                                const newFallbackServiceTypes = [
                                  ...field.value,
                                ];
                                newFallbackServiceTypes[i] = value as string;
                                field.onChange(newFallbackServiceTypes);
                              }}
                              placeholder="Select a service type"
                              emptyMessage="No service types found"
                            />
                            <Button
                              type="button"
                              variant="outline"
                              size="sm"
                              onClick={() => {
                                const newFallbackServiceTypes =
                                  field.value.filter((_, index) => index !== i);
                                field.onChange(newFallbackServiceTypes);
                              }}
                            >
                              <XIcon className="h-4 w-4" />
                            </Button>
                          </div>
                        ))}
                        <Button
                          type="button"
                          variant="outline"
                          size="sm"
                          onClick={() => {
                            const newFallbackServiceTypes = [
                              ...field.value,
                              "",
                            ];
                            field.onChange(newFallbackServiceTypes);
                          }}
                        >
                          Add fallback
                        </Button>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="flex justify-end">
                <Button
                  variant="destructive"
                  onClick={() => {
                    remove(overrideIdx);
                  }}
                >
                  Remove
                </Button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

const BenefitNoteRulesForm: React.FC<{
  configIdx: number;
}> = ({ configIdx }) => {
  const form = useFormContext<FormValues>();
  const { fields, append, remove, move } = useFieldArray({
    control: form.control,
    name: `providerServiceConfiguration.${configIdx}.benefitNoteRules`,
  });

  return (
    <div>
      <h3 className="text-lg font-medium">Benefit Note Rules</h3>
      <p className="text-sm text-gray-500">
        Configure rules for matching and ranking benefits based on their notes.
      </p>
      <Sortable
        value={fields.map((field, index) => ({
          id: field.id || index.toString(),
        }))}
        onMove={({ activeIndex, overIndex }) => move(activeIndex, overIndex)}
      >
        {fields.map((field, index) => (
          <SortableItem
            value={field.id}
            key={field.id}
            id={field.id || index.toString()}
          >
            <div className="flex items-center gap-2 mt-2">
              <SortableDragHandle variant="outline" size="sm" type="button">
                <DragHandleDots2Icon className="w-4 h-4" />
              </SortableDragHandle>
              <div className="flex-grow grid grid-cols-3 gap-2">
                <div className="flex items-center gap-2">
                  <div>If note</div>
                  <FormField
                    control={form.control}
                    name={`providerServiceConfiguration.${configIdx}.benefitNoteRules.${index}.matchType`}
                    render={({ field }) => (
                      <FormItem className="flex-grow">
                        <Select
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Match Type" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            <SelectItem value="Contains">Contains</SelectItem>
                            <SelectItem value="DoesNotContain">
                              Does Not Contain
                            </SelectItem>
                          </SelectContent>
                        </Select>
                      </FormItem>
                    )}
                  />
                </div>
                <FormField
                  control={form.control}
                  name={`providerServiceConfiguration.${configIdx}.benefitNoteRules.${index}.value`}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input {...field} placeholder="Value" />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <div className="flex items-center gap-2">
                  <div>then</div>
                  <FormField
                    control={form.control}
                    name={`providerServiceConfiguration.${configIdx}.benefitNoteRules.${index}.actionType`}
                    render={({ field }) => (
                      <FormItem className="flex-grow">
                        <Select
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Action Type" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            <SelectItem value="Exclude">Exclude</SelectItem>
                            <SelectItem value="Include">Include</SelectItem>
                            <SelectItem value="Downrank">Rank Lower</SelectItem>
                            <SelectItem value="Uprank">Rank Higher</SelectItem>
                          </SelectContent>
                        </Select>
                      </FormItem>
                    )}
                  />
                </div>
              </div>
              <Button
                type="button"
                variant="destructive"
                size="sm"
                onClick={() => remove(index)}
              >
                <XIcon className="h-4 w-4" />
              </Button>
            </div>
          </SortableItem>
        ))}
      </Sortable>
      <Button
        type="button"
        variant="outline"
        size="sm"
        onClick={() =>
          append({
            value: "",
            matchType: "Contains",
            actionType: "Include",
            rank: null,
          })
        }
        className="mt-2"
      >
        Add Rule
      </Button>
    </div>
  );
};

const ProviderServiceConfigurationForm: React.FC<{
  config: FormValues["providerServiceConfiguration"][number];
  idx: number;
}> = ({ config, idx }) => {
  const form = useFormContext<FormValues>();
  console.log({ config, idx, errors: form.formState.errors });
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: `providerServiceConfiguration.${idx}.providerServiceConfigurationOverrides`,
  });
  const fallbackServiceTypes =
    form.watch(`providerServiceConfiguration.${idx}.fallbackServiceTypes`) ||
    [];
  return (
    <Card className="w-full">
      <Disclosure as="div" key={config.id} className="w-full">
        {({ open }) => (
          <>
            <Disclosure.Button className="w-full group flex justify-between items-center px-2 py-2 text-base font-medium text-gray-600">
              <div className="text-lg">{config.name}</div>
              <ChevronDownIcon
                className={cn(
                  open ? "text-gray-900 rotate-180" : "text-gray-600",
                  "ml-auto h-5 w-5 group-hover:text-gray-500"
                )}
                aria-hidden="true"
              />
            </Disclosure.Button>
            <Disclosure.Panel className="px-4 pb-4">
              <div className="flex flex-col gap-4 pt-2 border-t">
                <FormField
                  control={form.control}
                  name={`providerServiceConfiguration.${idx}.name`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Name</FormLabel>
                      <FormDescription>
                        The display name for the benefit.
                      </FormDescription>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Divider className="my-2" />
                <FormField
                  control={form.control}
                  name={`providerServiceConfiguration.${idx}.serviceType`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Primary Service Type</FormLabel>
                      <FormDescription>
                        The first service type to use when requesting benefits.
                      </FormDescription>
                      <FormControl>
                        <SearchableCombobox
                          value={field.value}
                          options={Object.entries(ServiceTypeCode).map(
                            ([name, code]) => ({
                              value: code,
                              label: `${pascalCaseToReadable(name)} (${code})`,
                            })
                          )}
                          onSelect={(value) => field.onChange(value)}
                          placeholder="Select a service type"
                          emptyMessage="No service types found."
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name={`providerServiceConfiguration.${idx}.fallbackServiceTypes`}
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel>Fallback Service Types</FormLabel>
                        <FormDescription>
                          If the primary service type is not available, use
                          these in order.
                        </FormDescription>
                        <FormControl>
                          <div className="space-y-2">
                            {fallbackServiceTypes.map((serviceType, i) => (
                              <div
                                key={i}
                                className="flex items-center space-x-2"
                              >
                                <Button
                                  type="button"
                                  variant="outline"
                                  size="sm"
                                  onClick={() => {
                                    const newFallbackServiceTypes = [
                                      ...fallbackServiceTypes,
                                    ];
                                    if (i > 0) {
                                      [
                                        newFallbackServiceTypes[i - 1],
                                        newFallbackServiceTypes[i],
                                      ] = [
                                        newFallbackServiceTypes[i],
                                        newFallbackServiceTypes[i - 1],
                                      ];
                                      form.setValue(
                                        `providerServiceConfiguration.${idx}.fallbackServiceTypes`,
                                        newFallbackServiceTypes
                                      );
                                    }
                                  }}
                                  disabled={i === 0}
                                >
                                  <ArrowUpIcon className="h-4 w-4" />
                                </Button>
                                <Button
                                  type="button"
                                  variant="outline"
                                  size="sm"
                                  onClick={() => {
                                    const newFallbackServiceTypes = [
                                      ...fallbackServiceTypes,
                                    ];
                                    if (i < fallbackServiceTypes.length - 1) {
                                      [
                                        newFallbackServiceTypes[i],
                                        newFallbackServiceTypes[i + 1],
                                      ] = [
                                        newFallbackServiceTypes[i + 1],
                                        newFallbackServiceTypes[i],
                                      ];
                                      form.setValue(
                                        `providerServiceConfiguration.${idx}.fallbackServiceTypes`,
                                        newFallbackServiceTypes
                                      );
                                    }
                                  }}
                                  disabled={
                                    i === fallbackServiceTypes.length - 1
                                  }
                                >
                                  <ArrowDownIcon className="h-4 w-4" />
                                </Button>
                                <SearchableCombobox
                                  value={serviceType}
                                  options={Object.entries(ServiceTypeCode)
                                    .filter(
                                      ([_, code]) =>
                                        code !== config.serviceType &&
                                        (code === serviceType ||
                                          !fallbackServiceTypes.includes(code))
                                    )
                                    .map(([name, code]) => ({
                                      value: code,
                                      label: `${pascalCaseToReadable(
                                        name
                                      )} (${code})`,
                                    }))}
                                  onSelect={(value) => {
                                    const newFallbackServiceTypes = [
                                      ...fallbackServiceTypes,
                                    ];
                                    newFallbackServiceTypes[i] =
                                      value as string;
                                    form.setValue(
                                      `providerServiceConfiguration.${idx}.fallbackServiceTypes`,
                                      newFallbackServiceTypes
                                    );
                                  }}
                                  placeholder="Select a service type"
                                  emptyMessage="No service types found."
                                />
                                <Button
                                  type="button"
                                  variant="outline"
                                  size="sm"
                                  onClick={() => {
                                    const newFallbackServiceTypes =
                                      fallbackServiceTypes.filter(
                                        (_, index) => index !== i
                                      );
                                    form.setValue(
                                      `providerServiceConfiguration.${idx}.fallbackServiceTypes`,
                                      newFallbackServiceTypes
                                    );
                                  }}
                                >
                                  <XIcon className="h-4 w-4" />
                                </Button>
                              </div>
                            ))}
                            <Button
                              type="button"
                              variant="outline"
                              size="sm"
                              onClick={() => {
                                const newFallbackServiceTypes = [
                                  ...fallbackServiceTypes,
                                  "",
                                ];
                                form.setValue(
                                  `providerServiceConfiguration.${idx}.fallbackServiceTypes`,
                                  newFallbackServiceTypes
                                );
                              }}
                            >
                              Add fallback
                            </Button>
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  control={form.control}
                  name={`providerServiceConfiguration.${idx}.claimType`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Claim Type</FormLabel>
                      <FormDescription>
                        Is the benefit used for professional or institutional
                        claims.
                      </FormDescription>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value || undefined}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select a claim type" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="">None</SelectItem>
                          <SelectItem value="Professional">
                            Professional
                          </SelectItem>
                          <SelectItem value="Institutional">
                            Institutional
                          </SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name={`providerServiceConfiguration.${idx}.placeOfService`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Place of Service</FormLabel>
                      <FormDescription>
                        Where is the place of service for this benefit.
                      </FormDescription>
                      <FormControl>
                        <SearchableCombobox
                          value={field.value || ""}
                          options={[
                            { value: "", label: "None" },
                            ...Object.entries(PlaceOfService).map(
                              ([name, code]) => ({
                                value: code,
                                label: `${pascalCaseToReadable(
                                  name
                                )} (${code})`,
                              })
                            ),
                          ]}
                          onSelect={(value) => field.onChange(value)}
                          placeholder="Select a place of service"
                          emptyMessage="No places of service found."
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name={`providerServiceConfiguration.${idx}.specialist`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Specialist</FormLabel>
                      <FormDescription>
                        Is the provider considered a specialist for this
                        benefit.
                      </FormDescription>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value || undefined}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select specialist status" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="">None</SelectItem>
                          <SelectItem value="true">Yes</SelectItem>
                          <SelectItem value="false">No</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name={`providerServiceConfiguration.${idx}.virtual`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Virtual</FormLabel>
                      <FormDescription>
                        Is this benefit used for telehealth and other virtual
                        visits.
                      </FormDescription>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value || undefined}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select virtual status" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="">None</SelectItem>
                          <SelectItem value="true">Yes</SelectItem>
                          <SelectItem value="false">No</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Divider className="my-2" />
                <BenefitNoteRulesForm configIdx={idx} />
                <div>
                  <h3 className="text-lg font-medium">Payer Overrides</h3>
                  <p className="text-sm text-gray-500">
                    Override the default service type code behavior for a
                    specific payer.
                  </p>
                  <div className="mt-2 space-y-2">
                    {fields.map((field, overrideIdx) => (
                      <PayerProviderServiceConfigurationOverrideForm
                        key={field.id}
                        configIdx={idx}
                        overrideIdx={overrideIdx}
                        remove={remove}
                      />
                    ))}
                    <Button
                      type="button"
                      variant="outline"
                      size="sm"
                      onClick={() => {
                        append({
                          id: undefined,
                          payers: [],
                          serviceType: config.serviceType,
                          fallbackServiceTypes:
                            config.fallbackServiceTypes || [],
                        });
                      }}
                    >
                      Add payer override
                    </Button>
                  </div>
                </div>
                <FormField
                  control={form.control}
                  name={`providerServiceConfiguration.${idx}.archived`}
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                      <div className="space-y-0.5">
                        <FormLabel className="text-base">Archived</FormLabel>
                        <FormDescription>
                          Whether this benefit configuration is archived.
                        </FormDescription>
                      </div>
                      <FormControl>
                        <Switch
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </Card>
  );
};

const UPDATE_PROVIDER_SERVICE_CONFIGURATION = gql`
  mutation UpdateProviderServiceConfiguration(
    $organizationId: String!
    $providerServiceConfiguration: ProviderServiceConfigurationUpdateManyWithoutOrganizationNestedInput!
  ) {
    updateOneOrganization(
      where: { id: $organizationId }
      data: { providerServiceConfiguration: $providerServiceConfiguration }
    ) {
      id
      providerServiceConfiguration(
        where: { archivedAt: null }
        orderBy: { priorityOrder: { sort: asc, nulls: last } }
      ) {
        id
        priorityOrder
        name
        shortName
        serviceType
        fallbackServiceTypes
        placeOfService
        specialist
        virtual
        claimType
        archivedAt
        providerTaxonomyClassification {
          id
          name
        }
        providerServiceConfigurationOverrides {
          id
          payerProviderServiceConfigurationOverrides {
            id
            payer {
              id
              name
            }
          }
          serviceType
          fallbackServiceTypes
        }
        benefitNoteRules {
          id
          value
          matchType
          actionType
          rank
        }
      }
    }
  }
`;

const stringToBoolean = (value: string | null) => {
  if (value === "true") return true;
  if (value === "false") return false;
  return null;
};

const GET_ORGANIZATION_BENEFIT_PRACTIC_CONFIGURATIONS = gql`
  query GetOrganizationBenefitPracticesConfigurations(
    $organizationId: String!
  ) {
    organization(where: { id: $organizationId }) {
      id
      providerServiceConfiguration(
        orderBy: [
          { archivedAt: { sort: desc, nulls: first } }
          { priorityOrder: { sort: asc, nulls: last } }
        ]
      ) {
        id
        priorityOrder
        name
        shortName
        serviceType
        fallbackServiceTypes
        placeOfService
        specialist
        virtual
        claimType
        archivedAt
        providerTaxonomyClassification {
          id
          name
        }
        providerServiceConfigurationOverrides {
          id
          payerProviderServiceConfigurationOverrides {
            id
            payer {
              id
              name
            }
          }
          serviceType
          fallbackServiceTypes
        }
        benefitNoteRules {
          id
          value
          matchType
          actionType
          rank
        }
      }
    }
  }
`;

export const WorkspaceBenefitsSettings: React.FC<{
  organization: Organization;
}> = ({ organization }) => {
  const { data, loading } = useQuery<
    GetOrganizationBenefitPracticesConfigurations,
    GetOrganizationBenefitPracticesConfigurationsVariables
  >(GET_ORGANIZATION_BENEFIT_PRACTIC_CONFIGURATIONS, {
    variables: { organizationId: organization.id },
  });

  if (loading) {
    return (
      <SettingsLayout
        title="Benefits"
        description="Manage your benefits settings."
      >
        <div className="space-y-4">
          {[...Array(3)].map((_, index) => (
            <div key={index} className="border rounded-lg p-4">
              <div className="h-6 bg-gray-200 rounded w-1/3 mb-2"></div>
              <div className="space-y-2">
                <div className="h-4 bg-gray-200 rounded w-full"></div>
                <div className="h-4 bg-gray-200 rounded w-2/3"></div>
              </div>
            </div>
          ))}
        </div>
      </SettingsLayout>
    );
  }
  if (!data) return <div>Error</div>;

  return <WorkspaceBenefitsSettingsForm organization={data.organization!} />;
};

export const WorkspaceBenefitsSettingsForm: React.FC<{
  organization: OrganizationWithBenefitPracticesConfigurations;
}> = ({ organization }) => {
  const [showArchived, setShowArchived] = useState(false);
  const [
    updateProviderServiceConfiguration,
    updateProviderServiceConfigurationResult,
  ] = useMutation<
    UpdateProviderServiceConfiguration,
    UpdateProviderServiceConfigurationVariables
  >(UPDATE_PROVIDER_SERVICE_CONFIGURATION);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      organizationId: organization.id,
      providerServiceConfiguration:
        organization.providerServiceConfiguration.map((config) => ({
          id: config.id,
          priorityOrder: config.priorityOrder,
          name: config.name,
          serviceType: config.serviceType,
          fallbackServiceTypes: config.fallbackServiceTypes,
          placeOfService: config.placeOfService,
          claimType: config.claimType,
          specialist: config.specialist?.toString(),
          virtual: config.virtual?.toString(),
          archived: !!config.archivedAt,
          providerServiceConfigurationOverrides:
            config.providerServiceConfigurationOverrides.map((override) => ({
              id: override.id,
              payers: override.payerProviderServiceConfigurationOverrides.map(
                (ppso) => ({
                  value: ppso.payer.id,
                  label: ppso.payer.name,
                })
              ),
              serviceType: override.serviceType,
              fallbackServiceTypes: override.fallbackServiceTypes,
            })),
          benefitNoteRules: config.benefitNoteRules.map((rule) => ({
            id: rule.id,
            value: rule.value,
            matchType: rule.matchType,
            actionType: rule.actionType,
            rank: rule.rank,
          })),
        })),
    },
  });

  const { fields, append, insert, move } = useFieldArray({
    control: form.control,
    name: "providerServiceConfiguration",
    keyName: "key",
  });

  const activeFields = fields.filter((field) => !field.archived);
  const archivedFields = fields.filter((field) => field.archived);

  const displayedFields = showArchived
    ? [...activeFields, ...archivedFields]
    : activeFields;

  const addNewConfig = () => {
    insert(activeFields.length, {
      // id: null,
      priorityOrder: activeFields.length,
      name: "",
      serviceType: "",
      fallbackServiceTypes: [],
      placeOfService: null,
      claimType: null,
      specialist: null,
      virtual: null,
      archived: false,
      providerServiceConfigurationOverrides: [],
      benefitNoteRules: [],
    });
  };

  function onSubmit(data: FormValues) {
    const configs = organization.providerServiceConfiguration;
    const toUpdate = data.providerServiceConfiguration
      .filter((config) => config.id)
      .map((config) => {
        const psc = organization.providerServiceConfiguration.find(
          (p) => p.id === config.id
        );
        const priorityOrder = activeFields.findIndex((c) => c.id === config.id);
        const overridesToDelete =
          configs
            .find((c) => c.id === config.id)
            ?.providerServiceConfigurationOverrides.filter(
              (override) =>
                !config.providerServiceConfigurationOverrides.find(
                  (o) => o.id === override.id
                )
            )
            .map((override) => override.id) ?? [];
        const overridesToUpdate = config.providerServiceConfigurationOverrides
          .filter((override) => override.id)
          .map((override) => {
            return {
              where: { id: override.id },
              data: {
                payerProviderServiceConfigurationOverrides: {
                  connectOrCreate: override.payers.map((payer) => ({
                    where: {
                      providerServiceConfigurationOverrideId_payerId: {
                        providerServiceConfigurationOverrideId: override.id,
                        payerId: payer.value,
                      },
                    },
                    create: {
                      payer: { connect: { id: payer.value } },
                    },
                  })),
                },
                serviceType: { set: override.serviceType },
                fallbackServiceTypes: {
                  set: override.fallbackServiceTypes ?? [],
                },
                organization: { connect: { id: organization.id } },
              },
            };
          });
        const overridesToCreate = config.providerServiceConfigurationOverrides
          .filter((override) => !override.id)
          .map((override) => ({
            payerProviderServiceConfigurationOverrides: {
              create: override.payers.map((payer) => ({
                payer: { connect: { id: payer.value } },
              })),
            },
            serviceType: override.serviceType,
            fallbackServiceTypes: {
              set: override.fallbackServiceTypes ?? [],
            },
            organization: { connect: { id: organization.id } },
          }));

        const rulesToDelete =
          psc?.benefitNoteRules
            .filter(
              (rule) => !config.benefitNoteRules.find((r) => r.id === rule.id)
            )
            .map((rule) => rule.id) ?? [];

        const rulesToUpdate = config.benefitNoteRules
          .filter((rule) => rule.id)
          .map((rule) => ({
            where: { id: rule.id },
            data: {
              value: { set: rule.value },
              matchType: { set: rule.matchType },
              actionType: { set: rule.actionType },
              rank: { set: rule.rank },
            },
          }));

        const rulesToCreate = config.benefitNoteRules
          .filter((rule) => !rule.id)
          .map((rule) => ({
            value: rule.value,
            matchType: rule.matchType,
            actionType: rule.actionType,
            rank: rule.rank,
          }));

        return {
          where: { id: config.id },
          data: {
            priorityOrder: { set: priorityOrder },
            name: { set: config.name },
            serviceType: { set: config.serviceType },
            fallbackServiceTypes: { set: config.fallbackServiceTypes },
            placeOfService: {
              set: config.placeOfService ? (config.placeOfService.trim() || null) : null,
            },
            claimType: {
              set: config.claimType ? (config.claimType.trim() || null) : null,
            },
            specialist: { set: stringToBoolean(config.specialist ?? null) },
            virtual: { set: stringToBoolean(config.virtual ?? null) },
            archivedAt: {
              set: config.archived ? psc?.archivedAt ?? new Date() : null,
            },
            // TODO payerProviderServiceConfigurationOverrides
            providerServiceConfigurationOverrides: {
              delete: overridesToDelete.map((id) => ({ id })),
              update: overridesToUpdate,
              create: overridesToCreate,
            },
            benefitNoteRules: {
              delete: rulesToDelete.map((id) => ({ id })),
              update: rulesToUpdate,
              create: rulesToCreate,
            },
          },
        };
      });
    const toCreate = data.providerServiceConfiguration
      .filter((config) => !config.id)
      .map((config) => {
        const psc = organization.providerServiceConfiguration.find(
          (p) => p.id === config.id
        );
        const priorityOrder = activeFields.findIndex((c) => c.id === config.id);
        return {
          priorityOrder: priorityOrder,
          name: config.name,
          serviceType: config.serviceType,
          fallbackServiceTypes: {
            set: config.fallbackServiceTypes ?? [],
          },
          placeOfService: config.placeOfService ? (config.placeOfService.trim() || null) : null,
          claimType: config.claimType ? (config.claimType.trim() || null) : null,
          specialist: stringToBoolean(config.specialist ?? null),
          virtual: stringToBoolean(config.virtual ?? null),
          archivedAt: config.archived ? psc?.archivedAt ?? new Date() : null,
          providerServiceConfigurationOverrides: {
            create: config.providerServiceConfigurationOverrides.map(
              (override) => ({
                payerProviderServiceConfigurationOverrides: override.payers.map(
                  (payer) => ({
                    payer: { connect: { id: payer.value } },
                  })
                ),
                serviceType: override.serviceType,
                fallbackServiceTypes: {
                  set: override.fallbackServiceTypes ?? [],
                },
                organization: { connect: { id: organization.id } },
              })
            ),
          },
          benefitNoteRules: {
            create: config.benefitNoteRules.map((rule) => ({
              value: rule.value,
              matchType: rule.matchType,
              actionType: rule.actionType,
              rank: rule.rank,
            })),
          },
        };
      });

    updateProviderServiceConfiguration({
      variables: {
        organizationId: organization.id,
        providerServiceConfiguration: {
          create: toCreate,
          update: toUpdate,
        },
      },
      onCompleted: () => {
        toast.success("Settings updated");
      },
      onError: (error) => {
        toast.error("Error updating settings");
      },
    });
  }

  return (
    <SettingsLayout
      title="Benefits"
      description="Manage your benefits settings."
    >
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <div className="flex flex-col">
            <div>
              <label className="truncate text-lg font-medium text-gray-900">
                Practice Benefit Configurations
              </label>
              <p className="text-gray-500 text-sm py-1">
                Benefit configurations are used to determine which benefits to
                track and display for your practice. They're also used to
                determine which service type codes to request from payers in
                eligibility requests and which benefits to look for in the
                response.
              </p>
            </div>
            <div className="mt-2 flex flex-col gap-4">
              <Sortable
                value={fields.map((c) => ({ id: c.key }))}
                onMove={({ activeIndex, overIndex }) =>
                  move(activeIndex, overIndex)
                }
              >
                {fields.map((config, idx) => {
                  const visible = displayedFields.some(
                    (c) => c.id === config.id
                  );
                  const anyErrors =
                    form.formState.errors.providerServiceConfiguration?.[idx];
                  return (
                    <SortableItem
                      key={config.id}
                      id={config.id}
                      className={cn(
                        config.serviceType === "30" ? "hidden" : "",
                        visible ? "" : "hidden"
                      )}
                      value={config.key}
                    >
                      <div className="flex items-center gap-2 w-full">
                        <SortableDragHandle
                          variant="outline"
                          size="sm"
                          type="button"
                        >
                          <DragHandleDots2Icon className="w-4 h-4" />
                        </SortableDragHandle>
                        <div
                          className={cn(
                            "w-full",
                            anyErrors
                              ? "ring-red-500 ring-2 ring-offset-1 rounded-md"
                              : "",
                            config.archived ? "opacity-70" : ""
                          )}
                        >
                          <ProviderServiceConfigurationForm
                            config={config}
                            idx={idx}
                          />
                        </div>
                      </div>
                    </SortableItem>
                  );
                })}
              </Sortable>
            </div>
          </div>
          <div className="py-4 flex justify-between items-center">
            <div className="space-x-2">
              <Button type="button" variant="outline" onClick={addNewConfig}>
                <PlusIcon className="h-4 w-4 mr-2" />
                Add New Config
              </Button>
              <Button
                type="button"
                variant="link"
                onClick={() => setShowArchived(!showArchived)}
              >
                {showArchived ? "Hide Archived" : "Show Archived"}
              </Button>
            </div>
            <div>
              <SubmitButton
                loading={updateProviderServiceConfigurationResult.loading}
              >
                Save
              </SubmitButton>
            </div>
          </div>
        </form>
      </Form>
    </SettingsLayout>
  );
};
