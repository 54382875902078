import { Cross2Icon, DotsVerticalIcon } from "@radix-ui/react-icons";
import { Table } from "@tanstack/react-table";
import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";

import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { DataTableViewOptions } from "../../components/ui/table-helpers/data-table-view-options";
import { DataTableFacetedFilter } from "../../components/ui/table-helpers/data-table-faceted-filter";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { Checkbox } from "../../components/ui/checkbox";
import { Spinner } from "../../components";

import { GetPayers, GetPayersVariables } from "../../generated/GetPayers";
import { useUser } from "../../user-context";
import { GET_PAYERS } from "../payers";
import { SkipForward } from "lucide-react";
import { VerificationWorkflowReason } from "../../generated/globalTypes";
import {
  cn,
  formatDateMMDDYYYY,
  formatPercentage,
  mapNullable,
} from "../../utils";
import {
  BulkWorklistItemActionMenu,
  GET_VERIFICATION_WORKFLOW_POLICIES,
} from "./list";
import { toast } from "react-toastify";
import {
  RunIntegrationSync,
  RunIntegrationSyncVariables,
} from "../../generated/RunIntegrationSync";
import { RefreshIcon, DocumentDownloadIcon } from "@heroicons/react/outline";
import { useDashboardResizable } from "../../dashboard-resizable";
import { VerificationSidePanel } from "./side-panel";
import { VerificationWorklistRow } from "./columns";
import { GET_ACCOUNT_TYPES } from "../billing";
import {
  GetAccountTypes,
  GetAccountTypesVariables,
} from "../../generated/GetAccountTypes";
import { useAnalytics } from "../../analytics-context";
import { useQueryClient } from "react-query";
import { CSVLink } from "react-csv";
import { parseISO } from "date-fns";

interface DataTableToolbarProps<TData extends { id: string }> {
  table: Table<TData>;
}

const REASON_OPTIONS: {
  value: string;
  label: string;
}[] = [
  {
    value: VerificationWorkflowReason.NewPolicy,
    label: "New Policy",
  },
  {
    value: VerificationWorkflowReason.Reverification,
    label: "Reverification",
  },
  {
    value: VerificationWorkflowReason.ManuallyMoved,
    label: "Manually Moved",
  },
];

const ELIGIBILITY_STATUS_OPTIONS = [
  {
    value: "Active",
    label: "Active",
  },
  {
    value: "Inactive",
    label: "Inactive",
  },
  {
    value: "NeedsReverification",
    label: "Needs Reverification",
  },
  {
    value: "Error",
    label: "Error",
  },
  {
    value: "Unverified",
    label: "Unverified",
  },
];

const BENEFITS_EMAIL_SENT_OPTIONS = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

const EXPORT_MULTIPLE_INSURANCE_POLICIES = gql`
  mutation ExportMultipleInsurancePolicies($insurancePolicyIds: [String!]!) {
    exportMultipleInsurancePolicies(insurancePolicyIds: $insurancePolicyIds) {
      id
      lastExportedAt
      verificationWorkflowStatus {
        id
        stage
        name
      }
    }
  }
`;

const RUN_INTEGRATION_SYNC = gql`
  mutation RunIntegrationSync($integrationId: String!) {
    runIntegrationSync(integrationId: $integrationId) {
      synced
      errors {
        message
      }
    }
  }
`;

export const VerificationListDownloadButton: React.FC<{
  rows: VerificationWorklistRow[];
  onComplete?: () => void;
  className?: string;
}> = ({ rows, onComplete, className }) => {
  const user = useUser()!;
  const analytics = useAnalytics();
  const [exportMultipleInsurancePolicies] = useMutation(
    EXPORT_MULTIPLE_INSURANCE_POLICIES
  );

  const [headerRow, setHeaderRow] = useState([
    { id: "Date", label: "Date", checked: true },
    { id: "Patient", label: "Patient", checked: true },
    { id: "Account(s)", label: "Account(s)", checked: true },
    { id: "Reason", label: "Reason", checked: true },
    { id: "Next Appointment", label: "Next Appointment", checked: true },
    { id: "Member ID(s)", label: "Member ID(s)", checked: true },
    { id: "Payer(s)", label: "Payer(s)", checked: true },
    {
      id: "Verification Status(s)",
      label: "Verification Status(s)",
      checked: true,
    },
    { id: "Last Verified(s)", label: "Last Verified(s)", checked: true },
    {
      id: "Completeness Score(s)",
      label: "Completeness Score(s)",
      checked: true,
    },
    {
      id: "Services with Benefits",
      label: "Services with Benefits",
      checked: true,
    },
    {
      id: "Services without Benefits",
      label: "Services without Benefits",
      checked: true,
    },
  ]);

  const csvRows = rows.map((row) => {
    const dataRow = [];
    if (headerRow.find((h) => h.id === "Date")?.checked)
      dataRow.push(
        mapNullable((date: Date) => formatDateMMDDYYYY(date.toISOString()))(
          row.date
        )
      );
    if (headerRow.find((h) => h.id === "Patient")?.checked)
      dataRow.push(row.patientName);
    if (headerRow.find((h) => h.id === "Account(s)")?.checked)
      dataRow.push(
        row.policy.accounts
          .filter((a) => a.accountType)
          .map((a) => a.accountType!.name)
          .join(", ")
      );
    if (headerRow.find((h) => h.id === "Reason")?.checked)
      dataRow.push(row.lastVerificationWorkflowReason);
    if (headerRow.find((h) => h.id === "Next Appointment")?.checked)
      dataRow.push(
        mapNullable((date: Date) => formatDateMMDDYYYY(date.toISOString()))(
          row.nextAppointmentStart
        )
      );
    if (headerRow.find((h) => h.id === "Member ID(s)")?.checked)
      dataRow.push(row.memberId);
    if (headerRow.find((h) => h.id === "Payer(s)")?.checked)
      dataRow.push(row.payerName);
    if (headerRow.find((h) => h.id === "Verification Status(s)")?.checked)
      dataRow.push(row.policy.insurancePolicyVerificationStatus);
    if (headerRow.find((h) => h.id === "Last Verified(s)")?.checked)
      dataRow.push(
        mapNullable(formatDateMMDDYYYY)(
          row.policy.mostRecentEligibilityRequest?.createdAt
        )
      );
    if (headerRow.find((h) => h.id === "Completeness Score(s)")?.checked)
      dataRow.push(mapNullable(formatPercentage)(row.completeness));
    if (headerRow.find((h) => h.id === "Services with Benefits")?.checked)
      dataRow.push(
        row.policy.mostRecentCoverageBenefits
          .filter((cb) => cb.empty === false)
          .map((cb) => cb.providerServiceConfiguration.name)
          .join(", ")
      );
    if (headerRow.find((h) => h.id === "Services without Benefits")?.checked)
      dataRow.push(
        row.policy.mostRecentCoverageBenefits
          .filter((cb) => cb.empty === true)
          .map((cb) => cb.providerServiceConfiguration.name)
          .join(", ")
      );
    return dataRow;
  });

  const enabledHeaders = headerRow.filter((h) => h.checked).map((h) => h.label);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          className={
            className ??
            "h-9 px-2 py-2 inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground"
          }
        >
          <DocumentDownloadIcon className="h-5 w-5" aria-hidden="true" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Download CSV</DialogTitle>
          <DialogDescription>
            Select the columns you want to include in the CSV file.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          {headerRow.map(({ id, label, checked }) => (
            <div key={id} className="flex items-center space-x-2">
              <Checkbox
                id={id}
                checked={checked}
                onCheckedChange={(checked) => {
                  setHeaderRow((prev) =>
                    prev.map((row) =>
                      row.id === id
                        ? { ...row, checked: Boolean(checked.valueOf()) }
                        : row
                    )
                  );
                }}
              />
              <label htmlFor={id} className="text-sm font-medium leading-none">
                {label}
              </label>
            </div>
          ))}
        </div>
        <DialogFooter>
          <CSVLink
            data={[enabledHeaders, ...csvRows]}
            filename={`verifications-${formatDateMMDDYYYY(
              new Date().toISOString()
            )}.csv`}
            className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
            onClick={() => {
              analytics?.track("Verifications Worklist Downloaded", {
                organizationId: user.organization.id,
                organizationName: user.organization.name,
                locationId: user.activeLocation.id,
                locationName: user.activeLocation.name,
              });
              exportMultipleInsurancePolicies({
                variables: {
                  insurancePolicyIds: rows.map((r) => r.id),
                },
                onCompleted: onComplete,
              });
            }}
          >
            Download
            <DocumentDownloadIcon
              className="ml-2 -mr-1 h-5 w-5"
              aria-hidden="true"
            />
          </CSVLink>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export function DataTableToolbar({
  table,
}: DataTableToolbarProps<VerificationWorklistRow>) {
  const user = useUser()!;
  const analytics = useAnalytics();
  const queryClient = useQueryClient();
  const { setPanelChild } = useDashboardResizable();
  const integrations = user.activeLocation.integrations;
  const integrationsSupportingSync = integrations.filter(
    (integration) => integration.supportsOnDemandSync
  );
  const payersResult = useQuery<GetPayers, GetPayersVariables>(GET_PAYERS, {
    variables: {
      organizationId: user.organization.id,
      locationId: user.activeLocation.id,
    },
  });
  const accountTypesResult = useQuery<
    GetAccountTypes,
    GetAccountTypesVariables
  >(GET_ACCOUNT_TYPES, {
    variables: {
      locationId: user.activeLocation.id,
    },
  });
  const [runIntegrationSync, runIntegrationSyncResult] = useMutation<
    RunIntegrationSync,
    RunIntegrationSyncVariables
  >(RUN_INTEGRATION_SYNC);

  const statusOptions = user.activeLocation.verificationWorkflowStatuses.map(
    (s) => ({ value: s.name, label: s.name })
  );

  const payerOptions = Array.from(
    new Set(payersResult.data?.payers.map((p) => p.name) ?? [])
  ).map((payer) => ({ value: payer, label: payer }));

  const accountTypeOptions = Array.from(
    new Set(accountTypesResult?.data?.accountTypes?.map((p) => p.name) ?? [])
  ).map((payer) => ({ value: payer, label: payer }));

  const isFiltered = table.getState().columnFilters.length > 0;

  return (
    <div className="flex items-start justify-between gap-2">
      <div className="flex flex-1 items-center flex-wrap gap-2">
        <Input
          placeholder="Filter by Patient or Member ID"
          value={table.getState().globalFilter ?? ""}
          onChange={(event) => table.setGlobalFilter(event.target.value)}
          className="h-8 w-[150px] lg:w-[250px]"
        />
        {table.getColumn("status") && (
          <DataTableFacetedFilter
            column={table.getColumn("status")}
            title="Status"
            options={statusOptions}
          />
        )}
        {table.getColumn("lastVerificationWorkflowReason") && (
          <DataTableFacetedFilter
            column={table.getColumn("lastVerificationWorkflowReason")}
            title="Reason"
            options={REASON_OPTIONS}
          />
        )}
        {table.getColumn("patientName") && (
          <DataTableFacetedFilter
            column={table.getColumn("patientName")}
            title="Account"
            options={accountTypeOptions}
            sortByCount={true}
          />
        )}
        {table.getColumn("payerName") && (
          <DataTableFacetedFilter
            column={table.getColumn("payerName")}
            title="Payer"
            options={payerOptions}
            sortByCount={true}
          />
        )}
        {table.getColumn("eligibilityStatus") && (
          <DataTableFacetedFilter
            column={table.getColumn("eligibilityStatus")}
            title="Eligibility Status"
            options={ELIGIBILITY_STATUS_OPTIONS}
          />
        )}
        {table.getColumn("benefitsEmailSent") && (
          <DataTableFacetedFilter
            column={table.getColumn("benefitsEmailSent")}
            title="Benefits Email Sent"
            options={BENEFITS_EMAIL_SENT_OPTIONS}
          />
        )}

        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3"
          >
            Reset
            <Cross2Icon className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
      <Button
        className="h-8"
        size="sm"
        disabled={table.getFilteredRowModel().rows.length === 0}
        onClick={() => {
          setPanelChild(
            <VerificationSidePanel
              ids={table
                .getFilteredRowModel()
                .rows.map((row) => row.original.id)}
            />
          );
          analytics?.track("Verification Worklist Batch Opened", {
            organizationId: user.organization.id,
            organizationName: user.organization.name,
            locationId: user.activeLocation.id,
            locationName: user.activeLocation.name,
            count: table.getFilteredRowModel().rows.length,
          });
          table.resetRowSelection();
        }}
        type="button"
      >
        Execute Tasks
        <SkipForward className="ml-2 h-4 w-4" />
      </Button>
      {integrationsSupportingSync.map((integration) => (
        <Button
          onClick={() =>
            runIntegrationSync({
              variables: { integrationId: integration.id },
              onCompleted: (data) => {
                analytics?.track("Integration Sync Run", {
                  organizationId: user.organization.id,
                  organizationName: user.organization.name,
                  locationId: user.activeLocation.id,
                  locationName: user.activeLocation.name,
                  integrationId: integration.id,
                  integrationType: integration.type,
                });
                if (data.runIntegrationSync.errors?.at(0)) {
                  toast.error(data.runIntegrationSync.errors[0].message);
                } else {
                  toast.success("Synced from " + integration.name);
                  queryClient.invalidateQueries("verificationWorklist");
                }
              },
              onError: () => {
                toast.error("Error syncing from " + integration.name);
              },
            })
          }
          className="h-8"
          size="sm"
          disabled={runIntegrationSyncResult.loading}
        >
          Sync from {integration.name}
          <RefreshIcon
            className={cn(
              "h-4 w-4 ml-2",
              runIntegrationSyncResult.loading && "animate-spin"
            )}
          />
        </Button>
      ))}
      <VerificationListDownloadButton
        rows={table.getFilteredRowModel().rows.map((row) => row.original)}
      />
      <BulkWorklistItemActionMenu
        worklistRows={table.getSortedRowModel().rows.map((row) => row.original)}
        trigger={
          <Button variant="outline" size="sm" className="h-8" type="button">
            <DotsVerticalIcon className="h-4 w-4" />
          </Button>
        }
        onClose={() => {
          table.resetRowSelection();
        }}
      />
      <DataTableViewOptions table={table} />
    </div>
  );
}
