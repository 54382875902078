import { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "../../../components/ui/table-helpers/data-table-column-header";
import { Link } from "react-router-dom";
import {
  BriefcaseIcon,
  CashIcon,
  CheckCircleIcon,
  CheckIcon,
  ClockIcon,
  CogIcon,
  ExclamationCircleIcon,
  MinusIcon,
  InformationCircleIcon,
  ExternalLinkIcon,
} from "@heroicons/react/outline";
import { StickyNote } from "lucide-react";
import { CreditCardIcon } from "@heroicons/react/solid";
import { formatDateMMDDYYYY, formatUSD, isDefined } from "../../../utils";
import { format, isBefore, parseISO, isValid } from "date-fns";
import {
  GetLocalAppointments_localAppointments_appointment as Appointment,
  GetLocalAppointments_localAppointments_appointment_accountCoverages_insurancePolicy as InsurancePolicy,
  GetLocalAppointments_localAppointments_appointment_account as Account,
  GetLocalAppointments_localAppointments_appointment_accountCoverages_insurancePolicy_mostRecentEligibilityRequest as EligibilityRequest,
  GetLocalAppointments_localAppointments_appointment_estimates as Estimate,
  GetLocalAppointments_localAppointments_appointment_mostRecentVisitCollectionRequest as VisitCollectionRequest,
  GetLocalAppointments_localAppointments_appointment_account_patient_patientLabelings_patientLabel as PatientLabel,
  GetLocalAppointments_localAppointments_appointment_account_patient_notes as Note,
} from "../../../generated/GetLocalAppointments";
import { AdditionalPlanAcknowledgements } from "../../patients/insurance-policy-summary";
import { CreateNote, CreateNoteVariables } from "../../../generated/CreateNote";
import { UpdateNote, UpdateNoteVariables } from "../../../generated/UpdateNote";
import {
  EligibilityStatusBadge,
  InsurancePolicyPriorityBadge,
  TermedInsurancePolicyBadge,
} from "..";
import { Card, Spinner, Tooltip, Button, Modal } from "../../../components";
import {
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
} from "../../../components/ui/card";
import { Label } from "../../../components/ui/label";
import { Textarea } from "../../../components/ui/textarea";
import { billIsPaid, getAppointmentStatus } from "./list";
import { DataTableMeta } from "./table";
import { useUser } from "../../../user-context";
import { gql, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { OvalSpinner } from "../../../components/loading";
import { useFeatureFlags } from "../../../hooks";
import { APPOINTMENT_LIST_FRAGMENT } from "../../../graphql";
import {
  ACCEPT_INSURANCE_POLICY,
  UNACCEPT_INSURANCE_POLICY,
} from "../../patients/insurances/show";
import {
  AcceptInsurancePolicy,
  AcceptInsurancePolicyVariables,
} from "../../../generated/AcceptInsurancePolicy";
import {
  UnacceptInsurancePolicy,
  UnacceptInsurancePolicyVariables,
} from "../../../generated/UnacceptInsurancePolicy";
import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  CreateEstimateWizardDialogButton,
  EditEstimateWizardDialogButton,
} from "./estimate-dialog";
import { SaltedCharges } from "./estimate-form";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@radix-ui/react-hover-card";
import {
  ShapesIcon,
  TrendingDownIcon,
  TrendingUpIcon,
  Maximize2,
  Minimize2,
} from "lucide-react";
import { ChargemasterBadge } from "../../shared/visit-bill-display-card";
import { NetworkBadge } from "../../patients/networkBadge";
import {
  GetEstimateChargeDiffDetails,
  GetEstimateChargeDiffDetailsVariables,
} from "../../../generated/GetEstimateChargeDiffDetails";
import { useAnalytics } from "../../../analytics-context";
import { Badge } from "../../../components/ui/badge";
import { EstimateType, NoteType } from "../../../generated/globalTypes";
import {
  WorkflowColumn,
  getCurrentStep,
  getVisitWorkflow,
  WorkfowStepType,
} from "./visit-workflow";
import { PreVisitEstimationWorkflowNextAction } from "./visit-workflow/pre-visit-estimation";
import { VerificationWorkflowNextAction } from "./visit-workflow/insurance-verification";
import { ChargeEntryWorkflowNextAction } from "./visit-workflow/charge-entry";
import { PaymentWorkflowNextAction } from "./visit-workflow/payment-collection";
import { PostVisitEstimationWorkflowNextAction } from "./visit-workflow/post-visit-estimation";
import { PreVisitReminderWorkflowNextAction } from "./visit-workflow/pre-visit-reminder";
import { Transition } from "@headlessui/react";

export type AppointmentsRow = {
  id: string;
  patientReadyBalance: number;
  pastPatientPendingBalance: number;
  totalCredits: number;
  date: Date;
  start: Date;
  end: Date | null;
  patientId: string;
  patientName: string;
  accountType: string | null;
  providerName: string | null;
  policies: InsurancePolicy[];
  insurancePolicyVerificationStatus: string;
  lastVerificationDate: Date | null;
  acceptedAt: string | null;
  estimate: Estimate | null;
  estimatedResponsibility: number | null;
  visitCollectionRequest: VisitCollectionRequest | null;
  lastPreVisitCollectionRequest: VisitCollectionRequest | null;
  lastPostVisitCollectionRequest: VisitCollectionRequest | null;
  memberIds: string;
  payers: string;
  appointment: Appointment;
  account: Account;
  status: "Past" | "Current" | "Upcoming";
  showAllPolicies: boolean;
  providerEligibilityEnabled: boolean;
  chargeStatus: boolean;
  appointmentInNetwork: boolean | null;
  appointmentLabels: string[];
  patientLabels: PatientLabel[];
  currentStep: WorkfowStepType | "Completed";
  otherPatientAppointments: Appointment[];
  notes: Note[];
  patientNotes: Note[];
  appointmentNotes: Note[];
  nextAppointment: Appointment | null;
};

const PatientCell: React.FC<{
  patientId: string;
  patientName: string;
  account: Account;
  patientLabels: { id: string; name: string }[];
}> = ({ patientId, patientName, account, patientLabels }) => {
  return (
    <div className="flex flex-col">
      <Link
        to={`/patients/${patientId}`}
        className="text-sm text-gray-900 hover:text-gray-600"
      >
        {patientName}
      </Link>
      <div className="flex items-center gap-1 text-xs relative">
        <div className="flex items-center gap-1 group">
          {account.accountType?.name && (
            <div className="flex items-center gap-1 whitespace-nowrap">
              <BriefcaseIcon className="h-4 flex-shrink-0" />
              <span className="truncate">{account.accountType.name}</span>
            </div>
          )}
          {patientLabels.length > 0 && (
            <div className="flex items-center gap-1">
              <Badge
                variant="outline"
                className="px-1 py-[1px] font-normal whitespace-nowrap flex-shrink-0"
              >
                {patientLabels[0].name}
              </Badge>
              {patientLabels.length > 1 && (
                <span className="text-gray-400">
                  +{patientLabels.length - 1}
                </span>
              )}
            </div>
          )}
          {(account.accountType?.name || patientLabels.length > 0) && (
            <div className="absolute left-0 top-0 hidden group-hover:flex items-center gap-1 bg-gray-100 rounded transition-all duration-700 ease-in-out z-10">
              {account.accountType?.name && (
                <div className="flex items-center gap-1 whitespace-nowrap">
                  <BriefcaseIcon className="h-4 flex-shrink-0" />
                  <span>{account.accountType.name}</span>
                </div>
              )}
              {patientLabels.map((label) => (
                <Badge
                  key={label.id}
                  variant="outline"
                  className="px-1 py-[1px] font-normal whitespace-nowrap bg-white"
                >
                  {label.name}
                </Badge>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const ProviderCell: React.FC<{
  providerName: string | null;
  appointmentLabels: { id: string; name: string }[];
}> = ({ providerName, appointmentLabels }) => {
  return (
    <div className="flex flex-col">
      {providerName}
      <div className="flex items-center gap-1 text-xs relative">
        <div className="flex items-center gap-1 group">
          {appointmentLabels.length > 0 && (
            <div className="flex items-center gap-1">
              <Badge
                variant="outline"
                className="px-1 py-[1px] font-normal whitespace-nowrap flex-shrink-0"
              >
                {appointmentLabels[0].name}
              </Badge>
              {appointmentLabels.length > 1 && (
                <span className="text-gray-400">
                  +{appointmentLabels.length - 1}
                </span>
              )}
            </div>
          )}
          {appointmentLabels.length > 0 && (
            <div className="absolute left-0 top-0 hidden group-hover:flex items-center gap-1 bg-gray-100 rounded transition-all duration-700 ease-in-out z-10">
              {appointmentLabels.map((label) => (
                <Badge
                  key={label.id}
                  variant="outline"
                  className="px-1 py-[1px] font-normal whitespace-nowrap bg-white"
                >
                  {label.name}
                </Badge>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const VisitWorkflowCell: React.FC<{
  row: AppointmentsRow;
}> = ({ row }) => {
  const flags = useFeatureFlags();
  const workflow = getVisitWorkflow(row, flags);
  return (
    <div className="flex px-1">
      <WorkflowColumn workflow={workflow} row={row} />
    </div>
  );
};

const NextActionCell: React.FC<{
  row: AppointmentsRow;
}> = ({ row }) => {
  const flags = useFeatureFlags();
  const workflow = getVisitWorkflow(row, flags);

  const currentStep = getCurrentStep(workflow);

  switch (currentStep?.type) {
    case "insuranceVerification":
      return (
        <VerificationWorkflowNextAction
          row={row}
          status={workflow.insuranceVerification}
        />
      );
    case "preVisitEstimate":
      return (
        <PreVisitEstimationWorkflowNextAction
          row={row}
          status={workflow.preVisitEstimate}
        />
      );
    case "preVisitReminder":
      return (
        <PreVisitReminderWorkflowNextAction
          row={row}
          status={workflow.preVisitReminder}
        />
      );
    case "chargeEntry":
      return (
        <ChargeEntryWorkflowNextAction
          row={row}
          status={workflow.chargeEntry}
        />
      );
    case "postVisitEstimate":
      return (
        <PostVisitEstimationWorkflowNextAction
          row={row}
          status={workflow.postVisitEstimate}
        />
      );
    case "payment":
      return <PaymentWorkflowNextAction row={row} status={workflow.payment} />;
  }

  // Completed state!
  return <div className="italic text-gray-500">No action required</div>;
};

const REQUEST_APPOINTMENT_ELIGIBILITY = gql`
  mutation NewRequestAppointmentEligibility(
    $appointmentId: String!
    $insurancePolicyId: String!
  ) {
    requestAppointmentEligibility(
      appointmentId: $appointmentId
      insurancePolicyId: $insurancePolicyId
    ) {
      eligibilityRequest {
        id
        createdAt
        status
        appointment {
          id
          nextScheduledVerificationDate
          accountCoverages {
            id
            insurancePolicy {
              id
              deletedAt
              priority
              acceptedAt
              memberId
              active
              effectiveDate
              renewalDate
              terminationDate
              insurancePolicyVerificationStatus
              appointmentInNetwork
              payer {
                id
                name
                eligibilityEnabled
              }
              acceptedBy {
                id
                firstName
                lastName
              }
              mostRecentEligibilityRequest {
                id
                createdAt
                automated
                status
                eligible
                allRequestsErrored
                requestedBy {
                  id
                  firstName
                  lastName
                }
                reverificationStatus {
                  needsReverification
                  reason
                  reasonCode
                }
                deduplicatedErrors {
                  id
                  field
                  description
                  followupAction
                }
              }
              coverageBenefits {
                id
                providerServiceConfiguration {
                  id
                  name
                  serviceType
                }
                networkStatus
                coverageLevel
                copay
                coinsurance
                remainingDeductible
                remainingOutOfPocket
                remainingVisits
                maxVisits
                authRequired
              }
            }
          }
        }
      }
    }
  }
`;

export const InsuranceVerificationCellRow: React.FC<
  React.PropsWithChildren<{
    appointment: Appointment;
    isPastDay: boolean;
    referenceDate: Date;
    insurancePolicy: InsurancePolicy;
    eligibilityRequest: EligibilityRequest | null;
  }>
> = ({
  appointment,
  isPastDay,
  referenceDate,
  insurancePolicy,
  eligibilityRequest,
}) => {
  const user = useUser()!;
  const flags = useFeatureFlags();
  const [requestAppointmentEligibility, requestAppointmentEligibilityResult] =
    useMutation(REQUEST_APPOINTMENT_ELIGIBILITY, {
      onCompleted: (data) => {
        toast.success("Eligibility request successful");
      },
      onError: (error) => {
        toast.error("Failed to check eligibility");
      },
    });

  const onSubmit = async (insurancePolicyId: string) => {
    await requestAppointmentEligibility({
      variables: {
        appointmentId: appointment.id,
        insurancePolicyId,
        // The date to check reverification status on
        referenceDate,
      },
    });
  };

  const eligibilityLoading = requestAppointmentEligibilityResult.loading;

  const provider = appointment.provider;
  // If automatedEligibilityVerificationEnabled feature flag is enabled:
  // If there is a nextScheduledVerificationDate and there is no eligibility request or
  // the eligibility request is eligible and needs reverification, show the indicator
  const showScheduledVerificationIndicator =
    flags.automatedEligibilityVerificationEnabled &&
    appointment.nextScheduledVerificationDate &&
    (!eligibilityRequest ||
      (eligibilityRequest.eligible &&
        eligibilityRequest.reverificationStatus.needsReverification));

  // If the insurancePolicy was termed at the time of the appointment
  const termedAtAppointmentTime =
    !insurancePolicy.active &&
    appointment.start &&
    insurancePolicy.terminationDate &&
    isBefore(
      parseISO(insurancePolicy.terminationDate),
      parseISO(appointment.start)
    );

  const defaultProvider = user.activeLocation.defaultEligibilityProvider;

  return (
    <div
      className="flex gap-1"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {eligibilityRequest ? (
        <div className="flex justify-center items-center gap-1">
          <EligibilityStatusBadge
            eligibilityRequest={eligibilityRequest}
            patientId={appointment.account.patient.id}
            insurancePolicyId={insurancePolicy.id}
          />
          {showScheduledVerificationIndicator && (
            <Tooltip
              content={
                <>
                  Reverification scheduled to run automatically on{" "}
                  {formatDateMMDDYYYY(
                    appointment.nextScheduledVerificationDate
                  )}
                  .
                </>
              }
              trigger={
                <div className="flex items-center rounded-full p-1">
                  <CogIcon className="w-4 text-gray-500" />
                </div>
              }
            />
          )}
          {/* Don't allow re-verification if appointment is in the past */}
          {!isPastDay && (
            <Tooltip
              content={<>Run eligibility verification again now.</>}
              trigger={
                <button
                  className="flex items-center rounded-full p-1 hover:bg-gray-100 hover:text-gray-600"
                  onClick={() => {
                    onSubmit(insurancePolicy.id);
                  }}
                  disabled={eligibilityLoading}
                >
                  <Spinner
                    className="h-4 w-4 text-gray-500"
                    spinning={eligibilityLoading}
                  />
                </button>
              }
            />
          )}
          {flags.showAdditionalPlans &&
            insurancePolicy.additionalPlans.length > 0 && (
              <AdditionalPlanAcknowledgements
                additionalPlans={insurancePolicy.additionalPlans}
                patientId={appointment.account.patient.id}
              />
            )}
        </div>
      ) : provider?.eligibilityEnabled || defaultProvider ? (
        termedAtAppointmentTime ? (
          <>
            <TermedInsurancePolicyBadge
              insurancePolicy={insurancePolicy}
              patientId={appointment.account.patient.id}
            />
            {/* Don't allow re-verification if appointment is in the past */}
            {!isPastDay && (
              <Tooltip
                content={<>Run eligibility verification again now.</>}
                trigger={
                  <button
                    className="flex items-center rounded-full p-1 hover:bg-gray-100 hover:text-gray-600"
                    onClick={() => {
                      onSubmit(insurancePolicy.id);
                    }}
                    disabled={eligibilityLoading}
                  >
                    <Spinner
                      className="h-4 w-4 text-gray-500"
                      spinning={eligibilityLoading}
                    />
                  </button>
                }
              />
            )}
          </>
        ) : insurancePolicy.payer.eligibilityEnabled ? (
          // Don't allow verification if appointment is in the past
          isPastDay && false ? (
            <Tooltip
              trigger={
                <button
                  className="inline-flex justify-center items-center text-nowrap min-w-[8em] rounded-md border border-transparent px-2.5 py-1.5 text-xs font-medium text-gray-500 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed"
                  disabled={true}
                >
                  Verify Eligibility
                </button>
              }
              content={
                <>Cannot request eligibility for appointments in the past</>
              }
            />
          ) : (
            <>
              <button
                className="inline-flex justify-center items-center text-nowrap min-w-[8em] rounded-md border border-transparent px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed"
                onClick={() => {
                  onSubmit(insurancePolicy.id);
                }}
                disabled={eligibilityLoading}
              >
                {eligibilityLoading ? (
                  <OvalSpinner className="text-indigo-700 h-4 w-4" />
                ) : (
                  <>Verify Eligibility</>
                )}
              </button>
              {showScheduledVerificationIndicator && (
                <Tooltip
                  content={
                    <>
                      Reverification scheduled to run automatically on{" "}
                      {formatDateMMDDYYYY(
                        appointment.nextScheduledVerificationDate
                      )}
                      .
                    </>
                  }
                  trigger={
                    <div className="flex items-center rounded-full p-1">
                      <CogIcon className="w-4 text-gray-500" />
                    </div>
                  }
                />
              )}
            </>
          )
        ) : (
          <Tooltip
            trigger={
              <button
                className="inline-flex justify-center items-center text-nowrap min-w-[8em] rounded-md border border-transparent px-2.5 py-1.5 text-xs font-medium text-gray-500 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed"
                disabled={true}
              >
                Verify Eligibility
              </button>
            }
            content={<>Payer does not support electronic eligibility</>}
          />
        )
      ) : (
        <Tooltip
          trigger={
            <button
              className="inline-flex justify-center items-center text-nowrap min-w-[8em] rounded-md border border-transparent px-2.5 py-1.5 text-xs font-medium text-gray-500 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed"
              disabled={true}
            >
              Verify Eligibility
            </button>
          }
          content={<>No NPI configured for provider</>}
        />
      )}
    </div>
  );
};

const PolicyConfirmationRow: React.FC<{
  insurancePolicy: InsurancePolicy;
}> = ({ insurancePolicy }) => {
  const [checked, setChecked] = useState<boolean>(!!insurancePolicy.acceptedAt);
  const [acceptInsurancePolicy, acceptInsurancePolicyResult] = useMutation<
    AcceptInsurancePolicy,
    AcceptInsurancePolicyVariables
  >(ACCEPT_INSURANCE_POLICY);
  const [unacceptInsurancePolicy, unacceptInsurancePolicyResult] = useMutation<
    UnacceptInsurancePolicy,
    UnacceptInsurancePolicyVariables
  >(UNACCEPT_INSURANCE_POLICY);

  const onChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const newChecked = e.currentTarget.checked;
    setChecked(newChecked);
    if (newChecked) {
      acceptInsurancePolicy({
        variables: {
          insurancePolicyId: insurancePolicy.id,
        },
        onCompleted: () => {
          toast.success("Policy confirmed");
        },
        onError: () => {
          toast.error("Failed to confirm policy");
          setChecked(!newChecked);
        },
      });
    } else {
      unacceptInsurancePolicy({
        variables: {
          insurancePolicyId: insurancePolicy.id,
        },
        onCompleted: () => {
          toast.success("Policy marked as unconfirmed");
        },
        onError: () => {
          toast.error("Failed to mark policy as unconfirmed");
          setChecked(!newChecked);
        },
      });
    }
  };

  const acceptedDisplay = insurancePolicy.acceptedBy ? (
    <>
      by{" "}
      {[
        insurancePolicy.acceptedBy.firstName,
        insurancePolicy.acceptedBy.lastName,
      ].join(" ")}
    </>
  ) : (
    <>automatically</>
  );

  return (
    <div className="flex items-center gap-1">
      <input
        type="checkbox"
        checked={checked}
        className="h-4 w-4 mr-1 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
        onChange={onChanged}
        disabled={
          acceptInsurancePolicyResult.loading ||
          unacceptInsurancePolicyResult.loading
        }
      />
      {insurancePolicy.acceptedAt ? (
        <Tooltip
          content={<>Policy confirmed {acceptedDisplay}</>}
          trigger={
            <>
              Confirmed on{" "}
              {format(parseISO(insurancePolicy.acceptedAt), "MM/dd/yyyy")}
            </>
          }
        />
      ) : (
        <span className="italic text-gray-400">Not yet confirmed</span>
      )}
    </div>
  );
};

export const CREATE_NOTE = gql`
  mutation CreateNote($data: NoteCreateInput!) {
    createOneNote(data: $data) {
      id
      content
      updatedAt
      createdAt
      appointment {
        id
        notes {
          id
          content
          createdAt
          updatedAt
          appointmentId
          patientId
          type
        }
      }
      patient {
        id
        notes {
          id
          content
          createdAt
          updatedAt
          appointmentId
          patientId
          type
        }
      }
      type
    }
  }
`;

export const UPDATE_NOTE = gql`
  mutation UpdateNote($id: String!, $data: NoteUpdateInput!) {
    updateOneNote(where: { id: $id }, data: $data) {
      id
      content
      updatedAt
      createdAt
      appointment {
        id
        notes {
          id
          content
          createdAt
          updatedAt
          appointmentId
          patientId
          type
        }
      }
      patient {
        id
        notes {
          id
          content
          createdAt
          updatedAt
          appointmentId
          patientId
          type
        }
      }
      type
    }
  }
`;

export const NotesButton: React.FC<{
  notes: Note[];
  patientId: string;
  appointmentId: string;
  isExpanded: boolean;
}> = React.memo(({ notes, patientId, appointmentId, isExpanded }) => {
  const [open, setOpen] = useState(false);

  const existingPatientNote = useMemo(() => {
    return notes
      .filter((n) => n.type === "Patient")
      .sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
      .at(0);
  }, [notes]);

  const existingAppointmentNote = useMemo(() => {
    return notes
      .filter(
        (n) => n.type === "Appointment" && n.appointmentId === appointmentId
      )
      .sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
      .at(0);
  }, [notes, appointmentId]);

  const [patientNotes, setPatientNotes] = useState(
    existingPatientNote?.content || ""
  );
  const [appointmentNotes, setAppointmentNotes] = useState(
    existingAppointmentNote?.content || ""
  );

  useEffect(() => {
    setPatientNotes(existingPatientNote?.content || "");
    setAppointmentNotes(existingAppointmentNote?.content || "");
  }, [existingPatientNote, existingAppointmentNote]);

  const [createNote, createNoteResult] = useMutation<
    CreateNote,
    CreateNoteVariables
  >(CREATE_NOTE);
  const [updateNote, updateNoteResult] = useMutation<
    UpdateNote,
    UpdateNoteVariables
  >(UPDATE_NOTE);
  const MAX_CHARS = 500;

  const isActive =
    (existingAppointmentNote?.content?.length || 0) > 0 ||
    (existingPatientNote?.content?.length || 0) > 0;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (existingPatientNote) {
        await updateNote({
          variables: {
            id: existingPatientNote.id,
            data: {
              content: { set: patientNotes },
              updatedAt: { set: new Date() },
            },
          },
        });
      } else if (patientNotes.length > 0) {
        await createNote({
          variables: {
            data: {
              content: patientNotes,
              patient: {
                connect: {
                  id: patientId,
                },
              },
              type: NoteType.Patient,
            },
          },
        });
      }

      if (existingAppointmentNote) {
        await updateNote({
          variables: {
            id: existingAppointmentNote.id,
            data: {
              content: { set: appointmentNotes },
              updatedAt: { set: new Date() },
            },
          },
        });
      } else if (appointmentNotes.length > 0) {
        await createNote({
          variables: {
            data: {
              content: appointmentNotes,
              appointment: {
                connect: {
                  id: appointmentId,
                },
              },
              patient: {
                connect: {
                  id: patientId,
                },
              },
              type: NoteType.Appointment,
            },
          },
        });
      }

      toast.success("Notes saved");
      setOpen(false);
    } catch {
      toast.error("Failed to create notes");
    }
  };

  const anyNotes =
    !!existingPatientNote?.content || !!existingAppointmentNote?.content;

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="inline-flex items-center flex-shrink-0"
    >
      <div className="inline-flex items-center gap-2 flex-shrink-0">
        <Button
          className="mt-1 flex px-1 py-1  justify-center items-center w-full h-full hover:bg-gray-200 rounded-md border border-transparent text-xs font-medium text-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed"
          onClick={() => setOpen(true)}
        >
          <StickyNote
            className={`h-4 w-4 ${
              isActive ? "text-indigo-600 fill-indigo-200" : "text-gray-400"
            }`}
          />
        </Button>
        <div className="flex-grow overflow-hidden">
          <Transition
            show={isExpanded}
            enter="transition-all duration-500 ease-in-out delay-300"
            enterFrom="max-h-0 opacity-0"
            enterTo="max-h-40 opacity-100"
            leave="transition-all duration-500 ease-in-out"
            leaveFrom="max-h-40 opacity-100 max-w-96"
            leaveTo="max-h-0 opacity-0"
          >
            {anyNotes && (
              <div className="whitespace-pre-wrap p-1 rounded max-w-96 hover:bg-gray-100 cursor-text">
                {existingPatientNote?.content && (
                  <div>{existingPatientNote.content}</div>
                )}
                {existingPatientNote?.content &&
                  existingAppointmentNote?.content && <br />}
                {existingAppointmentNote?.content && (
                  <div>{existingAppointmentNote.content}</div>
                )}
              </div>
            )}
          </Transition>
        </div>
      </div>
      {open && (
        <Modal
          open={open}
          setOpen={setOpen}
          onClose={() => setOpen(false)}
          className="!px-0 !pb-0"
        >
          <Card className="w-full max-w-2xl mx-auto flex flex-col space-y-4">
            <CardHeader>
              <CardTitle className="text-2xl font-bold">
                Patient and Appointment Notes
              </CardTitle>
            </CardHeader>
            <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
              <CardContent className="space-y-6">
                <div className="space-y-2">
                  <div className="flex items-center gap-1">
                    <Label htmlFor="patient-notes">Notes for Patient</Label>
                    <Tooltip
                      trigger={<InformationCircleIcon className="h-4 w-4" />}
                      content={
                        <div className="max-w-md">
                          Add notes that will apply to all appointments for this
                          specific patient
                        </div>
                      }
                    />
                  </div>
                  <Textarea
                    id="patient-notes"
                    placeholder="Enter notes for the patient..."
                    value={patientNotes}
                    onChange={(e) =>
                      setPatientNotes(e.target.value.slice(0, MAX_CHARS))
                    }
                    className="min-h-[100px]"
                  />
                  <p className="text-sm text-muted-foreground text-right">
                    {patientNotes.length}/{MAX_CHARS}
                  </p>
                </div>
                <div className="space-y-2">
                  <div className="flex items-center gap-1">
                    <Label htmlFor="appointment-notes">
                      Notes for Appointment
                    </Label>
                    <Tooltip
                      trigger={<InformationCircleIcon className="h-4 w-4" />}
                      content={
                        <div className="max-w-md">
                          Add notes for this specific appointment
                        </div>
                      }
                    />
                  </div>
                  <Textarea
                    id="appointment-notes"
                    placeholder="Enter notes for the appointment..."
                    value={appointmentNotes}
                    onChange={(e) =>
                      setAppointmentNotes(e.target.value.slice(0, MAX_CHARS))
                    }
                    className="min-h-[100px]"
                  />
                  <p className="text-sm text-muted-foreground text-right">
                    {appointmentNotes.length}/{MAX_CHARS}
                  </p>
                </div>
              </CardContent>
              <CardFooter>
                <Button
                  type="submit"
                  className="w-full bg-indigo-600 text-white rounded-lg py-2 font-semibold pr-5 pl-5"
                >
                  Save Notes
                </Button>
              </CardFooter>
            </form>
          </Card>
        </Modal>
      )}
    </div>
  );
});

export const DepositPreviewContent: React.FC<{
  deposit: VisitCollectionRequest;
}> = ({ deposit }) => {
  return (
    <div className="flex flex-col justify-end gap-2 p-2 w-full text-wrap">
      {deposit.estimateType === "Deposit" ? (
        <>
          <div className="text-sm font-semibold">Pre-Visit Deposit</div>
          <dl className="space-y-6 border-gray-200 text-sm font-medium text-gray-900 lg:block">
            <div className="flex items-center justify-between gap-4">
              <dt className="text-gray-600 font-normal">Collected On</dt>
              <dd>{formatDateMMDDYYYY(deposit.createdAt)}</dd>
            </div>
          </dl>
          <dl className="space-y-6 border-gray-200 text-sm font-medium text-gray-900 lg:block">
            <div className="flex items-center justify-between gap-4">
              <dt className="text-gray-600 font-normal">Created By</dt>
              <dd>
                {[
                  deposit.createdBy?.firstName,
                  deposit.createdBy?.lastName,
                ].join(" ")}
              </dd>
            </div>
          </dl>
          <dl className="space-y-6 border-gray-200 text-sm font-medium text-gray-900 lg:block">
            <div className="flex items-center justify-between gap-4">
              <dt className="text-gray-600">Deposit Amount</dt>
              <dd>{formatUSD(deposit.amount)}</dd>
            </div>
          </dl>
          <div className="text-sm text-gray-600">
            An estimate can still be created for this visit. The deposit amount
            will be applied to the final bill.
          </div>
        </>
      ) : (
        <>
          <dl className="space-y-6 border-gray-200 text-sm font-medium text-gray-900 lg:block">
            <div className="flex items-center justify-between gap-4">
              <dt className="text-gray-600 font-normal">Estimated On</dt>
              <dd>{formatDateMMDDYYYY(deposit.createdAt)}</dd>
            </div>
          </dl>
          <dl className="space-y-6 border-gray-200 text-sm font-medium text-gray-900 lg:block">
            <div className="flex items-center justify-between gap-4">
              <dt className="text-gray-600 font-normal">Deposit Rule</dt>
              <dd>
                {deposit.depositMapping?.name ?? (
                  <span className="text-gray-500">NA</span>
                )}
              </dd>
            </div>
          </dl>
          <dl className="space-y-6 border-gray-200 text-sm font-medium text-gray-900 lg:block">
            <div className="flex items-center justify-between gap-4">
              <dt className="text-gray-600 font-normal">Created By</dt>
              <dd>
                {[
                  deposit.createdBy?.firstName,
                  deposit.createdBy?.lastName,
                ].join(" ")}
              </dd>
            </div>
          </dl>
          <dl className="space-y-6 border-gray-200 text-sm font-medium text-gray-900 lg:block">
            <div className="flex items-center justify-between gap-4">
              <dt className="text-gray-600 font-normal">Benefit Used</dt>
              <dd>
                {deposit.coverageBenefit?.providerServiceConfiguration
                  ?.name ?? <span className="text-gray-500">NA</span>}
              </dd>
            </div>
          </dl>
          <dl className="space-y-6 border-gray-200 text-sm font-medium text-gray-900 lg:block">
            <div className="flex items-center justify-between gap-4 font-bold">
              <dt className="text-gray-600">Amount to Collect</dt>
              <dd>{formatUSD(deposit.amount)}</dd>
            </div>
          </dl>
        </>
      )}
    </div>
  );
};

export const DepositPreviewCard: React.FC<{
  deposit: VisitCollectionRequest;
}> = ({ deposit }) => {
  return (
    <Card>
      <DepositPreviewContent deposit={deposit} />
    </Card>
  );
};

export const EstimatePreviewContent: React.FC<{
  estimate: Estimate;
  estimateType?: EstimateType;
}> = ({ estimate, estimateType }) => {
  const otherPatientResponsibility =
    estimate.totalPatientResponsibility -
    (estimate.copayAmount +
      estimate.coinsuranceAmount +
      estimate.deductibleAmount);
  const primaryId = estimate.estimateInsurancePolicies.at(0)?.id ?? null;
  const primaryEstimatedCharges = estimate.estimatedCharges.filter(
    (ec) => ec.estimatedInsurancePolicyId === primaryId
  );
  return (
    <div className="flex flex-col justify-end gap-2 p-2 w-full">
      {estimateType === EstimateType.PreVisit && (
        <div className="grow">Pre-Visit Estimate</div>
      )}
      {estimateType === EstimateType.PostVisit && (
        <div className="grow font-semibold">Post-Visit Estimate (Current)</div>
      )}
      {estimate.chargeTemplate && (
        <dl className="space-y-6 px-2 py-1 rounded-md text-sm bg-blue-50 text-blue-700 lg:block">
          <div className="flex items-center justify-between gap-4">
            <dd className="flex items-center gap-2">
              <Tooltip
                trigger={<ShapesIcon className="h-4 w-4 text-blue-500" />}
                content={<>Charge Template</>}
              />
              <span className="font-semibold">
                {estimate.chargeTemplate.name}
              </span>
            </dd>
          </div>
        </dl>
      )}
      {estimate.saltedBill?.appointment?.id && (
        <SaltedCharges appointmentId={estimate.saltedBill?.appointment?.id} />
      )}
      {primaryEstimatedCharges.map((ec) => (
        <dl className="space-y-6 border-gray-200 text-sm font-medium text-gray-900 lg:block">
          <div className="flex items-center justify-between gap-4">
            <dt className="text-gray-600 font-normal">
              <ChargemasterBadge
                chargemaster={ec.chargemaster}
                units={ec.units}
              />
            </dt>
            <dd>{formatUSD(ec.allowedAmount)}</dd>
          </div>
        </dl>
      ))}
      <dl className="space-y-6 pb-2 border-b border-gray-200 text-sm font-medium text-gray-900 lg:block">
        <div className="flex items-center justify-between gap-4">
          <dt className="text-gray-600 font-normal">Total Allowed Amount</dt>
          <dd>{formatUSD(estimate.allowedAmount)}</dd>
        </div>
      </dl>
      <dl className="space-y-6 border-gray-200 text-sm font-medium text-gray-900 lg:block">
        <div className="flex items-center justify-between gap-4">
          <dt className="text-gray-600 font-normal">
            Estimated Insurance Responsibility
          </dt>
          <dd>
            {formatUSD(
              -(estimate.allowedAmount - estimate.totalPatientResponsibility)
            )}
          </dd>
        </div>
      </dl>
      <dl className="space-y-6 border-t pt-2 border-gray-200 text-sm font-medium text-gray-900 lg:block">
        <div className="flex items-center justify-between gap-4">
          <dt className="text-gray-600 font-normal">Copay</dt>
          <dd>{formatUSD(estimate.copayAmount)}</dd>
        </div>
      </dl>
      <dl className="space-y-6 border-gray-200 text-sm font-medium text-gray-900 lg:block">
        <div className="flex items-center justify-between gap-4">
          <dt className="text-gray-600 font-normal">Coinsurance</dt>
          <dd>{formatUSD(estimate.coinsuranceAmount)}</dd>
        </div>
      </dl>
      <dl className="space-y-6 border-gray-200 text-sm font-medium text-gray-900 lg:block">
        <div className="flex items-center justify-between gap-4">
          <dt className="text-gray-600 font-normal">Deductible</dt>
          <dd>{formatUSD(estimate.deductibleAmount)}</dd>
        </div>
      </dl>
      {otherPatientResponsibility > 0 && (
        <dl className="space-y-6 border-gray-200 text-sm font-medium text-gray-900 lg:block">
          <div className="flex items-center justify-between gap-4">
            <dt className="text-gray-600 font-normal">Other</dt>
            <dd>{formatUSD(otherPatientResponsibility)}</dd>
          </div>
        </dl>
      )}
      <dl className="space-y-6 border-gray-200 text-sm font-medium text-gray-900 lg:block">
        <div className="flex items-center justify-between gap-4 font-bold">
          <dt className="text-gray-600">Estimated Patient Responsibility</dt>
          <dd>{formatUSD(estimate.totalPatientResponsibility)}</dd>
        </div>
      </dl>
    </div>
  );
};

export const EstimatePreviewCard: React.FC<{
  estimate: Estimate;
  estimateType?: EstimateType;
}> = ({ estimate, estimateType }) => {
  return (
    <Card>
      <EstimatePreviewContent estimate={estimate} estimateType={estimateType} />
    </Card>
  );
};

const GET_ESTIMATE_CHARGE_DIFF_DETAILS = gql`
  query GetEstimateChargeDiffDetails($estimateId: String!, $billId: String!) {
    bill(where: { id: $billId }) {
      id
      charges {
        id
        units
        allowedAmount
        chargemaster {
          id
          code
          chargemasterGroupId
        }
      }
    }
    estimate(where: { id: $estimateId }) {
      id
      estimatedCharges(orderBy: { priority: asc }) {
        id
        units
        allowedAmount
        chargemaster {
          id
          code
          chargemasterGroupId
        }
      }
    }
  }
`;

const EstimatedChargeDiffHoverCard: React.FC<{
  billId: string;
  estimateId: string;
}> = ({ billId, estimateId }) => {
  const { data, loading } = useQuery<
    GetEstimateChargeDiffDetails,
    GetEstimateChargeDiffDetailsVariables
  >(GET_ESTIMATE_CHARGE_DIFF_DETAILS, {
    variables: {
      estimateId,
      billId,
    },
  });

  if (!data || loading) return <>Loading...</>;

  // TODO
  const chargesDiffer = true;
  const estimatedCharges = data.estimate?.estimatedCharges ?? [];
  const charges = data.bill?.charges ?? [];

  // The list of charges that match an estimated charge by chargemasterGroupId
  const matchingCharges = charges.filter((charge) =>
    estimatedCharges.some(
      (ec) =>
        ec.chargemaster.chargemasterGroupId ===
        charge.chargemaster?.chargemasterGroupId
    )
  );

  // The list of charges that do not match an estimated charge by chargemasterGroupId
  const nonmatchingCharges = charges.filter((charge) =>
    estimatedCharges.every(
      (ec) =>
        ec.chargemaster.chargemasterGroupId !==
        charge.chargemaster?.chargemasterGroupId
    )
  );

  // The list of estimated charges that do not match a charge by chargemasterGroupId
  const nonmatchingEstimatedCharges = estimatedCharges.filter((ec) =>
    charges.every(
      (charge) =>
        ec.chargemaster.chargemasterGroupId !==
        charge.chargemaster?.chargemasterGroupId
    )
  );

  return (
    <Card>
      <div className="flex flex-col gap-1">
        {chargesDiffer && (
          <dl className="space-y-6 px-2 py-1 rounded-md text-sm bg-yellow-50 text-yellow-700 lg:block">
            <div className="flex items-center justify-between gap-4">
              <ExclamationCircleIcon className="h-4 w-4 text-yellow-500" />
              <dd className="flex items-center gap-2">
                Entered charges differ than those used in estimate
              </dd>
            </div>
          </dl>
        )}
        <table className="p-1 w-full">
          <thead className="divide-x">
            <th colSpan={3} className="text-center bg-slate-50">
              Charge
            </th>
            <th colSpan={3} className="text-center bg-slate-50">
              Estimated
            </th>
          </thead>
          <thead className="border-b">
            <th className="text-gray-600 font-normal">Code</th>
            <th className="text-gray-600 font-normal">Units</th>
            <th className="text-gray-600 font-normal border-r">Allowed</th>
            <th className="text-gray-600 font-normal">Code</th>
            <th className="text-gray-600 font-normal">Units</th>
            <th className="text-gray-600 font-normal">Allowed</th>
          </thead>
          <tbody>
            <>
              {matchingCharges.map((charge) => {
                const matchingEstimatedCharge = estimatedCharges.find(
                  (ec) =>
                    ec.chargemaster.chargemasterGroupId ===
                    charge.chargemaster?.chargemasterGroupId
                )!;
                return (
                  <tr key={charge.id} className="">
                    <td className="text-gray-600 font-normal text-center">
                      {charge.chargemaster?.code}
                    </td>
                    <td className="text-center">{charge.units}</td>
                    <td className="text-center border-r">
                      {formatUSD(-charge.allowedAmount)}
                    </td>
                    <td className="text-gray-600 font-normal text-center">
                      {matchingEstimatedCharge.chargemaster.code}
                    </td>
                    <td className="text-center">
                      {matchingEstimatedCharge.units}
                    </td>
                    <td className="text-center">
                      {formatUSD(matchingEstimatedCharge.allowedAmount)}
                    </td>
                  </tr>
                );
              })}
              {nonmatchingCharges.map((charge) => {
                return (
                  <tr key={charge.id} className="">
                    <td className="text-gray-600 font-normal text-center">
                      {charge.chargemaster?.code}
                    </td>
                    <td className="text-center">{charge.units}</td>
                    <td className="text-center border-r">
                      {formatUSD(-charge.allowedAmount)}
                    </td>
                    <td className="text-center">&nbsp;</td>
                    <td className="text-center">&nbsp;</td>
                    <td className="text-center">&nbsp;</td>
                  </tr>
                );
              })}
              {nonmatchingEstimatedCharges.map((charge) => {
                return (
                  <tr key={charge.id} className="">
                    <td className="text-center">&nbsp;</td>
                    <td className="text-center">&nbsp;</td>
                    <td className="text-center border-r">&nbsp;</td>
                    <td className="text-gray-600 font-normal text-center">
                      {charge.chargemaster.code}
                    </td>
                    <td className="text-center">{charge.units}</td>
                    <td className="text-center">
                      {formatUSD(charge.allowedAmount)}
                    </td>
                  </tr>
                );
              })}
            </>
          </tbody>
        </table>
      </div>
    </Card>
  );
};

const CardIcon: React.FC<{
  paymentMethodError?: string;
}> = ({ paymentMethodError }) => {
  return (
    <div className="relative">
      <CreditCardIcon className="h-5 w-5 text-indigo-400 self-end" />
      {paymentMethodError && (
        <ExclamationCircleIcon className="h-3 w-3 z-10 text-red-500 absolute -top-1 -right-1" />
      )}
    </div>
  );
};

export const columns: ColumnDef<AppointmentsRow>[] = [
  {
    id: "Scheduled At",
    accessorKey: "date",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Scheduled At" />
    ),
    cell: ({ row }) => {
      return (
        <>
          {format(row.original.start, "h:mm aa")}
          {isDefined(row.original.end) &&
            `- ${format(row.original.end, "h:mm aa")}`}
        </>
      );
    },
    filterFn: (row, id, value) => {
      const status = getAppointmentStatus(row.original.appointment);
      return value.includes(status);
    },
  },
  {
    id: "Patient",
    accessorKey: "patientName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Patient" />
    ),
    cell: ({ row }) => {
      return (
        <PatientCell
          patientId={row.original.patientId}
          patientName={row.original.patientName}
          account={row.original.account}
          patientLabels={row.original.patientLabels}
        />
      );
    },
  },
  {
    id: "accountType",
    accessorKey: "accountType",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Account" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.original.accountType);
    },
  },
  {
    id: "Provider",
    accessorKey: "providerName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Provider" />
    ),
    cell: ({ row }) => {
      const provider = row.original.appointment.provider;
      if (!provider) return null;
      const appointmentLabels =
        row.original.appointment.appointmentLabelings.map(
          ({ appointmentLabel }) => appointmentLabel
        );
      return (
        <ProviderCell
          providerName={provider.displayName}
          appointmentLabels={appointmentLabels}
        />
      );
    },
    filterFn: (row, id, value) => {
      return (
        value.includes(row.original.providerName) ||
        row.original.appointmentLabels.some((l) => value.includes(l.name))
      );
    },
  },
  {
    id: "Next Appointment",
    accessorKey: "nextAppointment",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Next Appointment" />
    ),
    cell: ({ row }) => {
      const nextAppointment = row.original.nextAppointment;
      if (nextAppointment && isDefined(nextAppointment.start)) {
        return (
          <div>
            {format(parseISO(nextAppointment.start), "EEE, MMM d, h:mm aa")}
          </div>
        );
      } else {
        return (
          <div className="text-gray-400 text-center w-full pr-6">None</div>
        );
      }
    },
  },
  {
    id: "appointmentLabels",
    accessorKey: "appointmentLabels",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Appt Labels" />
    ),
    filterFn: (row, id, value) => {
      return row.original.appointmentLabels.some((label) =>
        value.includes(label)
      );
    },
  },
  {
    id: "Payer",
    accessorKey: "payers",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Payer" />
    ),
    cell: ({ row, column, table }) => {
      const policies = row.original.showAllPolicies
        ? row.original.policies
        : row.original.policies.filter((p) => p.active);
      const hasInactive = row.original.policies.some((p) => !p.active);
      return (
        <>
          <div className="flex flex-col gap-4">
            {policies.map((policy) => (
              <div className="flex items-center gap-1">
                {policy.priority && (
                  <InsurancePolicyPriorityBadge priority={policy.priority} />
                )}
                <div className="truncate max-w-[12em]">{policy.payer.name}</div>
              </div>
            ))}
          </div>
          {!row.original.showAllPolicies && hasInactive && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                (table.options.meta as DataTableMeta)?.updateData(
                  row.index,
                  "showAllPolicies",
                  true
                );
                // row.original.showAllPolicies = true;
              }}
              className="underline text-gray-700 hover:text-gray-500"
            >
              Show {row.original.policies.filter((p) => !p.active).length} more
            </div>
          )}
        </>
      );
    },
    filterFn: (row, id, value) => {
      return row.original.policies.some((policy) =>
        value.includes(policy.payer.name)
      );
    },
  },
  {
    id: "Member ID",
    accessorKey: "memberIds",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Member ID" />
    ),
    cell: ({ row }) => {
      const policies = row.original.showAllPolicies
        ? row.original.policies
        : row.original.policies.filter((p) => p.active);
      const hasInactive = row.original.policies.some((p) => !p.active);
      return (
        <>
          <div className="flex flex-col gap-4">
            {policies.map((policy) => (
              <Link
                to={`../patients/${row.original.patientId}/insurances/${policy.id}`}
                className="hover:text-gray-600"
              >
                {policy.memberId ? (
                  <div className="flex items-center gap-1">
                    <p>{policy.memberId}</p>
                    <Link
                      to={`../patients/${row.original.patientId}/insurances/${policy.id}`}
                      className="hover:text-gray-600"
                      target="_blank"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {" "}
                      <ExternalLinkIcon className="h-4 w-4" />
                    </Link>
                  </div>
                ) : (
                  <span className="italic text-gray-400">No Member ID</span>
                )}
              </Link>
            ))}
          </div>
          {!row.original.showAllPolicies && hasInactive && (
            <div className="text-xs">&nbsp;</div>
          )}
        </>
      );
    },
    filterFn: (row, id, value) => {
      return row.original.policies.some((policy) =>
        value.includes(policy.memberId)
      );
    },
  },
  {
    id: "Verification Status",
    accessorKey: "insurancePolicyVerificationStatus",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Verification Status" />
    ),
    cell: ({ row }) => {
      const policies = row.original.showAllPolicies
        ? row.original.policies
        : row.original.policies.filter((p) => p.active);
      const hasInactive = row.original.policies.some((p) => !p.active);
      return (
        <>
          <div className="flex flex-col gap-4">
            {policies.map((policy) => (
              <>
                <InsuranceVerificationCellRow
                  insurancePolicy={policy}
                  eligibilityRequest={policy.mostRecentEligibilityRequest}
                  appointment={row.original.appointment}
                  isPastDay={false}
                  referenceDate={new Date()}
                />
              </>
            ))}
          </div>
          {!row.original.showAllPolicies && hasInactive && (
            <div className="text-xs">&nbsp;</div>
          )}
        </>
      );
    },
    filterFn: (row, id, value) => {
      // If not verifiable, don't show when filtering by verification status
      if (
        !row.original.providerEligibilityEnabled ||
        row.original.policies.every((p) => !p.payer.eligibilityEnabled)
      ) {
        return false;
      }
      return row.original.policies.some((policy) =>
        value.includes(policy.insurancePolicyVerificationStatus)
      );
    },
  },
  {
    id: "In Network",
    accessorKey: "appointmentInNetwork",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="In Network" />
    ),
    cell: ({ row }) => {
      const policies = row.original.showAllPolicies
        ? row.original.policies
        : row.original.policies.filter((p) => p.active);
      const hasInactive = row.original.policies.some((p) => !p.active);
      return (
        <>
          <div className="flex flex-col gap-4">
            {policies.map((policy) => (
              <div>
                <NetworkBadge
                  orgPayerNetworkStatus={policy.appointmentInNetwork}
                />
              </div>
            ))}
          </div>
          {!row.original.showAllPolicies && hasInactive && (
            <div className="text-xs">&nbsp;</div>
          )}
        </>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.original.appointmentInNetwork);
    },
  },
  {
    id: "Policy Confirmed",
    accessorKey: "acceptedAt",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Policy Confirmed" />
    ),
    cell: ({ row }) => {
      const policies = row.original.showAllPolicies
        ? row.original.policies
        : row.original.policies.filter((p) => p.active);
      return (
        <>
          <div className="flex flex-col gap-4">
            {policies.map((policy) => (
              <PolicyConfirmationRow
                // Force rerender when policy confirmation changes
                key={policy.id + policy.acceptedAt}
                insurancePolicy={policy}
              />
            ))}
          </div>
          {!row.original.showAllPolicies && (
            <div className="text-xs">&nbsp;</div>
          )}
        </>
      );
    },
    filterFn: (row, id, value) => {
      return row.original.policies.some((policy) => {
        const confirmed = !!policy.acceptedAt;
        if (value.includes("Confirmed") && confirmed) return true;
        if (value.includes("Not yet confirmed") && !confirmed) return true;
        return false;
      });
    },
  },
  {
    id: "chargeStatus",
    accessorKey: "chargeStatus",
    header: ({ column }) => (
      <div className="flex">
        <DataTableColumnHeader column={column} title="Charge Status" />
      </div>
    ),
    cell: ({ row }) => {
      const collectionRequest = row.original.visitCollectionRequest;
      const estimate = collectionRequest?.estimate;
      const chargesDiffer = !!estimate?.chargesDifferFromBill;
      const policiesChanged = !!estimate?.policiesChanged;
      const bill = row.original.appointment.bill.at(0);
      const charges = bill?.charges ?? [];

      return row.original.chargeStatus ? (
        <HoverCard>
          <HoverCard>
            <HoverCardTrigger>
              <div className="flex items-center gap-1 text-sm text-gray-700">
                {chargesDiffer || policiesChanged ? (
                  <ExclamationCircleIcon className="w-4 h-4 text-yellow-500" />
                ) : (
                  <CheckCircleIcon className="w-4 h-4 text-green-500" />
                )}
                Charges Entered
              </div>
            </HoverCardTrigger>
            <HoverCardContent side="top" className="z-50">
              {chargesDiffer ? (
                <EstimatedChargeDiffHoverCard
                  estimateId={estimate.id}
                  billId={bill!.id}
                />
              ) : (
                <Card>
                  <div className="flex flex-col gap-1">
                    {policiesChanged && (
                      <dl className="space-y-6 px-2 py-1 rounded-md text-sm bg-yellow-50 text-yellow-700 lg:block">
                        <div className="flex items-center justify-between gap-4">
                          <ExclamationCircleIcon className="h-4 w-4 text-yellow-500" />
                          <dd className="flex items-center gap-2">
                            New policies for account since estimate
                          </dd>
                        </div>
                      </dl>
                    )}
                    <dl className="flex flex-col gap-2 p-1 w-full">
                      <div className="flex items-center justify-between gap-4 border-b">
                        <dt className="text-gray-600 font-normal">Charge</dt>
                        <dd>Units</dd>
                      </div>
                      {charges.map((charge) => (
                        <div className="flex items-center justify-between gap-4">
                          <dt className="text-gray-600 font-normal">
                            {charge.customCode}
                          </dt>
                          <dd>{charge.units}</dd>
                        </div>
                      ))}
                    </dl>
                  </div>
                </Card>
              )}
            </HoverCardContent>
          </HoverCard>
        </HoverCard>
      ) : (
        <div className="flex items-center gap-1 text-sm text-gray-700">
          <ClockIcon className="w-4 h-4 text-gray-300" />
          Awaiting Charges
        </div>
      );
    },
  },
  {
    id: "Workflow",
    accessorKey: "currentStep",
    header: ({ column }) => (
      <div className="flex">
        <DataTableColumnHeader column={column} title="Workflow" />
      </div>
    ),
    cell: ({ row }) => {
      return <VisitWorkflowCell row={row.original} />;
    },
    sortingFn: (rowA, rowB, columnId) => {
      const stepOrder: (WorkfowStepType | null)[] = [
        "insuranceVerification",
        "preVisitEstimate",
        "preVisitReminder",
        "chargeEntry",
        "postVisitEstimate",
        "payment",
        // No current step considered complete
        null,
      ];

      const stepA = rowA.getValue(columnId) as string;
      const stepB = rowB.getValue(columnId) as string;

      return stepOrder.indexOf(stepA) - stepOrder.indexOf(stepB);
    },
  },
  {
    id: "Next Action",
    header: ({ column }) => (
      <div className="flex">
        <DataTableColumnHeader column={column} title="Next Action" />
      </div>
    ),
    cell: ({ row }) => {
      return (
        <div
          onClick={(e) => {
            // Prevent opening the appointment slideout
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <NextActionCell row={row.original} />
        </div>
      );
    },
  },
  {
    id: "Estimate",
    accessorKey: "estimatedResponsibility",
    header: ({ column }) => (
      <div className="flex justify-end items-center mr-8">
        <DataTableColumnHeader column={column} title="Estimate" />
      </div>
    ),
    cell: ({ row }) => {
      const user = useUser();
      const analytics = useAnalytics();
      const collectionRequest = row.original.visitCollectionRequest;
      const lastPostVisitCollectionRequest =
        row.original.lastPostVisitCollectionRequest;
      const lastPreVisitCollectionRequest =
        row.original.lastPreVisitCollectionRequest;
      const estimateUpdated =
        lastPreVisitCollectionRequest &&
        lastPostVisitCollectionRequest &&
        lastPreVisitCollectionRequest.amount !==
          lastPostVisitCollectionRequest.amount;
      const increased =
        estimateUpdated &&
        lastPostVisitCollectionRequest.amount! >
          lastPreVisitCollectionRequest!.amount!;
      const decreased =
        estimateUpdated &&
        lastPostVisitCollectionRequest.amount! <
          lastPreVisitCollectionRequest!.amount!;

      const [viewPastEstimate, setViewPastEstimate] = useState(false);

      const estimate = collectionRequest?.estimate;
      if (isDefined(estimate)) {
        const estimatedResponsibility = estimate.totalPatientResponsibility;
        return (
          <div className="flex justify-end items-center gap-2 mr-8">
            <HoverCard
              onOpenChange={(open) => {
                if (open) {
                  analytics?.track("Estimate Hovercard Viewed", {
                    organizationId: user?.organization?.id,
                    organizationName: user?.organization?.name,
                    locationId: user?.activeLocation?.id,
                    locationName: user?.activeLocation?.name,
                  });
                }
                setViewPastEstimate(false);
              }}
            >
              <HoverCardTrigger>
                {formatUSD(estimatedResponsibility)}
              </HoverCardTrigger>
              <HoverCardContent
                side="top"
                sideOffset={5}
                className="z-50"
                onClick={(e) => {
                  // Prevent opening the appointment slideout
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <div className="flex">
                  {viewPastEstimate && lastPreVisitCollectionRequest && (
                    <Card>
                      <div className="flex flex-col justify-between">
                        <div className="font-semibold px-2">
                          Previous Estimate
                        </div>
                        {lastPreVisitCollectionRequest.estimate ? (
                          <EstimatePreviewContent
                            estimate={lastPreVisitCollectionRequest.estimate}
                          />
                        ) : (
                          <DepositPreviewContent
                            deposit={lastPreVisitCollectionRequest}
                          />
                        )}
                      </div>
                    </Card>
                  )}

                  <Card>
                    <div className="flex flex-col justify-between">
                      {viewPastEstimate && (
                        <div className="font-semibold px-2">
                          Current Estimate
                        </div>
                      )}
                      <div>
                        {estimateUpdated && (
                          <dl className="space-y-6 px-2 py-1 rounded-md text-sm bg-blue-50 text-blue-700 lg:block">
                            <div className="flex items-center justify-between gap-4">
                              <dd className="flex items-center gap-2">
                                {increased && (
                                  <TrendingUpIcon className="h-4 w-4 text-blue-500" />
                                )}
                                {decreased && (
                                  <TrendingDownIcon className="h-4 w-4 text-blue-500" />
                                )}
                                <span className="font-semibold">
                                  Estimate updated from{" "}
                                  {formatUSD(
                                    lastPreVisitCollectionRequest.amount
                                  )}{" "}
                                  to{" "}
                                  {formatUSD(
                                    lastPostVisitCollectionRequest.amount
                                  )}
                                </span>
                                <button
                                  onClick={() => {
                                    setViewPastEstimate(!viewPastEstimate);
                                  }}
                                >
                                  {viewPastEstimate ? "Hide" : "View"}
                                </button>
                              </dd>
                            </div>
                          </dl>
                        )}
                        <EstimatePreviewContent estimate={estimate} />
                      </div>
                    </div>
                  </Card>
                </div>
              </HoverCardContent>
            </HoverCard>

            <EditEstimateWizardDialogButton
              appointmentId={row.original.appointment.id}
            />
          </div>
        );
      }
      if (isDefined(collectionRequest)) {
        return (
          <div className="flex justify-end items-center gap-2 mr-8">
            <HoverCard
              onOpenChange={(open) => {
                if (open) {
                  analytics?.track("Estimate Hovercard Viewed", {
                    organizationId: user?.organization?.id,
                    organizationName: user?.organization?.name,
                    locationId: user?.activeLocation?.id,
                    locationName: user?.activeLocation?.name,
                  });
                }
              }}
            >
              <HoverCardTrigger>
                {formatUSD(collectionRequest.amount)}
              </HoverCardTrigger>
              <HoverCardContent
                side="top"
                sideOffset={5}
                className="z-50 max-w-md"
                onClick={(e) => {
                  // Prevent opening the appointment slideout
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <div className="flex">
                  {viewPastEstimate && lastPreVisitCollectionRequest && (
                    <Card>
                      <div className="flex flex-col justify-between">
                        <div className="font-semibold px-2">
                          Previous Estimate
                        </div>
                        {lastPreVisitCollectionRequest.estimate ? (
                          <EstimatePreviewContent
                            estimate={lastPreVisitCollectionRequest.estimate}
                          />
                        ) : (
                          <DepositPreviewContent
                            deposit={lastPreVisitCollectionRequest}
                          />
                        )}
                      </div>
                    </Card>
                  )}
                  <Card>
                    <div className="flex flex-col justify-between">
                      {viewPastEstimate && (
                        <div className="font-semibold px-2">
                          Current Estimate
                        </div>
                      )}
                      <div>
                        {estimateUpdated && (
                          <dl className="space-y-6 px-2 py-1 rounded-md text-sm bg-blue-50 text-blue-700 lg:block">
                            <div className="flex items-center justify-between gap-4">
                              <dd className="flex items-center gap-2">
                                {increased && (
                                  <TrendingUpIcon className="h-4 w-4 text-blue-500" />
                                )}
                                {decreased && (
                                  <TrendingDownIcon className="h-4 w-4 text-blue-500" />
                                )}
                                <span className="font-semibold">
                                  Estimate updated from{" "}
                                  {formatUSD(
                                    lastPreVisitCollectionRequest.amount
                                  )}{" "}
                                  to{" "}
                                  {formatUSD(
                                    lastPostVisitCollectionRequest.amount
                                  )}
                                </span>
                                <button
                                  onClick={() => {
                                    setViewPastEstimate(!viewPastEstimate);
                                  }}
                                >
                                  {viewPastEstimate ? "Hide" : "View"}
                                </button>
                              </dd>
                            </div>
                          </dl>
                        )}
                        <DepositPreviewContent deposit={collectionRequest} />
                      </div>
                    </div>
                  </Card>
                </div>
              </HoverCardContent>
            </HoverCard>

            <EditEstimateWizardDialogButton
              appointmentId={row.original.appointment.id}
            />
          </div>
        );
      }
      return (
        <div className="flex justify-end items-center gap-2 mr-8">
          <CreateEstimateWizardDialogButton
            appointmentId={row.original.appointment.id}
          />
        </div>
      );
    },
    filterFn: (row, id, value) => {
      const estimated = !!row.original.appointment.bill.at(0)?.activeEstimate;
      if (value.includes("Estimated") && estimated) return true;
      if (value.includes("Unestimated") && !estimated) return true;
      return false;
    },
  },
  {
    id: "Notes",
    header: ({ column, table }) => {
      const meta = table.options.meta as DataTableMeta;
      const notesExpanded = meta.notesExpanded;
      return (
        <div className="flex justify-left items-center mr-6 max-w-12">
          <DataTableColumnHeader column={column} title="Notes" />
          <button
            className="h-8 data-[state=open]:bg-accent py-1 px-2 rounded-md relative hover:bg-gray-200"
            onClick={() => meta.setNotesExpanded(!notesExpanded)}
          >
            <div className="relative w-3.5 h-3.5">
              <Maximize2
                className={`w-3.5 h-3.5 absolute top-0 left-0 transition-all duration-300 ease-in-out ${
                  notesExpanded
                    ? "opacity-0 rotate-90 scale-0"
                    : "opacity-100 rotate-0 scale-100"
                }`}
              />
              <Minimize2
                className={`w-3.5 h-3.5 absolute top-0 left-0 transition-all duration-300 ease-in-out ${
                  notesExpanded
                    ? "opacity-100 rotate-0 scale-100"
                    : "opacity-0 rotate-90 scale-0"
                }`}
              />
            </div>
          </button>
        </div>
      );
    },
    cell: ({ row, table }) => {
      const meta = table.options.meta as DataTableMeta;
      const notesExpanded = meta.notesExpanded;
      return (
        <NotesButton
          key={row.original.id}
          patientId={row.original.appointment.account.patient.id}
          appointmentId={row.original.appointment.id}
          notes={row.original.notes}
          isExpanded={notesExpanded}
        />
      );
    },
  },
  {
    id: "Actions",
    accessorKey: "patientReadyBalance",
    header: ({ column }) => <DataTableColumnHeader column={column} title="" />,
    cell: ({ row }) => {
      // const bill = appointment.bill.at(0);
      const appointment = row.original.appointment;
      const patient = appointment.account.patient;
      const bill = appointment.bill.at(0);
      const externalPaymentMethod = patient.accounts
        .flatMap((account) => account.externalPaymentMethods)
        .at(0);

      const paymentMethod = patient.paymentMethods.at(0);
      const paymentMethodError =
        paymentMethod?.paymentIntents.at(0)?.lastPaymentError;
      const both = paymentMethod && externalPaymentMethod;

      const patientReadyBalance = -(row.original.patientReadyBalance ?? 0);
      const collectableBalance =
        row.original.pastPatientPendingBalance +
        patientReadyBalance -
        row.original.totalCredits;

      const paymentsToday =
        row.original.appointment.sameDayAccountPaymentsTotal;

      return (
        <div className="flex justify-center gap-2 text-sm text-gray-700">
          <div className="w-6 flex items-center justify-center">
            {both ? (
              <Tooltip
                trigger={
                  <div className="relative ml-[-8px] mt-[-10px]">
                    <CreditCardIcon className="h-5 w-5 text-gray-300 self-end absolute left-0 z-2" />
                    <div className="absolute top-[4px] left-[4px]">
                      <CardIcon paymentMethodError={paymentMethodError} />
                    </div>
                  </div>
                }
                content={
                  <div className="flex flex-col gap-1">
                    <div>
                      Patient has a card on file in Pledge and{" "}
                      {
                        externalPaymentMethod?.integrationLinks?.at(0)
                          ?.integration?.name
                      }
                    </div>
                    {paymentMethodError && (
                      <div>
                        Last charge failed with error: {paymentMethodError}
                      </div>
                    )}
                  </div>
                }
              />
            ) : paymentMethod ? (
              <Tooltip
                trigger={
                  // <CreditCardIcon className="h-5 w-5 text-indigo-400 self-end" />
                  <CardIcon paymentMethodError={paymentMethodError} />
                }
                content={
                  <div className="flex flex-col gap-1">
                    <div>Patient has a card on file in Pledge</div>
                    {paymentMethodError && (
                      <div>
                        Last charge failed with error: {paymentMethodError}
                      </div>
                    )}
                  </div>
                }
              />
            ) : externalPaymentMethod ? (
              <Tooltip
                trigger={
                  <CreditCardIcon className="h-5 w-5 text-gray-500 self-end" />
                }
                content={
                  <>
                    Patient has a card on file in{" "}
                    {
                      externalPaymentMethod.integrationLinks.at(0)?.integration
                        ?.name
                    }
                  </>
                }
              />
            ) : (
              <Tooltip
                trigger={
                  <div className="flex items-center rounded-full p-2 hover:bg-gray-100">
                    <MinusIcon className="w-2 text-gray-500" />
                  </div>
                }
                content={<>No card on file</>}
              />
            )}
          </div>
          <div className="w-6 flex items-center justify-center">
            {collectableBalance > 0 ? (
              <Tooltip
                trigger={<CashIcon className="h-5 w-5 text-red-700 self-end" />}
                content={
                  <>
                    Patient has a past balance of{" "}
                    {formatUSD(collectableBalance)}
                  </>
                }
              />
            ) : collectableBalance < 0 ? (
              <Tooltip
                trigger={
                  <CashIcon className="h-5 w-5 text-yellow-500 self-end" />
                }
                content={
                  <>Patient has a credit of {formatUSD(-collectableBalance)}</>
                }
              />
            ) : (
              <Tooltip
                trigger={
                  <div className="flex items-center rounded-full p-2 hover:bg-gray-100">
                    <MinusIcon className="w-2 text-gray-500" />
                  </div>
                }
                content={<>No past balance</>}
              />
            )}
          </div>
          <div className="w-6 flex items-center justify-center">
            {bill && billIsPaid(bill, paymentsToday) ? (
              <Tooltip
                trigger={
                  <CheckIcon className="h-5 w-5 text-green-600 self-end" />
                }
                content={
                  <>
                    {formatUSD(bill!.toCollect.patientPaid)} paid towards this
                    visit.
                  </>
                }
              />
            ) : (
              <Tooltip
                trigger={
                  <div className="flex items-center rounded-full p-2 hover:bg-gray-100">
                    <MinusIcon className="w-2 text-gray-500" />
                  </div>
                }
                content={<>Today's balance is not paid</>}
              />
            )}
          </div>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      // TODO: Handle estimates etc
      const bill = row.original.appointment.bill.at(0);
      const paymentsToday =
        row.original.appointment.sameDayAccountPaymentsTotal;
      const paid = bill && billIsPaid(bill, paymentsToday);
      if (value.includes("Paid") && paid) return true;
      if (value.includes("Unpaid") && !paid) return true;
      return false;
    },
    enableSorting: false,
    enableHiding: false,
  },
];
