import React from "react";
import * as RadixTooltip from "@radix-ui/react-tooltip";

export const Tooltip: React.FC<
  React.PropsWithChildren<{
    trigger: React.ReactNode;
    content?: React.ReactNode;
    side?: "top" | "right" | "bottom" | "left";
    buttonClassName?: string;
    contentClassName?: string;
  }>
> = ({ trigger, content, side, buttonClassName, contentClassName }) => {
  const triggerRef = React.useRef(null);
  const tooltipSide = side ?? "top";
  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root delayDuration={400}>
        <RadixTooltip.Trigger
          type="button"
          ref={triggerRef}
          className={buttonClassName ?? ""}
          onClick={(event) => event.preventDefault()}
        >
          {trigger}
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            side={tooltipSide}
            className={`bg-gray-900 text-white drop-shadow-xl text-sm rounded-md px-2 py-1 z-[9999] ${contentClassName ?? ""}`}
            onPointerDownOutside={(event) => {
              if (event.target === triggerRef.current) event.preventDefault();
            }}
          >
            {content}
            <RadixTooltip.Arrow offset={10} fill="rgba(17, 24, 39, 1)" />
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};
