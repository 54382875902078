import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { parseISO, format } from "date-fns";
import React, { Fragment, useState } from "react";
import * as RQ from "react-query";

import { Disclosure, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ChevronDownIcon,
  CloudIcon,
  DocumentDownloadIcon,
  ExclamationIcon,
  FastForwardIcon,
  PlusCircleIcon,
  XCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import {
  Bold,
  Icon,
  List,
  ListItem,
  Metric,
  ProgressBar,
  Text,
} from "@tremor/react";
import { SkipForward } from "lucide-react";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import { useAnalytics } from "../../analytics-context";
import { Card, Modal, SubmitButton } from "../../components";
import { Button } from "../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
} from "../../components/ui/dropdown-menu";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { constants } from "../../constants";
import { useDashboardResizable } from "../../dashboard-resizable";
import {
  BulkCreateEstimatesForAppointments,
  BulkCreateEstimatesForAppointmentsVariables,
} from "../../generated/BulkCreateEstimatesForAppointments";
import {
  CancelMultipleAppointmentEstimationWorkflows,
  CancelMultipleAppointmentEstimationWorkflowsVariables,
} from "../../generated/CancelMultipleAppointmentEstimationWorkflows";
import {
  GetEstimationWorkflowAppointments,
  GetEstimationWorkflowAppointmentsVariables,
} from "../../generated/GetEstimationWorkflowAppointments";
import { GetMe_me_activeLocation_integrations as Integration } from "../../generated/GetMe";
import { GetVerificationWorkflowPolicies_location_verificationWorkflowStatuses as VerificationWorkflowStatus } from "../../generated/GetVerificationWorkflowPolicies";
import { WorkflowStage } from "../../generated/globalTypes";
import {
  UpdateMultipleAppointmentEstimationWorkflowStatuses,
  UpdateMultipleAppointmentEstimationWorkflowStatusesVariables,
} from "../../generated/UpdateMultipleAppointmentEstimationWorkflowStatuses";
import { VISIT_COLLECTION_REQUEST_FIELDS } from "../../graphql";
import { useUser } from "../../user-context";
import {
  cn,
  formatDateMMDDYYYY,
  formatUSD,
  isDefined,
  mapNullable,
} from "../../utils";
import { StatusIndicator } from "../billing";
import { HorizontalPadding } from "../layout";
import { ConfirmationDialog } from "../verifications/list";
import { EstimateSyncStatus, EstimationWorklistRow, columns } from "./columns";
import { EstimationSidePanel } from "./side-panel";
import { DataTable } from "./table";
import { GetRunningBulkSyncEstimatesToIntegrationWorkflowsForLocation } from "../../generated/GetRunningBulkSyncEstimatesToIntegrationWorkflowsForLocation";

export const GET_ESTIMATION_WORKFLOW_APPOINTMENTS = gql`
  query GetEstimationWorkflowAppointments($locationId: String!) {
    location(where: { id: $locationId }) {
      id
      estimationWorkflowStatuses(orderBy: [{ stage: asc }, { position: asc }]) {
        id
        stage
        name
        description
      }
    }
    estimationWorkflowAppointments {
      id
      createdAt
      updatedAt

      # Estimation Workflow state
      estimationWorkflowStatus {
        id
        name
        stage
      }
      lastEstimationWorkflowStartedAt
      lastEstimationWorkflowCompletedAt
      lastEstimationWorkflowCanceledAt
      lastEstimationError
      estimationWorkflowActiveAt
      estimationWorkflowArchivedAt

      start
      end
      appointmentLabelings {
        id
        appointmentLabel {
          id
          name
        }
      }
      account {
        id
        accountType {
          id
          name
        }
        patient {
          id
          displayName
        }
      }
      insurancePolicies {
        id
        priority
        memberId
        renewalDate
        insurancePolicyVerificationStatus
        unverifiedReason
        payer {
          id
          name
          eligibilityEnabled
        }
      }
      provider {
        id
        displayName
        eligibilityEnabled
      }
      mostRecentVisitCollectionRequest {
        id
        amount
        lastSavedToIntegrationAt
        savedToIntegration {
          id
          name
        }
        savedToIntegrationBy {
          id
          firstName
          lastName
        }
      }
      visitCollectionRequests {
        id
        amount
        lastSavedToIntegrationAt
        savedToIntegration {
          id
          name
        }
        savedToIntegrationBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const BulkWorklistItemActionMenu: React.FC<{
  worklistRows: EstimationWorklistRow[];
  trigger: React.ReactNode;
  // After successful compltion of the bulk action, this callback is called
  // to reset the table selection and refresh the worklist
  onClose: () => void;
}> = ({ worklistRows, trigger, onClose }) => {
  const [bulkCreateEstimatesDialogOpen, setBulkCreateEstimatesDialogOpen] =
    useState(false);

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>{trigger}</DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[150px]">
          <DropdownMenuItem
            onClick={() => {
              setBulkCreateEstimatesDialogOpen(true);
            }}
          >
            Create Estimates
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      {bulkCreateEstimatesDialogOpen && (
        <BulkCreateEstimatesDialog
          close={() => {
            setBulkCreateEstimatesDialogOpen(false);
            onClose();
          }}
          selected={worklistRows}
        />
      )}
    </>
  );
};

const CANCEL_MULTIPLE_APPOINTMENT_ESTIMATION_WORKFLOWS = gql`
  mutation CancelMultipleAppointmentEstimationWorkflows(
    $appointmentIds: [String!]!
  ) {
    cancelMultipleAppointmentEstimationWorkflows(
      appointmentIds: $appointmentIds
    ) {
      id
      estimationWorkflowStatus {
        id
        stage
        name
      }
    }
  }
`;

const UPDATE_MULTIPLE_APPOINTMENT_ESTIMATION_WORKFLOW_STATUSES = gql`
  mutation UpdateMultipleAppointmentEstimationWorkflowStatuses(
    $appointmentIds: [String!]!
    $statusId: String!
  ) {
    updateMultipleAppointmentEstimationWorkflowStatuses(
      appointmentIds: $appointmentIds
      statusId: $statusId
    ) {
      id
      estimationWorkflowStatus {
        id
        stage
        name
      }
    }
  }
`;

const BULK_CREATE_ESTIMATES_FOR_APPOINTMENTS = gql`
  ${VISIT_COLLECTION_REQUEST_FIELDS}
  mutation BulkCreateEstimatesForAppointments($appointmentIds: [String!]!) {
    bulkCreateEstimatesForAppointments(appointmentIds: $appointmentIds) {
      appointmentId
      visitCollectionRequest {
        ...VisitCollectionRequestFields
      }
    }
  }
`;

export const BulkCreateEstimatesDialog = ({
  close,
  selected,
}: {
  close: () => void;
  selected: EstimationWorklistRow[];
}) => {
  const user = useUser()!;
  const queryClient = RQ.useQueryClient();
  const analytics = useAnalytics();
  const [
    bulkCreateEstimatesForAppointments,
    bulkCreateEstimatesForAppointmentsResult,
  ] = useMutation<
    BulkCreateEstimatesForAppointments,
    BulkCreateEstimatesForAppointmentsVariables
  >(BULK_CREATE_ESTIMATES_FOR_APPOINTMENTS);
  // const createEstimateItems = selected.filter((row) =>
  //   [WorkflowStage.Todo, WorkflowStage.InProgress].includes(
  //     row.appointment.estimationWorkflowStatus.stage
  //   )
  // );
  const createEstimateItems = selected;

  const estimationResults = (
    bulkCreateEstimatesForAppointmentsResult.data
      ?.bulkCreateEstimatesForAppointments ?? []
  ).reduce<{ estimated: number; skipped: number }>(
    (acc, result) => {
      if (result.visitCollectionRequest) {
        acc.estimated = acc.estimated + 1;
      } else {
        acc.skipped = acc.skipped + 1;
      }
      return acc;
    },
    {
      estimated: 0,
      skipped: 0,
    }
  );

  const estimated = estimationResults.estimated ?? 0;
  const skipped = estimationResults.skipped ?? 0;

  const renewalAppointments = selected.filter((row) => {
    return row.appointment.insurancePolicies.some((policy) => {
      return new Date(policy.renewalDate) < new Date(row.appointment.start);
    });
  });

  return (
    <Modal open={true} setOpen={close}>
      <div>
        {bulkCreateEstimatesForAppointmentsResult.called &&
        !bulkCreateEstimatesForAppointmentsResult.loading &&
        estimationResults ? (
          <>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Benefits Assigned Batch
              </h3>
              <div>
                <List className="my-4">
                  <ListItem>
                    <div className="flex justify-start items-center truncate space-x-4">
                      <Icon
                        variant="light"
                        icon={PlusCircleIcon}
                        size="md"
                        color="green"
                      />
                      <div className="truncate">
                        <Text className="truncate">
                          <Bold>Estimated</Bold>
                        </Text>
                      </div>
                    </div>
                    <Text>{estimated}</Text>
                  </ListItem>
                  <ListItem>
                    <div className="flex justify-start items-center truncate space-x-4">
                      <Icon
                        variant="light"
                        icon={SkipForward}
                        size="md"
                        color="gray"
                      />
                      <div className="truncate">
                        <Text className="truncate">
                          <Bold>Skipped</Bold>
                        </Text>
                      </div>
                    </div>
                    <Text>{skipped}</Text>
                  </ListItem>
                </List>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                onClick={() => close()}
              >
                Close
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Create estimates for {createEstimateItems.length} appointments?
              </h3>
              {renewalAppointments.length > 0 && (
                <div className="mt-2 p-4 bg-red-100 border border-red-400 text-red-700 rounded relative">
                  <div className="flex items-center mb-2">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-500 mr-2"
                      aria-hidden="true"
                    />
                    <Text className="font-bold text-lg">Warning:</Text>
                  </div>
                  <Text className="mb-2">
                    The following appointments have policies that will renew
                    before the appointment date:
                  </Text>
                  <List className="pl-6 list-disc">
                    {renewalAppointments.map((row) => (
                      <ListItem key={row.id}>
                        <Text>
                          <strong>{row.patientName}</strong>:{" "}
                          {row.start
                            ? format(row.start, "yyyy-MM-dd HH:mm:ss")
                            : "Invalid Date"}
                        </Text>
                      </ListItem>
                    ))}
                  </List>
                </div>
              )}
            </div>
            <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                onClick={() => close()}
              >
                Close
              </button>
              <SubmitButton
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                loading={bulkCreateEstimatesForAppointmentsResult.loading}
                onClick={() => {
                  bulkCreateEstimatesForAppointments({
                    variables: {
                      appointmentIds: createEstimateItems.map(
                        (item) => item.appointment!.id
                      ),
                    },
                    onCompleted: () => {
                      analytics?.track("Appointments Bulk Estimated", {
                        organizationId: user.organization.id,
                        organizationName: user.organization.name,
                        locationId: user.activeLocation.id,
                        locationName: user.activeLocation.name,
                        count: createEstimateItems.length,
                      });
                      toast.success("Successfully created estimates");
                      queryClient.invalidateQueries("estimationWorklist");
                    },
                    onError: (e) => {
                      toast.error("Failed to create estimates");
                    },
                  });
                }}
              >
                Confirm
              </SubmitButton>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

const BULK_SYNC_ESTIMATES_TO_INTEGRATION = gql`
  mutation BulkSyncEstimatesToIntegration(
    $visitCollectionRequestIds: [String!]!
    $integrationId: String!
  ) {
    bulkSyncEstimatesToIntegration(
      visitCollectionRequestIds: $visitCollectionRequestIds
      integrationId: $integrationId
    ) {
      workflowId
    }
  }
`;

const GET_BULK_SYNC_ESTIMATES_WORKFLOW = gql`
  query GetBulkSyncEstimatesToIntegrationWorkflow($workflowId: String!) {
    getBulkSyncEstimatesToIntegrationWorkflow(workflowId: $workflowId) {
      cursor
      batchSize
      totalSaved
      totalSkipped
      totalError
      results {
        status
        error
      }
    }
  }
`;

export const BulkSyncEstimatesDialog: React.FC<{
  close: () => void;
  selected: EstimationWorklistRow[];
  integration: Integration;
  existingWorkflowId?: string;
}> = ({ close, selected, integration, existingWorkflowId }) => {
  const user = useUser()!;
  const analytics = useAnalytics();
  const [bulkSync, bulkSyncResult] = useMutation(
    BULK_SYNC_ESTIMATES_TO_INTEGRATION
  );
  const [workflowId, setWorkflowId] = useState<string | null>(
    existingWorkflowId ?? null
  );
  const [selectedEstimates, setSelectedEstimates] = useState<Set<string>>(
    new Set([
      ...selected
        .filter((row) => row.syncStatus === "Not Synced")
        .map((row) => row.id),
      ...selected
        .filter((row) => row.syncStatus === "Previously Synced")
        .map((row) => row.id),
    ])
  );

  const readyToSync = selected.filter((row) => row.syncStatus === "Not Synced");
  const previouslySyncedChanged = selected.filter(
    (row) => row.syncStatus === "Previously Synced"
  );
  const previouslySyncedUnchanged = selected.filter(
    (row) => row.syncStatus === "Synced"
  );
  const rowsToSync = [...readyToSync, ...previouslySyncedChanged];

  const willSync = selectedEstimates.size;
  const skipping = selected.length - willSync;

  const visitCollectionRequestIds = rowsToSync
    .filter((row) => selectedEstimates.has(row.id))
    .map((row) => row.appointment.mostRecentVisitCollectionRequest?.id)
    .filter(isDefined);

  const toggleAll = () => {
    const allRows = rowsToSync;
    if (selectedEstimates.size === allRows.length) {
      setSelectedEstimates(new Set());
    } else {
      setSelectedEstimates(new Set(allRows.map((row) => row.id)));
    }
  };

  return (
    <Modal open={true} setOpen={close}>
      <div>
        {!bulkSyncResult.loading && workflowId ? (
          <BulkSyncEstimatesWorkflowProgress
            workflowId={workflowId}
            integration={integration}
            setOpen={close}
          />
        ) : (
          <>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Sync estimates to {integration.name} for {selected.length}{" "}
                appointments?
              </h3>
              <div className="mt-2">
                <List className="my-4">
                  <Disclosure defaultOpen={rowsToSync.length > 0}>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="w-full">
                          <ListItem>
                            <div className="flex justify-start items-center truncate space-x-4">
                              <Icon
                                variant="light"
                                icon={CloudIcon}
                                size="md"
                                color="green"
                              />
                              <div className="flex items-center gap-2">
                                <Text className="truncate">
                                  <Bold>To be synced</Bold>
                                </Text>
                                <ChevronDownIcon
                                  className={cn(
                                    "h-6 w-6 text-gray-300 transition ease-in-out duration-200 group-hover:text-gray-400 cursor-pointer",
                                    open && "rotate-180"
                                  )}
                                />
                              </div>
                            </div>
                            <Text>{willSync}</Text>
                          </ListItem>
                        </Disclosure.Button>

                        <Disclosure.Panel>
                          {rowsToSync.length === 0 ? (
                            <div className="flex items-center justify-center h-full py-4">
                              <Text>No estimates to sync</Text>
                            </div>
                          ) : (
                            <div className="border rounded-lg p-4 my-4">
                              <div className="flex items-center justify-between mb-2">
                                <Text>
                                  <Bold>Selected Estimates</Bold>
                                </Text>
                                <Button variant="ghost" onClick={toggleAll}>
                                  {selectedEstimates.size ===
                                  readyToSync.length +
                                    previouslySyncedChanged.length
                                    ? "Deselect All"
                                    : "Select All"}
                                </Button>
                              </div>
                              <div className="max-h-[50vh] overflow-y-auto">
                                {[
                                  ...readyToSync,
                                  ...previouslySyncedChanged,
                                ].map((row) => {
                                  const visitCollectionRequest =
                                    row.appointment
                                      .mostRecentVisitCollectionRequest;
                                  const previousVisitCollectionRequest =
                                    row.appointment.visitCollectionRequests.find(
                                      (vcr) =>
                                        vcr.id !== visitCollectionRequest?.id &&
                                        vcr.lastSavedToIntegrationAt
                                    );
                                  return (
                                    <div
                                      key={row.id}
                                      className="flex items-center justify-between py-2 border-t"
                                    >
                                      <div className="flex items-center gap-4">
                                        <input
                                          type="checkbox"
                                          checked={selectedEstimates.has(
                                            row.id
                                          )}
                                          onChange={() => {
                                            const newSelected = new Set(
                                              selectedEstimates
                                            );
                                            if (newSelected.has(row.id)) {
                                              newSelected.delete(row.id);
                                            } else {
                                              newSelected.add(row.id);
                                            }
                                            setSelectedEstimates(newSelected);
                                          }}
                                          className="h-4 w-4 rounded border-gray-300"
                                        />
                                        <div className="flex gap-2">
                                          <Text>{row.patientName}</Text>
                                          <Text className="text-sm text-gray-500">
                                            {mapNullable(formatDateMMDDYYYY)(
                                              row.appointment.start
                                            )}
                                          </Text>
                                          <Text className="text-sm text-gray-500">
                                            {
                                              row.appointment.provider
                                                ?.displayName
                                            }
                                          </Text>
                                          <Text className="text-sm text-gray-500">
                                            {row.appointment.appointmentLabelings
                                              .map(
                                                (labeling) =>
                                                  labeling.appointmentLabel.name
                                              )
                                              .join(", ")}
                                          </Text>
                                        </div>
                                      </div>
                                      <div className="flex items-center gap-2">
                                        {row.syncStatus ===
                                          "Previously Synced" && (
                                          <Text className="line-through text-gray-500">
                                            {mapNullable(formatUSD)(
                                              previousVisitCollectionRequest?.amount
                                            )}
                                          </Text>
                                        )}
                                        <Text className="font-medium">
                                          {mapNullable(formatUSD)(
                                            visitCollectionRequest?.amount
                                          )}
                                        </Text>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>

                  <ListItem>
                    <div className="flex justify-start items-center truncate space-x-4">
                      <Icon
                        variant="light"
                        icon={FastForwardIcon}
                        size="md"
                        color="gray"
                      />
                      <div className="truncate">
                        <Text className="truncate">
                          <Bold>Already synced (skipping)</Bold>
                        </Text>
                      </div>
                    </div>
                    <Text>{skipping}</Text>
                  </ListItem>
                </List>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="mt-5 sm:mt-6 flex justify-between space-x-4">
        <Button variant="secondary" onClick={close}>
          Close
        </Button>
        {!workflowId && (
          <div>
            <SubmitButton
              loading={bulkSyncResult.loading}
              onClick={() => {
                bulkSync({
                  variables: {
                    integrationId: integration.id,
                    visitCollectionRequestIds,
                  },
                  onCompleted: ({ bulkSyncEstimatesToIntegration }) => {
                    analytics?.track("Estimates Bulk Sync Started", {
                      organizationId: user.organization.id,
                      organizationName: user.organization.name,
                      locationId: user.activeLocation.id,
                      locationName: user.activeLocation.name,
                      count: selected.length,
                      willSync,
                      skipping,
                    });
                    setWorkflowId(bulkSyncEstimatesToIntegration.workflowId);
                  },
                  onError: () => {
                    toast.error("Failed to start sync workflow");
                  },
                });
              }}
            >
              Confirm
            </SubmitButton>
          </div>
        )}
      </div>
    </Modal>
  );
};

const BulkSyncEstimatesWorkflowProgress: React.FC<{
  workflowId: string;
  integration: Integration;
  setOpen: (open: boolean) => void;
}> = ({ workflowId, integration, setOpen }) => {
  const queryClient = RQ.useQueryClient();
  const { data, loading, startPolling, stopPolling } = useQuery(
    GET_BULK_SYNC_ESTIMATES_WORKFLOW,
    {
      variables: { workflowId },
      pollInterval: 2000,
    }
  );

  const cursor = data?.getBulkSyncEstimatesToIntegrationWorkflow?.cursor;
  const batchSize = data?.getBulkSyncEstimatesToIntegrationWorkflow?.batchSize;
  const totalSaved =
    data?.getBulkSyncEstimatesToIntegrationWorkflow?.totalSaved;
  const totalSkipped =
    data?.getBulkSyncEstimatesToIntegrationWorkflow?.totalSkipped;
  const totalError =
    data?.getBulkSyncEstimatesToIntegrationWorkflow?.totalError;

  const isComplete =
    isDefined(cursor) && isDefined(batchSize) && cursor === batchSize;

  React.useEffect(() => {
    if (isComplete) {
      stopPolling();
      queryClient.invalidateQueries("estimationWorklist");
    }
  }, [isComplete]);

  const progress = batchSize ? (100 * (cursor ?? 0)) / batchSize : 0;

  return (
    <div className="mt-3 text-center sm:mt-5">
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        Syncing Estimates to {integration.name}
      </h3>
      <div>
        <div className="flex flex-col gap-1">
          <div className="flex justify-between pt-4">
            <div className="flex gap-2 items-center">
              <Metric>{cursor}</Metric> complete
            </div>
            <div className="flex gap-2 items-center">
              of <Metric>{batchSize}</Metric> estimates
            </div>
          </div>
          <ProgressBar value={progress} color="emerald" className="mt-1" />
          <List className="my-4">
            <ListItem>
              <div className="flex justify-start items-center truncate space-x-4">
                <Icon
                  variant="light"
                  icon={CheckCircleIcon}
                  size="md"
                  color="green"
                />
                <div className="truncate">
                  <Text className="truncate">
                    <Bold>Synced</Bold>
                  </Text>
                </div>
              </div>
              <Text>{totalSaved}</Text>
            </ListItem>
            <ListItem>
              <div className="flex justify-start items-center truncate space-x-4">
                <Icon
                  variant="light"
                  icon={FastForwardIcon}
                  size="md"
                  color="gray"
                />
                <div className="truncate">
                  <Text className="truncate">
                    <Bold>Skipped</Bold>
                  </Text>
                </div>
              </div>
              <Text>{totalSkipped}</Text>
            </ListItem>
            <ListItem>
              <div className="flex justify-start items-center truncate space-x-4">
                <Icon
                  variant="light"
                  icon={XCircleIcon}
                  size="md"
                  color="red"
                />
                <div className="truncate">
                  <Text className="truncate">
                    <Bold>Error</Bold>
                  </Text>
                </div>
              </div>
              <Text>{totalError}</Text>
            </ListItem>
          </List>
        </div>
      </div>
    </div>
  );
};

const BulkSyncEstimatesWorkflowProgressAlert: React.FC<{
  workflowId: string;
  estimateSavingIntegration: Integration;
}> = ({ workflowId, estimateSavingIntegration }) => {
  const apollo = useApolloClient();
  const queryClient = RQ.useQueryClient();
  const [show, setShow] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const { data, loading, startPolling, stopPolling } = useQuery(
    GET_BULK_SYNC_ESTIMATES_WORKFLOW,
    {
      variables: { workflowId },
      pollInterval: 2000,
    }
  );

  const cursor = data?.getBulkSyncEstimatesToIntegrationWorkflow?.cursor;
  const batchSize = data?.getBulkSyncEstimatesToIntegrationWorkflow?.batchSize;
  const totalSaved =
    data?.getBulkSyncEstimatesToIntegrationWorkflow?.totalSaved;
  const totalSkipped =
    data?.getBulkSyncEstimatesToIntegrationWorkflow?.totalSkipped;
  const totalError =
    data?.getBulkSyncEstimatesToIntegrationWorkflow?.totalError;

  const isComplete =
    isDefined(cursor) && isDefined(batchSize) && cursor === batchSize;

  React.useEffect(() => {
    if (isComplete) {
      stopPolling();
      queryClient.invalidateQueries("estimationWorklist");
    }
  }, [isComplete, stopPolling, apollo]);

  if (!show) return null;
  return (
    <div className="rounded-md bg-blue-50 p-4 w-full">
      <div className="flex">
        <div className="flex-shrink-0">
          <CloudIcon
            className="h-5 w-5 text-blue-400 animate-[spin_3s_linear_infinite]"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-blue-700">
            Syncing {cursor} of {batchSize} estimates. {totalSaved} saved,{" "}
            {totalSkipped} skipped, {totalError} errors.
          </p>
          <div className="mt-3 text-sm md:ml-6 md:mt-0 flex gap-2">
            <button
              type="button"
              className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
              onClick={() => {
                setShowDialog(true);
              }}
            >
              View Batch
              <span aria-hidden="true"> &rarr;</span>
            </button>
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                className="inline-flex rounded-md bg-blue-50 p-1.5 text-blue-500 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-blue-50"
                onClick={() => {
                  setShow(false);
                }}
              >
                <span className="sr-only">Dismiss</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {showDialog && (
        <BulkSyncEstimatesDialog
          close={() => {
            setShowDialog(false);
          }}
          selected={[]}
          integration={estimateSavingIntegration}
          existingWorkflowId={workflowId}
        />
      )}
    </div>
  );
};

const GET_RUNNING_BULK_SYNC_ESTIMATES_WORKFLOWS_FOR_LOCATION = gql`
  query GetRunningBulkSyncEstimatesToIntegrationWorkflowsForLocation {
    getRunningBulkSyncEstimatesToIntegrationWorkflowsForLocation
  }
`;

const BulkUpdateStatusDialog = ({
  setOpen,
  selected,
  statuses,
  onComplete,
}: {
  setOpen: (open: boolean) => void;
  selected: EstimationWorklistRow[];
  statuses: VerificationWorkflowStatus[];
  onComplete: () => void;
}) => {
  const [
    updateMultipleAppointmentEstimationWorkflowStatuses,
    updateMultipleAppointmentEstimationWorkflowStatusesResult,
  ] = useMutation<
    UpdateMultipleAppointmentEstimationWorkflowStatuses,
    UpdateMultipleAppointmentEstimationWorkflowStatusesVariables
  >(UPDATE_MULTIPLE_APPOINTMENT_ESTIMATION_WORKFLOW_STATUSES);
  const [statusId, setStatusId] = useState<string>();
  const isValid = isDefined(statusId);
  return (
    <Modal open={true} setOpen={setOpen}>
      <div>
        <div className="mt-3 sm:mt-5">
          <h3 className="text-center text-lg leading-6 font-medium text-gray-900">
            Update status for {selected.length} appointments?
          </h3>
          <div className="mt-2 py-4">
            <label
              htmlFor="statusId"
              className="block text-sm font-medium text-gray-700"
            >
              Status
            </label>
            <Select value={statusId} onValueChange={setStatusId}>
              <SelectTrigger>
                <SelectValue placeholder={`Select a status`} />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {statuses.map((s) => (
                    <SelectItem value={s.id}>{s.name}</SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </div>
        <div className="mt-5 sm:mt-6 flex flex-justify space-x-4">
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
            onClick={() => setOpen(false)}
          >
            Close
          </button>
          <SubmitButton
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
            loading={
              updateMultipleAppointmentEstimationWorkflowStatusesResult.loading
            }
            disabled={!isValid}
            onClick={() => {
              updateMultipleAppointmentEstimationWorkflowStatuses({
                variables: {
                  appointmentIds: selected.map((item) => item.id),
                  statusId,
                },
                onCompleted: () => {
                  toast.success("Updated appointments");
                  setOpen(false);
                  onComplete();
                },
                onError: () => {
                  toast.error("Error updating appointments");
                },
              });
            }}
          >
            Confirm
          </SubmitButton>
        </div>
      </div>
    </Modal>
  );
};

export const EstimationWorkflowStatusIndicator: React.FC<{
  status: { stage: WorkflowStage };
}> = ({ status }) => {
  return (
    <StatusIndicator
      colorClass={
        status.stage === WorkflowStage.Todo
          ? "bg-gray-400"
          : status.stage === WorkflowStage.InProgress
          ? "bg-yellow-500"
          : status.stage === WorkflowStage.Complete
          ? "bg-green-500"
          : status.stage === WorkflowStage.Canceled
          ? "bg-red-500"
          : "bg-gray-400"
      }
    />
  );
};

const isSyncable = (row: EstimationWorklistRow) => {
  return isDefined(row.estimate);
};

export const EstimationWorklist: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const user = useUser()!;
  const queryClient = RQ.useQueryClient();
  const apollo = useApolloClient();
  const [bulkRemoveDialogOpen, setBulkRemoveDialogOpen] = useState(false);
  const [bulkUpdateStatusDialogOpen, setBulkUpdateStatusDialogOpen] =
    useState(false);
  const [bulkCreateEstimatesDialogOpen, setBulkCreateEstimatesDialogOpen] =
    useState(false);
  const [bulkSyncEstimatesDialogOpen, setBulkSyncEstimatesDialogOpen] =
    useState(false);
  const { setPanelChild } = useDashboardResizable();
  const estimateSavingIntegration = user.activeLocation.integrations.find(
    (i) => i.savingVisitCollectionRequestsEnabled
  );

  const { data, loading } = useQuery<
    GetEstimationWorkflowAppointments,
    GetEstimationWorkflowAppointmentsVariables
  >(GET_ESTIMATION_WORKFLOW_APPOINTMENTS, {
    variables: {
      locationId: user.activeLocation.id,
    },
    fetchPolicy: "cache-only",
  });

  const worklistQuery = RQ.useQuery({
    queryKey: ["estimationWorklist"],
    queryFn: () =>
      fetch(`${constants.VITE_GRAPHQL_URL}/api/worklists/estimations`, {
        credentials: "include",
        headers: {
          "x-pledge-demo": window.localStorage.getItem("demo") ?? "false",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          apollo.writeQuery<
            GetEstimationWorkflowAppointments,
            GetEstimationWorkflowAppointmentsVariables
          >({
            query: GET_ESTIMATION_WORKFLOW_APPOINTMENTS,
            variables: {
              locationId: user.activeLocation.id,
            },
            data: res,
            overwrite: true,
          });
        }),
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  });

  const [
    cancelMultipleAppointmentEstimationWorkflows,
    cancelMultipleAppointmentEstimationWorkflowsResult,
  ] = useMutation<
    CancelMultipleAppointmentEstimationWorkflows,
    CancelMultipleAppointmentEstimationWorkflowsVariables
  >(CANCEL_MULTIPLE_APPOINTMENT_ESTIMATION_WORKFLOWS);

  const rows = data?.estimationWorkflowAppointments ?? [];

  const tableData: EstimationWorklistRow[] = rows.map((row) => {
    const appointment = row;
    const patientName = appointment.account.patient.displayName;
    let start = appointment?.start ? parseISO(appointment?.start) : null;
    const estimate =
      appointment.mostRecentVisitCollectionRequest?.amount ?? null;
    const primary = appointment.insurancePolicies.at(0);

    let timestamp;
    switch (appointment.estimationWorkflowStatus.stage) {
      case WorkflowStage.Todo:
        timestamp = appointment.estimationWorkflowActiveAt;
        break;
      case WorkflowStage.InProgress:
        timestamp = appointment.lastEstimationWorkflowStartedAt;
        break;
      case WorkflowStage.Complete:
        timestamp = appointment.lastEstimationWorkflowCompletedAt;
        break;
      case WorkflowStage.Canceled:
        timestamp = appointment.lastEstimationWorkflowCanceledAt;
        break;
    }

    const visitCollectionRequest = appointment.mostRecentVisitCollectionRequest;
    const previousVisitCollectionRequest =
      appointment.visitCollectionRequests.find(
        (vcr) =>
          vcr.id !== visitCollectionRequest?.id && vcr.lastSavedToIntegrationAt
      );
    // Check if we have a previous synced version first
    const hasPreviousSyncedVersion =
      previousVisitCollectionRequest?.lastSavedToIntegrationAt;
    const currentVersionSynced =
      visitCollectionRequest?.lastSavedToIntegrationAt;
    const amountChanged =
      visitCollectionRequest?.amount !== undefined &&
      visitCollectionRequest?.amount !== previousVisitCollectionRequest?.amount;

    let syncStatus: EstimateSyncStatus = "Unable to Sync";
    if (visitCollectionRequest) {
      if (!hasPreviousSyncedVersion && !currentVersionSynced) {
        syncStatus = "Not Synced";
      } else if (!currentVersionSynced && amountChanged) {
        syncStatus = "Previously Synced";
      } else if (visitCollectionRequest.lastSavedToIntegrationAt) {
        syncStatus = "Synced";
      }
    }

    return {
      id: row.id,
      timestamp: mapNullable(parseISO)(timestamp),
      appointment,
      status: row.estimationWorkflowStatus.name,
      estimationWorkflowStatus: row.estimationWorkflowStatus,
      lastEstimationError: row.lastEstimationError,
      start,
      date: parseISO(row.estimationWorkflowActiveAt!),
      patientName,
      providerName: appointment.provider?.displayName ?? null,
      appointmentLabels: appointment.appointmentLabelings.map(
        (labeling) => labeling.appointmentLabel.name
      ),
      estimate,
      insurancePolicies: appointment.insurancePolicies,
      memberId: primary?.memberId ?? null,
      payer: primary?.payer?.name ?? null,
      verificationStatus: primary?.insurancePolicyVerificationStatus ?? null,
      syncStatus,
    };
  });

  const locationEstimationWorkflowStatuses =
    data?.location?.estimationWorkflowStatuses ?? [];

  const statuses = locationEstimationWorkflowStatuses.map((s) => ({
    id: s.id,
    name: s.name,
    stage: s.stage,
    count: rows.filter((row) => row.estimationWorkflowStatus.id === s.id)
      .length,
  }));

  const enabledColumns = columns.filter((c) => {
    if (c.id === "syncStatus") {
      return isDefined(estimateSavingIntegration);
    }
    return true;
  });

  const runningBulkSyncWorkflowsResult =
    useQuery<GetRunningBulkSyncEstimatesToIntegrationWorkflowsForLocation>(
      GET_RUNNING_BULK_SYNC_ESTIMATES_WORKFLOWS_FOR_LOCATION
    );

  const runningWorkflows =
    runningBulkSyncWorkflowsResult.data
      ?.getRunningBulkSyncEstimatesToIntegrationWorkflowsForLocation ?? [];

  return (
    <div className="flex flex-col gap-8">
      {runningWorkflows.length > 0 && isDefined(estimateSavingIntegration) && (
        <HorizontalPadding>
          <div className="flex flex-col gap-2 w-full">
            {runningWorkflows.map((workflowId) => (
              <BulkSyncEstimatesWorkflowProgressAlert
                key={workflowId}
                workflowId={workflowId}
                estimateSavingIntegration={estimateSavingIntegration}
              />
            ))}
          </div>
        </HorizontalPadding>
      )}
      <DataTable
        data={tableData}
        columns={enabledColumns}
        loading={loading || worklistQuery.isLoading}
        defaultColumnFilters={[
          {
            id: "status",
            // TODO: Pull the initial workflow status for the location
            value: ["Unestimated"],
          },
        ]}
      >
        {(table) => {
          const selectedRows = table.getSelectedRowModel().rows;

          return (
            <>
              <HorizontalPadding>
                <div className="flex gap-4">
                  {statuses.map((status) => (
                    <Card
                      key={status.id}
                      onClick={() => {
                        table
                          .getColumn("status")
                          ?.setFilterValue([status.name]);
                      }}
                      className="min-w-[12rem] hover:bg-gray-50 hover:cursor-pointer"
                    >
                      <div className="w-full flex flex-col gap-2 text-center">
                        <div className="flex justify-center items-center gap-2">
                          <EstimationWorkflowStatusIndicator status={status} />
                          {status.name}
                        </div>
                        <div className="text-xl">{status.count}</div>
                      </div>
                    </Card>
                  ))}
                </div>
              </HorizontalPadding>

              <div className="fixed bottom-0 right-0 w-full z-10">
                <BottomDrawer
                  open={selectedRows.length > 0}
                  setOpen={() => table.resetRowSelection()}
                  selectedRows={selectedRows.map((r) => r.original)}
                  deselect={() => table.resetRowSelection()}
                  selectAll={() => table.toggleAllRowsSelected()}
                  allSelected={table.getIsAllRowsSelected()}
                  ActionButtons={
                    <>
                      <SubmitButton
                        type="button"
                        loading={
                          cancelMultipleAppointmentEstimationWorkflowsResult.loading
                        }
                        onClick={() => {
                          setBulkRemoveDialogOpen(true);
                        }}
                        className="inline-flex items-center justify-center bg-red-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                      >
                        Cancel
                      </SubmitButton>

                      <DownloadEstimationWorklistButton
                        rows={selectedRows.map((row) => row.original)}
                      />

                      <Button
                        className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                        onClick={() => {
                          setPanelChild(
                            <EstimationSidePanel
                              ids={selectedRows.map((row) => row.original.id)}
                            />
                          );
                          table.resetRowSelection();
                        }}
                        type="button"
                      >
                        Execute {selectedRows.length} Tasks
                        <SkipForward className="ml-2 h-4 w-4" />
                      </Button>

                      {selectedRows.every((r) =>
                        [WorkflowStage.Todo, WorkflowStage.InProgress].includes(
                          r.original.estimationWorkflowStatus.stage
                        )
                      ) ? (
                        <Button
                          onClick={() => {
                            setBulkCreateEstimatesDialogOpen(true);
                          }}
                          className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                        >
                          Create Estimates
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            setBulkCreateEstimatesDialogOpen(true);
                          }}
                          className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                        >
                          Re-estimate
                        </Button>
                      )}

                      <Button
                        onClick={() => {
                          setBulkUpdateStatusDialogOpen(true);
                        }}
                        className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                      >
                        Update Status
                      </Button>

                      {estimateSavingIntegration &&
                        selectedRows.every((row) =>
                          isSyncable(row.original)
                        ) && (
                          <Button
                            onClick={() => {
                              setBulkSyncEstimatesDialogOpen(true);
                            }}
                            className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                          >
                            Sync to {estimateSavingIntegration.name}
                            <CloudIcon className="h-5 w-5 ml-2 text-white" />
                          </Button>
                        )}
                    </>
                  }
                />
              </div>
              {bulkRemoveDialogOpen && (
                <ConfirmationDialog
                  setOpen={setBulkRemoveDialogOpen}
                  selected={selectedRows}
                  loading={
                    cancelMultipleAppointmentEstimationWorkflowsResult.loading
                  }
                  onConfirm={(selected) => {
                    cancelMultipleAppointmentEstimationWorkflows({
                      variables: {
                        appointmentIds: selected.map((r) => r.original.id),
                      },
                      onCompleted: () => {
                        toast.success("Removed from worklist");
                        queryClient.invalidateQueries("estimationWorklist");
                        table.resetRowSelection();
                        setBulkRemoveDialogOpen(false);
                      },
                      onError: () => {
                        toast.error("Error removing from worklist");
                      },
                    });
                  }}
                  title={(selected) => (
                    <div className="flex gap-2 items-center text-xl font-medium">
                      Remove {selected.length} appointments from worklist?
                    </div>
                  )}
                  children={() => (
                    <div className="flex gap-2 items-center">
                      <div>
                        {selectedRows.length} appointments will be removed from
                        the worklist.
                      </div>
                    </div>
                  )}
                />
              )}
              {bulkCreateEstimatesDialogOpen && (
                <BulkCreateEstimatesDialog
                  close={() => {
                    setBulkCreateEstimatesDialogOpen(false);
                    table.resetRowSelection();
                  }}
                  selected={selectedRows.map((r) => r.original)}
                />
              )}
              {bulkUpdateStatusDialogOpen && (
                <BulkUpdateStatusDialog
                  setOpen={setBulkUpdateStatusDialogOpen}
                  selected={selectedRows.map((r) => r.original)}
                  statuses={locationEstimationWorkflowStatuses}
                  onComplete={() => {
                    table.resetRowSelection();
                  }}
                />
              )}
              {bulkSyncEstimatesDialogOpen && estimateSavingIntegration && (
                <BulkSyncEstimatesDialog
                  close={() => {
                    setBulkSyncEstimatesDialogOpen(false);
                    table.resetRowSelection();
                    apollo.refetchQueries({
                      include: [
                        GET_RUNNING_BULK_SYNC_ESTIMATES_WORKFLOWS_FOR_LOCATION,
                      ],
                    });
                  }}
                  integration={estimateSavingIntegration}
                  selected={selectedRows.map((r) => r.original)}
                />
              )}
            </>
          );
        }}
      </DataTable>
    </div>
  );
};

const DownloadEstimationWorklistButton: React.FC<
  React.PropsWithChildren<{
    rows: EstimationWorklistRow[];
  }>
> = ({ rows }) => {
  const user = useUser()!;
  const analytics = useAnalytics();

  const headerRow = [
    "Date",
    "Patient",
    "Account",
    "Status",
    "Appointment Type",
    "Provider",
    "Estimate",
  ];
  const csvRows = rows.map((row) => {
    return [
      row.start ? formatDateMMDDYYYY(row.start.toISOString()) : null,
      row.patientName,
      row.appointment.account.accountType?.name,
      row.estimationWorkflowStatus,
      row.appointmentLabels.join(", "),
      null, // TODO
    ];
  });

  return (
    <>
      <CSVLink
        data={[headerRow, ...csvRows]}
        filename="estimation-worklist.csv"
        className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
        onClick={() => {
          analytics?.track("Estimation Worklist Downloaded", {
            organizationId: user.organization.id,
            organizationName: user.organization.name,
            locationId: user.activeLocation.id,
            locationName: user.activeLocation.name,
          });
        }}
        target="_blank"
      >
        Download
        <DocumentDownloadIcon
          className="ml-2 -mr-1 h-5 w-5"
          aria-hidden="true"
        />
      </CSVLink>
    </>
  );
};

export const BottomDrawer: React.FC<
  React.PropsWithChildren<{
    open: boolean;
    setOpen: (open: boolean) => void;
    selectedRows: EstimationWorklistRow[];
    selectAll: () => void;
    deselect: () => void;
    allSelected: boolean;
    ActionButtons: React.ReactNode;
  }>
> = ({
  open,
  setOpen,
  selectedRows,
  selectAll,
  allSelected,
  deselect,
  ActionButtons,
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <div>
        <Transition.Child
          as={Fragment}
          enter="transform transition ease-in-out duration-500 sm:duration-700"
          enterFrom="translate-y-full"
          enterTo="translate-y-0"
          leave="transform transition ease-in-out duration-500 sm:duration-700"
          leaveFrom="translate-y-0"
          leaveTo="translate-y-full"
        >
          <div className="flex h-full flex-col bg-indigo-600 text-white py-6 shadow-xl">
            <div className="px-4 sm:px-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2 pl-16">
                  <div className="contents gap-1">
                    {selectedRows.length} patients
                    <span className="text-gray-300">selected</span>
                  </div>
                  {!allSelected && (
                    <button
                      onClick={selectAll}
                      className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                    >
                      Select All
                    </button>
                  )}
                  <button
                    onClick={deselect}
                    className="inline-flex items-center justify-center bg-indigo-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-opacity-50 disabled:cursor-not-allowed"
                  >
                    Deselect
                  </button>
                </div>
                <div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                  {ActionButtons}
                </div>
              </div>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition.Root>
  );
};
