import React, { useEffect, useState } from "react";
import { Card } from "../../components/ui/card";
import { DocumentNode, gql, useMutation, useQuery } from "@apollo/client";
import { PatientSummary } from "../patients/profile";
import {
  AppointmentDisplay,
  InsurancePolicyRow,
  InsurancePolicyVerificationStatusBadge,
  REQUEST_ELIGIBILITY,
} from "../worklists/policies/split-pane";
import {
  CompletionSection,
  WorklistProgressSection,
} from "../worklists/split-pane";
import { useDashboardResizable } from "../../dashboard-resizable";
import {
  CheckCircleIcon,
  DotsVerticalIcon,
  ExclamationCircleIcon,
  PlusIcon,
  XIcon,
} from "@heroicons/react/outline";
import {
  COVERAGE_BENEFIT_FIELDS,
  ELIGIBILITY_REQUEST_STATUS_FIELD,
  INSURANCE_POLICY_SUMMARY_FIELDS,
} from "../../graphql";
import { formatDistanceToNow, parseISO, set, startOfDay } from "date-fns";
import {
  GetVerificationWorkflowInsurancePolicyDetails,
  GetVerificationWorkflowInsurancePolicyDetailsVariables,
  GetVerificationWorkflowInsurancePolicyDetails_insurancePolicy as InsurancePolicy,
  GetVerificationWorkflowInsurancePolicyDetails_insurancePolicy_nextAppointment as Appointment,
} from "../../generated/GetVerificationWorkflowInsurancePolicyDetails";
import { Disclosure } from "@headlessui/react";
import { cn, isDefined } from "../../utils";
import { Badge, SubmitButton, Tooltip } from "../../components";
import { VerifyInsurancePolicyBenefitsTask } from "../worklists/policies/verify-benefits-task";
import { ConfirmInsurancePolicyBenefitsTask } from "../worklists/policies/confirm-benefits-task";
import { Button } from "../../components/ui/button";
import { useUser } from "../../user-context";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { StatusIndicator } from "../billing";
import { WorkflowStage } from "../../generated/globalTypes";
import { toast } from "react-toastify";
import {
  EligibilityStatusBadge,
  REQUEST_APPOINTMENT_ELIGIBILITY,
} from "../appointments";
import { Link } from "react-router-dom";
import {
  RequestAppointmentEligibility,
  RequestAppointmentEligibilityVariables,
} from "../../generated/RequestAppointmentEligibility";
import {
  RequestEligibility,
  RequestEligibilityVariables,
} from "../../generated/RequestEligibility";
import {
  BenefitsConfirmationButton,
  CompletenessScore,
  SendBenefitsEmailButton,
  ServiceBenefitHoverCard,
} from "../patients/insurances/show";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { OvalSpinner } from "../../components/loading";
import {
  GetVerificationWorkflowWorklist,
  GetVerificationWorkflowWorklistVariables,
} from "../../generated/GetVerificationWorkflowWorklist";
import { VerificationWorkflowStatusIndicator } from "./list";
import { Eligibility } from "../patients/eligibilities/show";

const GET_VERIFICATION_WORKFLOW_INSURANCE_POLICY_DETAILS = gql`
  ${INSURANCE_POLICY_SUMMARY_FIELDS}
  ${ELIGIBILITY_REQUEST_STATUS_FIELD}
  ${COVERAGE_BENEFIT_FIELDS}
  query GetVerificationWorkflowInsurancePolicyDetails(
    $id: String!
    $startOfDay: DateTime!
  ) {
    insurancePolicy(where: { id: $id }) {
      id
      createdAt
      updatedAt
      active
      ...InsurancePolicySummaryFields

      # Verification Workflow state
      verificationWorkflowStatus {
        id
        name
        stage
      }
      lastVerificationWorkflowReason
      lastVerificationWorkflowStartedAt
      lastVerificationWorkflowCompletedAt
      lastVerificationWorkflowCanceledAt
      verificationWorkflowActiveAt
      verificationWorkflowArchivedAt

      memberId
      priority
      ignoredAt
      acceptedAt
      insurancePolicyVerificationStatus
      patient {
        id
        displayName
        dateOfBirth
        birthSex
        email
        insurancePolicies(where: { id: { not: { equals: $id } } }) {
          id
          ...InsurancePolicySummaryFields
        }
        integrationLinks {
          id
          createdAt
          lastCheckedAt
          integration {
            id
            name
            supportsOnDemandPatientSync
          }
          integrationLinkSyncs(orderBy: { createdAt: desc }, take: 1) {
            id
            integrationLinkSyncErrors {
              id
              message
            }
          }
        }
      }
      accounts {
        id
        accountType {
          id
          name
        }
      }
      accountCoverages {
        id
        account {
          id
          accountType {
            id
            name
          }
          appointments(
            where: {
              start: { gte: $startOfDay }
              status: { notIn: [Canceled, Rescheduled, NoShow] }
            }
            orderBy: { start: { sort: asc, nulls: last } }
            take: 3
          ) {
            id
            start
            end
            appointmentLabelings {
              id
              appointmentLabel {
                id
                name
              }
            }
            provider {
              id
              displayName
              eligibilityEnabled
            }
            matchingBenefitMappings {
              insurancePolicy {
                id
              }
              benefitMappings {
                id
                name
                providerServiceConfiguration {
                  id
                }
                benefitMappingFallbacks {
                  id
                  providerServiceConfiguration {
                    id
                  }
                }
              }
            }
          }
        }
      }
      mostRecentCoverageBenefits {
        id
        ...CoverageBenefitFields
        createdAt
        insurancePolicyId
        providerServiceConfiguration {
          id
          name
          shortName
          serviceType
          priorityOrder
        }
        networkStatus
        coverageLevel
        empty
        authRequired
        copay
        copayBenefit {
          id
          eligibilityRequestId
          code
          serviceType
          serviceTypeDisplay
          coverageLevel
          networkStatusDisplay
          placeOfServiceDisplay
          notes
          validTo
          validFrom
          authRequired
        }
        coinsurance
        coinsuranceBenefit {
          id
          eligibilityRequestId
          code
          serviceType
          serviceTypeDisplay
          coverageLevel
          networkStatusDisplay
          placeOfServiceDisplay
          notes
          validTo
          validFrom
          authRequired
        }
        remainingDeductible
        remainingDeductibleBenefit {
          id
          eligibilityRequestId
          code
          serviceType
          serviceTypeDisplay
          coverageLevel
          networkStatusDisplay
          placeOfServiceDisplay
          notes
          validTo
          validFrom
          authRequired
        }
        maxDeductible
        maxDeductibleBenefit {
          id
          eligibilityRequestId
          code
          serviceType
          serviceTypeDisplay
          coverageLevel
          networkStatusDisplay
          placeOfServiceDisplay
          notes
          validTo
          validFrom
          authRequired
        }
        deductibleApplies
        metDeductible
        deductibleAppliesBenefit {
          id
          eligibilityRequestId
          code
          serviceType
          serviceTypeDisplay
          coverageLevel
          networkStatusDisplay
          placeOfServiceDisplay
          notes
          validTo
          validFrom
          authRequired
        }
        remainingOutOfPocket
        remainingOutOfPocketBenefit {
          id
          eligibilityRequestId
          code
          serviceType
          serviceTypeDisplay
          coverageLevel
          networkStatusDisplay
          placeOfServiceDisplay
          notes
          validTo
          validFrom
          authRequired
        }
        maxOutOfPocket
        maxOutOfPocketBenefit {
          id
          eligibilityRequestId
          code
          serviceType
          serviceTypeDisplay
          coverageLevel
          networkStatusDisplay
          placeOfServiceDisplay
          notes
          validTo
          validFrom
          authRequired
        }
        metOutOfPocketMax
        remainingVisits
        remainingVisitsBenefit {
          id
          eligibilityRequestId
          code
          serviceType
          serviceTypeDisplay
          coverageLevel
          networkStatusDisplay
          placeOfServiceDisplay
          notes
          validTo
          validFrom
          authRequired
          quantityQualifier
        }
        maxVisits
        maxVisitsBenefit {
          id
          eligibilityRequestId
          code
          serviceType
          serviceTypeDisplay
          coverageLevel
          networkStatusDisplay
          placeOfServiceDisplay
          notes
          validTo
          validFrom
          authRequired
          quantityQualifier
        }
        nonCovered
        nonCoveredBenefit {
          id
          eligibilityRequestId
        }
        activeCoverageBenefit {
          id
          eligibilityRequestId
        }
      }
      payer {
        id
        name
        eligibilityEnabled
      }
      mostRecentEligibilityRequest {
        id
        status
        createdAt
        automated
        rawEligibilityRequestResponses {
          id
          requestedServiceTypeCode
          requestedServiceTypeDisplay
        }
        reverificationStatus {
          needsReverification
          reason
          reasonCode
        }
        requestedBy {
          id
          firstName
          lastName
        }
        insurancePolicy {
          id
          payer {
            id
            name
          }
          plan {
            id
            name
          }
          patient {
            id
          }
        }
        ...EligibilityRequestStatusFields
      }
      communications(
        where: {
          contentType: { equals: BENEFITS }
          lastErrorType: { equals: null }
        }
        take: 1
      ) {
        id
        createdAt
        sentAt
      }
      nextAppointment {
        id
        start
        end
        appointmentLabelings {
          id
          appointmentLabel {
            id
            name
          }
        }
        provider {
          id
          displayName
          eligibilityEnabled
        }
        account {
          id
          accountType {
            id
            name
          }
          accountCoverages {
            id
            insurancePolicy {
              id
            }
          }
        }
      }
      organization {
        id
        providerServiceConfiguration {
          id
          name
          serviceType
          priorityOrder
        }
      }
    }
  }
`;

const CANCEL_INSURANCE_POLICY_VERIFICATION_WORKFLOW = gql`
  mutation CancelInsurancePolicyVerificationWorkflow($id: String!) {
    cancelInsurancePolicyVerificationWorkflow(insurancePolicyId: $id) {
      id
      lastVerificationWorkflowCanceledAt
      verificationWorkflowStatus {
        id
        name
        stage
      }
    }
  }
`;

const UPDATE_INSURANCE_POLICY_VERIFICATION_WORKFLOW_STATUS = gql`
  mutation UpdateInsurancePolicyVerificationWorkflowStatus(
    $id: String!
    $statusId: String!
  ) {
    updateOneInsurancePolicy(
      where: { id: $id }
      data: { verificationWorkflowStatus: { connect: { id: $statusId } } }
    ) {
      id
      verificationWorkflowStatus {
        id
        name
        stage
      }
    }
  }
`;

const PolicyVerificationWorkflowExecutionViewWrapper: React.FC<{
  id: string;
}> = ({ id }) => {
  const { data, loading } = useQuery<
    GetVerificationWorkflowInsurancePolicyDetails,
    GetVerificationWorkflowInsurancePolicyDetailsVariables
  >(GET_VERIFICATION_WORKFLOW_INSURANCE_POLICY_DETAILS, {
    variables: {
      id,
      startOfDay: startOfDay(new Date()),
    },
  });
  if (loading || !data) {
    return <div>Loading...</div>;
  }
  return (
    <PolicyVerificationWorkflowExecutionView
      insurancePolicy={data.insurancePolicy!}
    />
  );
};

export const VerifyButton: React.FC<{
  insurancePolicy: InsurancePolicy;
  patientId: string;
  appointment: Appointment | null;
  refetchQueries: DocumentNode[];
}> = ({ insurancePolicy, patientId, appointment, refetchQueries }) => {
  const user = useUser()!;
  const mostRecentEligibility = insurancePolicy.mostRecentEligibilityRequest;
  const eligibilityEnabled = insurancePolicy.payer.eligibilityEnabled;
  const defaultProvider = user.activeLocation.defaultEligibilityProvider;

  // const providerServiceConfigurations =
  //   insurancePolicy.organization.providerServiceConfiguration ?? [];

  // const trackedManually = benefitsTracked && !mostRecentEligibility?.eligible;

  // const benefitsSent = insurancePolicy.communications.length > 0;
  // const benefitsLastSent = insurancePolicy.communications.at(0)?.sentAt;

  const [requestEligibility, requestEligibilityResult] = useMutation<
    RequestEligibility,
    RequestEligibilityVariables
  >(REQUEST_ELIGIBILITY, {
    onCompleted: () => {
      toast.success("Eligibility request successful");
    },
    onError: () => {
      toast.error("Failed to check eligibility");
    },
    refetchQueries,
  });
  const [requestAppointmentEligibility, requestAppointmentEligibilityResult] =
    useMutation<
      RequestAppointmentEligibility,
      RequestAppointmentEligibilityVariables
    >(REQUEST_APPOINTMENT_ELIGIBILITY, {
      onCompleted: () => {
        toast.success("Eligibility request successful");
      },
      onError: () => {
        toast.error("Failed to check eligibility");
      },
      refetchQueries,
    });

  return (
    <>
      {!eligibilityEnabled ? (
        <Tooltip
          content={<>Electronic eligibility not supported</>}
          trigger={
            <SubmitButton
              className="w-full bg-indigo-600 border border-transparent rounded-md shadow-sm py-1.5 px-3 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex justify-center disabled:bg-opacity-50 disabled:cursor-not-allowed"
              onClick={(e) => {
                e.stopPropagation();
              }}
              disabled={true}
            >
              {mostRecentEligibility ? <>Verify</> : <>Reverify</>}
            </SubmitButton>
          }
        />
      ) : !insurancePolicy.memberId ? (
        <Tooltip
          content={<>No Member ID on the policy</>}
          trigger={
            <SubmitButton
              className="w-full bg-indigo-600 border border-transparent rounded-md shadow-sm py-1.5 px-3 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex justify-center disabled:bg-opacity-50 disabled:cursor-not-allowed"
              onClick={(e) => {
                e.stopPropagation();
              }}
              disabled={true}
            >
              {mostRecentEligibility ? <>Reverify</> : <>Verify</>}
            </SubmitButton>
          }
        />
      ) : appointment && appointment.provider?.eligibilityEnabled ? (
        <Tooltip
          content={<>Run verification using appointment information</>}
          trigger={
            <SubmitButton
              className="w-full bg-indigo-600 border border-transparent rounded-md shadow-sm py-1.5 px-3 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex justify-center disabled:bg-opacity-50 disabled:cursor-not-allowed"
              loading={
                requestAppointmentEligibilityResult.loading ||
                requestEligibilityResult.loading
              }
              onClick={(e) => {
                e.stopPropagation();
                requestAppointmentEligibility({
                  variables: {
                    appointmentId: appointment.id,
                    insurancePolicyId: insurancePolicy.id,
                    referenceDate: new Date(),
                    synchronous: true,
                  },
                });
              }}
            >
              {mostRecentEligibility ? <>Reverify</> : <>Verify</>}
            </SubmitButton>
          }
        />
      ) : defaultProvider ? (
        <Tooltip
          content={
            <>
              Run verification using default provider{" "}
              {defaultProvider.displayName}
            </>
          }
          trigger={
            <SubmitButton
              className="w-full bg-indigo-600 border border-transparent rounded-md shadow-sm py-1.5 px-3 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex justify-center disabled:bg-opacity-50 disabled:cursor-not-allowed"
              loading={
                requestAppointmentEligibilityResult.loading ||
                requestEligibilityResult.loading
              }
              onClick={(e) => {
                e.stopPropagation();
                if (appointment) {
                  requestAppointmentEligibility({
                    variables: {
                      appointmentId: appointment.id,
                      insurancePolicyId: insurancePolicy.id,
                      referenceDate: new Date(),
                      synchronous: true,
                    },
                  });
                } else {
                  requestEligibility({
                    variables: {
                      insurancePolicyId: insurancePolicy.id,
                      providerId: defaultProvider.id,
                      synchronous: true,
                    },
                  });
                }
              }}
            >
              {mostRecentEligibility ? <>Reverify</> : <>Verify</>}
            </SubmitButton>
          }
        />
      ) : (
        <Tooltip
          content={<>Open new verification form</>}
          trigger={
            <Link
              to={`/patients/${patientId}/eligibilities/new`}
              state={{ policy: insurancePolicy.id }}
              className="w-full bg-indigo-600 border border-transparent rounded-md shadow-sm py-1.5 px-3 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex justify-center disabled:bg-opacity-50 disabled:cursor-not-allowed"
            >
              {mostRecentEligibility ? <>Reverify</> : <>Verify</>}
            </Link>
          }
        />
      )}
    </>
  );
};

export const VerifyBenefitsDropdownButton: React.FC<{
  insurancePolicyId: string;
  patientId: string;
}> = ({ insurancePolicyId, patientId }) => (
  <DropdownMenu>
    <DropdownMenuTrigger asChild>
      <button className="h-8 p-1 rounded-full hover:bg-gray-100" type="button">
        <DotsVerticalIcon className="h-4 w-4" />
      </button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="end" className="min-w-[150px]">
      <DropdownMenuItem>
        <Link
          to={`/patients/${patientId}/eligibilities/new`}
          state={{ policy: insurancePolicyId }}
        >
          Open new eligibility form
        </Link>
      </DropdownMenuItem>
      <DropdownMenuItem>
        <Link to={`/patients/${patientId}/insurances/${insurancePolicyId}`}>
          Track benefits manually
        </Link>
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
);

const PolicyVerificationWorkflowExecutionView: React.FC<{
  insurancePolicy: InsurancePolicy;
}> = ({ insurancePolicy }) => {
  const user = useUser()!;
  const verificationWorkflowStatuses =
    user.activeLocation.verificationWorkflowStatuses;
  const [verificationWorkflowStatusId, setVerificationWorkflowStatusId] =
    useState(insurancePolicy.verificationWorkflowStatus.id);

  // Update the status if it changes
  useEffect(() => {
    setVerificationWorkflowStatusId(
      insurancePolicy.verificationWorkflowStatus.id
    );
  }, [insurancePolicy.verificationWorkflowStatus.id]);

  const patientId = insurancePolicy.patient.id;

  const [
    updateVerificationWorkflowStatus,
    updateVerificationWorkflowStatusResult,
  ] = useMutation(UPDATE_INSURANCE_POLICY_VERIFICATION_WORKFLOW_STATUS);
  const [requestEligibility, requestEligibilityResult] = useMutation<
    RequestEligibility,
    RequestEligibilityVariables
  >(REQUEST_ELIGIBILITY, {
    onCompleted: () => {
      toast.success("Eligibility request successful");
    },
    onError: () => {
      toast.error("Failed to check eligibility");
    },
    refetchQueries: [GET_VERIFICATION_WORKFLOW_INSURANCE_POLICY_DETAILS],
  });
  const [requestAppointmentEligibility, requestAppointmentEligibilityResult] =
    useMutation<
      RequestAppointmentEligibility,
      RequestAppointmentEligibilityVariables
    >(REQUEST_APPOINTMENT_ELIGIBILITY, {
      onCompleted: () => {
        toast.success("Eligibility request successful");
      },
      onError: () => {
        toast.error("Failed to check eligibility");
      },
      refetchQueries: [GET_VERIFICATION_WORKFLOW_INSURANCE_POLICY_DETAILS],
    });

  const otherPolicies = insurancePolicy.patient.insurancePolicies.filter(
    (ip) => ip.id !== insurancePolicy.id
  );
  const policyAccounts = insurancePolicy.accountCoverages.map(
    (ac) => ac.account
  );
  const appointment = insurancePolicy.nextAppointment;
  const appointments = insurancePolicy.accountCoverages.flatMap((ac) =>
    ac.account.appointments.map((a) => ({ ...a, account: ac.account }))
  );

  const mostRecentEligibility = insurancePolicy.mostRecentEligibilityRequest;
  const eligibilityEnabled = insurancePolicy.payer.eligibilityEnabled;
  const defaultProvider = user.activeLocation.defaultEligibilityProvider;

  const coverageBenefits = insurancePolicy.mostRecentCoverageBenefits;
  const providerServiceConfigurations =
    insurancePolicy.organization.providerServiceConfiguration ?? [];

  const benefitsTracked =
    coverageBenefits.filter((cb) => cb.empty === false).length > 0;
  const trackedManually = benefitsTracked && !mostRecentEligibility?.eligible;

  const benefitsSent = insurancePolicy.communications.length > 0;
  const benefitsLastSent = insurancePolicy.communications.at(0)?.sentAt;

  return (
    <>
      <Card>
        <div className="flex flex-col p-3 gap-1 divide-y">
          <PatientSummary
            patient={{
              ...insurancePolicy.patient,
              accounts: policyAccounts,
            }}
          />

          <div className="pt-2 pb-1 mt-1">
            <InsurancePolicyRow
              insurancePolicy={insurancePolicy}
              priority={insurancePolicy.priority}
              patientId={insurancePolicy.patient.id}
            />
          </div>

          {appointment && (
            <div className="pt-2">
              <div className="text-sm font-medium">Next Appointment</div>
              <AppointmentDisplay appointment={appointment} />
            </div>
          )}
        </div>
      </Card>

      <Card>
        <div className="flex flex-col p-3 gap-2 divide-y">
          <Disclosure>
            <div className="font-medium">
              <Disclosure.Button className="w-full flex justify-between items-center">
                {({ open }) => (
                  <>
                    <div className="flex justify-between items-end w-full">
                      <div className="font-medium">
                        Other Policies ({otherPolicies.length})
                      </div>
                      <div>
                        <PlusIcon
                          className={cn(
                            "h-6 w-6 text-gray-500 transition ease-in-out duration-200 group-hover:text-gray-400 cursor-pointer",
                            open && "rotate-45"
                          )}
                        />
                      </div>
                    </div>
                  </>
                )}
              </Disclosure.Button>
            </div>
            <Disclosure.Panel className="flex flex-col gap-2 pt-2">
              {otherPolicies.length === 0 && (
                <div className="text-base text-gray-900 italic truncate">
                  No other policies
                </div>
              )}
              {otherPolicies.map((policy) => (
                <InsurancePolicyRow
                  insurancePolicy={policy}
                  priority={policy.priority}
                  patientId={insurancePolicy.patient.id}
                />
              ))}
            </Disclosure.Panel>
          </Disclosure>

          <Disclosure>
            <div className="font-medium">
              <Disclosure.Button className="w-full flex justify-between items-center">
                {({ open }) => (
                  <>
                    <div className="flex justify-between items-end w-full">
                      <div className="font-medium pt-2">
                        Upcoming Appointments ({appointments.length})
                      </div>
                      <div>
                        <PlusIcon
                          className={cn(
                            "h-6 w-6 text-gray-500 transition ease-in-out duration-200 group-hover:text-gray-400 cursor-pointer",
                            open && "rotate-45"
                          )}
                        />
                      </div>
                    </div>
                  </>
                )}
              </Disclosure.Button>
            </div>
            <Disclosure.Panel className="flex flex-col gap-2 pt-2">
              {appointments.length === 0 && (
                <div className="text-base text-gray-900 italic truncate">
                  No upcoming appointments
                </div>
              )}
              {appointments.map((appointment) => {
                // Find if any of the appointment's policies match a rule
                // and if so, if the CoverageBenefit for the matched provider config
                // is empty or not

                const policyMatchedBenefits =
                  appointment.matchingBenefitMappings
                    .map(({ benefitMappings }) => {
                      const coverageBenefit =
                        insurancePolicy.mostRecentCoverageBenefits.find(
                          (cb) =>
                            cb.providerServiceConfiguration.id ===
                              benefitMappings.at(0)
                                ?.providerServiceConfiguration?.id ??
                            benefitMappings
                              .at(0)
                              ?.benefitMappingFallbacks?.some(
                                (bmf) =>
                                  bmf.providerServiceConfiguration.id ===
                                  cb.providerServiceConfiguration.id
                              )
                        );
                      return coverageBenefit ?? null;
                    })
                    .filter(isDefined);

                const hasMatchingRule =
                  appointment.matchingBenefitMappings.some(
                    (bm) => bm.benefitMappings.length > 0
                  );

                const emptyMatchedBenefit = policyMatchedBenefits.find(
                  (cb) => cb?.empty !== false
                );
                const nonEmptyMatchedBenefit = policyMatchedBenefits.find(
                  (cb) => cb?.empty === false
                );
                const benefitsTracked =
                  insurancePolicy.mostRecentCoverageBenefits.length > 0;

                return (
                  <AppointmentDisplay
                    key={appointment.id}
                    appointment={appointment}
                    Adornment={
                      hasMatchingRule ? (
                        !benefitsTracked ? (
                          <Tooltip
                            trigger={
                              <ExclamationCircleIcon className="h-4 w-4 text-gray-500" />
                            }
                            content={<>No benefits tracked yet</>}
                          />
                        ) : nonEmptyMatchedBenefit ? (
                          <Tooltip
                            trigger={
                              <CheckCircleIcon className="h-4 w-4 text-green-500" />
                            }
                            content={
                              <>
                                Appointment mapped to{" "}
                                {
                                  nonEmptyMatchedBenefit!
                                    .providerServiceConfiguration.name
                                }{" "}
                                benefit
                              </>
                            }
                          />
                        ) : (
                          <Tooltip
                            trigger={
                              <ExclamationCircleIcon className="h-4 w-4 text-yellow-500" />
                            }
                            content={
                              <>
                                Appointment mapped to{" "}
                                {
                                  emptyMatchedBenefit
                                    ?.providerServiceConfiguration?.name
                                }{" "}
                                benefit but it's empty
                              </>
                            }
                          />
                        )
                      ) : (
                        <Tooltip
                          trigger={
                            <ExclamationCircleIcon className="h-4 w-4 text-yellow-500" />
                          }
                          content={<>No benefit rules match this visit</>}
                        />
                      )
                    }
                  />
                );
              })}
            </Disclosure.Panel>
          </Disclosure>
        </div>
      </Card>

      <dl className="divide-y divide-gray-100 px-1">
        <div className="py-6 flex justify-between items-center">
          <dt className="font-medium text-gray-900">Workflow Status</dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <div className="flex items-center gap-2">
              <Select
                value={verificationWorkflowStatusId}
                onValueChange={(value) => {
                  const oldStatusId = verificationWorkflowStatusId;
                  setVerificationWorkflowStatusId(value);
                  updateVerificationWorkflowStatus({
                    variables: {
                      id: insurancePolicy.id,
                      statusId: value,
                    },
                    onCompleted: () => {
                      toast.success("Status updated");
                    },
                    onError: () => {
                      toast.error("Failed to update status");
                      setVerificationWorkflowStatusId(oldStatusId);
                    },
                  });
                }}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Select a status" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>Workflow Status</SelectLabel>
                    {verificationWorkflowStatuses.map((status) => (
                      <SelectItem value={status.id}>
                        <div className="flex items-center gap-2">
                          <VerificationWorkflowStatusIndicator
                            status={status}
                          />
                          {status.name}
                        </div>
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </dd>
        </div>

        <div className="py-6 flex flex-col gap-4">
          <div className="flex justify-between items-center">
            <dt className="text-sm font-medium text-gray-900">
              Last Verification Status
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {insurancePolicy.mostRecentEligibilityRequest ? (
                <EligibilityStatusBadge
                  eligibilityRequest={
                    insurancePolicy.mostRecentEligibilityRequest
                  }
                  patientId={patientId}
                  insurancePolicyId={insurancePolicy.id}
                />
              ) : (
                <Badge text="Unverified" variant="info" />
              )}
              {/* <InsurancePolicyVerificationStatusBadge
                insurancePolicyVerificationStatus={
                  insurancePolicy.insurancePolicyVerificationStatus
                }
              /> */}
            </dd>
          </div>

          <div className="flex flex-col">
            <div className="flex justify-between">
              <div>
                <label className="text-sm text-gray-500">Last verified</label>
                <div>
                  {mostRecentEligibility ? (
                    formatDistanceToNow(
                      parseISO(mostRecentEligibility.createdAt),
                      {
                        addSuffix: true,
                      }
                    )
                  ) : trackedManually ? (
                    <>Benefits tracked manually</>
                  ) : (
                    <span className="text-gray-500 italic">
                      Not yet verified
                    </span>
                  )}
                </div>
              </div>
              <div className="flex items-center gap-1">
                <VerifyButton
                  insurancePolicy={insurancePolicy}
                  patientId={patientId}
                  appointment={appointment}
                  refetchQueries={[
                    GET_VERIFICATION_WORKFLOW_INSURANCE_POLICY_DETAILS,
                  ]}
                />
                <VerifyBenefitsDropdownButton
                  insurancePolicyId={insurancePolicy.id}
                  patientId={patientId}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="py-6 flex flex-col gap-4">
          <div className="flex justify-between items-center">
            <dt className="text-sm font-medium text-gray-900">
              Benefits Confirmation
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {insurancePolicy.acceptedAt ? (
                <Badge variant="success" text="Confirmed" />
              ) : (
                <Badge variant="info" text="Unconfirmed" />
              )}
            </dd>
          </div>

          <div>
            <label className="text-sm text-gray-500">Completeness Score</label>
            <div className="flex gap-2">
              <CompletenessScore
                insurancePolicy={insurancePolicy}
                providerServiceConfigurations={providerServiceConfigurations}
              />
              <Link
                to={`/patients/${patientId}/insurances/${insurancePolicy.id}`}
                className="flex items-center gap-1 text-gray-900 group-hover:text-gray-700"
              >
                Manage benefits
                <div className="whitespace-nowrap transition ease-in-out duration-300 group-hover:translate-x-1">
                  <span aria-hidden="true"> &rarr;</span>
                </div>
              </Link>
            </div>
          </div>
          <div>
            <label className="text-sm text-gray-500">Benefits</label>
            <div className="flex flex-wrap gap-1 pt-1">
              {[...providerServiceConfigurations]
                .sort(
                  (a, b) =>
                    (a.priorityOrder ?? Infinity) -
                    (b.priorityOrder ?? Infinity)
                )
                .map((config) => {
                  const cb = coverageBenefits.find(
                    (b) => b.providerServiceConfiguration.id === config.id
                  );
                  if (!cb) {
                    return (
                      <ServiceBenefitHoverCard
                        key={config.id}
                        trigger={
                          <span className="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-500 ring-1 ring-inset ring-gray-600/10">
                            {config.shortName ?? config.name}
                          </span>
                        }
                        coverageBenefit={null}
                        patientId={patientId}
                        providerServiceConfiguration={config}
                        insurancePolicy={insurancePolicy}
                        inNetwork={null}
                      />
                    );
                  }
                  if (cb.empty) {
                    return (
                      <ServiceBenefitHoverCard
                        key={cb.id}
                        trigger={
                          <span className="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-500 ring-1 ring-inset ring-gray-600/10">
                            {cb.providerServiceConfiguration.shortName ??
                              cb.providerServiceConfiguration.name}
                          </span>
                        }
                        coverageBenefit={cb}
                        patientId={patientId}
                        providerServiceConfiguration={
                          cb.providerServiceConfiguration
                        }
                        insurancePolicy={insurancePolicy}
                        inNetwork={cb.networkStatus}
                      />
                    );
                  }
                  if (cb.nonCovered) {
                    return (
                      <ServiceBenefitHoverCard
                        key={cb.id}
                        trigger={
                          <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                            {cb.providerServiceConfiguration.shortName ??
                              cb.providerServiceConfiguration.name}
                          </span>
                        }
                        coverageBenefit={cb}
                        patientId={patientId}
                        providerServiceConfiguration={
                          cb.providerServiceConfiguration
                        }
                        insurancePolicy={insurancePolicy}
                        inNetwork={cb.networkStatus}
                      />
                    );
                  }

                  return (
                    <ServiceBenefitHoverCard
                      key={cb.id}
                      trigger={
                        <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                          {cb.providerServiceConfiguration.shortName ??
                            cb.providerServiceConfiguration.name}
                        </span>
                      }
                      coverageBenefit={cb}
                      patientId={patientId}
                      providerServiceConfiguration={
                        cb.providerServiceConfiguration
                      }
                      insurancePolicy={insurancePolicy}
                      inNetwork={cb.networkStatus}
                    />
                  );
                })}
            </div>
          </div>
          <div className="flex justify-end">
            <BenefitsConfirmationButton insurancePolicy={insurancePolicy} />
          </div>
        </div>
        <div className="py-6 flex flex-col gap-4">
          <div className="flex justify-between items-center">
            <dt className="text-sm font-medium text-gray-900">
              Benefits Email Sent
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {benefitsSent ? (
                <Badge variant="success" text="Sent" />
              ) : (
                <Badge variant="info" text="Not Sent" />
              )}
            </dd>
          </div>
          <div className="flex justify-between">
            <div>
              <label className="text-sm text-gray-500">Last sent</label>
              <div>
                {benefitsLastSent ? (
                  formatDistanceToNow(parseISO(benefitsLastSent), {
                    addSuffix: true,
                  })
                ) : (
                  <span className="text-gray-500 italic">Not yet sent</span>
                )}
              </div>
            </div>
            <div className="flex justify-end">
              <SendBenefitsEmailButton
                insurancePolicy={insurancePolicy}
                patientEmail={insurancePolicy.patient.email}
                variant="default"
              />
            </div>
          </div>
        </div>
      </dl>
    </>
  );
};

const GET_VERIFICATION_WORKFLOW_WORKLIST = gql`
  query GetVerificationWorkflowWorklist($ids: [String!]!) {
    insurancePolicies(where: { id: { in: $ids } }) {
      id
      verificationWorkflowStatus {
        id
        name
        stage
      }
    }
  }
`;

const VerificationWorkflowCompletionSection: React.FC<{
  ids: string[];
  onClose: () => void;
}> = ({ ids, onClose }) => {
  const total = ids.length;
  const { data, loading } = useQuery<
    GetVerificationWorkflowWorklist,
    GetVerificationWorkflowWorklistVariables
  >(GET_VERIFICATION_WORKFLOW_WORKLIST, {
    variables: {
      ids,
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const worklist = data?.insurancePolicies ?? [];
  const completed = worklist.reduce(
    (sum, policy) =>
      sum +
      (policy.verificationWorkflowStatus.stage === WorkflowStage.Complete
        ? 1
        : 0),
    0
  );
  const removed = worklist.reduce(
    (sum, policy) =>
      sum +
      (policy.verificationWorkflowStatus.stage === WorkflowStage.Canceled
        ? 1
        : 0),
    0
  );
  const skipped = total - completed - removed;

  return (
    <CompletionSection
      completed={completed}
      skipped={skipped}
      removed={removed}
      onClose={onClose}
    />
  );
};

export const VerificationSidePanel: React.FC<{ ids: string[] }> = ({ ids }) => {
  const { collapse } = useDashboardResizable();
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const total = ids.length;
  const currentId = ids[currentIndex];
  const [cancel, cancelResult] = useMutation(
    CANCEL_INSURANCE_POLICY_VERIFICATION_WORKFLOW
  );
  const { data, loading } = useQuery<
    GetVerificationWorkflowWorklist,
    GetVerificationWorkflowWorklistVariables
  >(GET_VERIFICATION_WORKFLOW_WORKLIST, {
    variables: {
      ids,
    },
  });
  const worklist = data?.insurancePolicies ?? [];
  const currentItem = worklist.find((policy) => policy.id === currentId);
  const completed =
    currentItem?.verificationWorkflowStatus.stage === WorkflowStage.Complete;
  const canceled =
    currentItem?.verificationWorkflowStatus.stage === WorkflowStage.Canceled;

  return (
    <div className="h-screen flex flex-col px-4 bg-white border-l border-gray-300 print:hidden">
      <div className="flex justify-between items-center pt-4">
        <h2 className="text-xl text-medium">Verification Worklist</h2>

        <button
          type="button"
          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          onClick={collapse}
        >
          <span className="sr-only">Close side pane</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      <WorklistProgressSection
        currentIndex={currentIndex}
        total={total}
        next={() => setCurrentIndex(currentIndex + 1)}
        previous={() => setCurrentIndex(currentIndex - 1)}
        nextDisabled={currentIndex === total - 1}
      />

      <div className="py-2 h-full flex flex-col">
        <div className="h-full max-h-[calc(100vh-14rem)] overflow-y-auto overflow-x-hidden flex flex-col gap-4">
          {currentIndex === total ? (
            <VerificationWorkflowCompletionSection
              ids={ids}
              onClose={() => {
                collapse();
              }}
            />
          ) : (
            <PolicyVerificationWorkflowExecutionViewWrapper id={currentId} />
          )}
        </div>
      </div>

      {currentIndex !== total && (
        <div className="flex border-t mt-2 py-4 gap-2">
          <div className="w-full flex justify-between">
            <Button
              variant="destructive"
              size="lg"
              type="button"
              disabled={canceled || cancelResult.loading}
              onClick={() => {
                cancel({
                  variables: {
                    id: currentId,
                  },
                  onCompleted: () => {
                    toast.success("Policy canceled");
                  },
                  onError: () => {
                    toast.error("Failed to cancel policy");
                  },
                });
              }}
            >
              {cancelResult.loading ? (
                <OvalSpinner className="h-4 w-4" />
              ) : (
                <>Cancel</>
              )}
            </Button>
            <Button
              size="lg"
              variant={completed ? "default" : "outline"}
              onClick={() => {
                setCurrentIndex(currentIndex + 1);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
