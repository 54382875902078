import { InformationCircleIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { useAuth } from "../../auth-context";
import { GetMe_me_organization_planTypes as PlanType } from "../../generated/GetMe";
import { InsuranceType } from "../../generated/globalTypes";

import { gql, useMutation, useQuery } from "@apollo/client";
import * as SelectPrimitive from "@radix-ui/react-select";
import React from "react";
import { toast } from "react-toastify";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../components/ui/alert-dialog";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectValue,
} from "../../components/ui/select";
import { useUser } from "../../user-context";
import { cn, formatUSD } from "../../utils";
import { GET_INSURANCE_POLICY } from "./insurances/show";

const GET_PLAN_BENEFIT_TEMPLATES = gql`
  query GetPlanBenefitTemplates($planTypeId: String!) {
    planBenefitTemplates(where: { planTypeId: { equals: $planTypeId } }) {
      id
      isDefault
      networkStatus
      coverageLevel
      copay
      coinsurance
      deductibleApplies
      maxDeductible
      maxOutOfPocket
      maxVisits
      authRequired
      nonCovered
      providerServiceConfiguration {
        id
        name
      }
    }
  }
`;

const SET_INSURANCE_POLICY_PLAN_TYPE = gql`
  mutation SetInsurancePolicyPlanType(
    $id: String!
    $planTypeId: String
    $applyTemplates: Boolean!
  ) {
    setInsurancePolicyPlanType(
      id: $id
      planTypeId: $planTypeId
      applyTemplates: $applyTemplates
    ) {
      id
      planType {
        id
        name
      }
    }
  }
`;

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    className={cn(
      "flex h-7 items-center justify-between rounded-md border border-input bg-background px-2 py-1 text-xs ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
      className
    )}
    {...props}
  >
    {children}
  </SelectPrimitive.Trigger>
));

// Add this helper function to group and sort plan types
const groupPlanTypes = (planTypes: PlanType[]) => {
  const groups = planTypes.reduce((acc, planType) => {
    const group = acc.get(planType.insuranceType) || [];
    group.push(planType);
    acc.set(planType.insuranceType, group);
    return acc;
  }, new Map<InsuranceType, PlanType[]>());

  // Sort each group alphabetically by name
  groups.forEach((group) => {
    group.sort((a, b) => a.name.localeCompare(b.name));
  });

  // Define display order and labels for insurance types
  const groupOrder = [
    {
      type: InsuranceType.COMMERCIAL,
      label: "Commercial Plans",
    },
    {
      type: InsuranceType.MEDICARE,
      label: "Medicare Plans",
    },
    {
      type: InsuranceType.MEDIGAP,
      label: "Medicare Supplement Plans",
    },
    {
      type: InsuranceType.MEDICAID,
      label: "Medicaid Plans",
    },
    {
      type: InsuranceType.TRICARE,
      label: "Tricare Plans",
    },
    {
      type: InsuranceType.CHAMPVA,
      label: "CHAMPVA Plans",
    },
    {
      type: InsuranceType.WORKERS_COMP,
      label: "Workers Comp Plans",
    },
    {
      type: InsuranceType.AUTO,
      label: "Auto Plans",
    },
    {
      type: InsuranceType.OTHER,
      label: "Other Plans",
    },
  ];

  return groupOrder
    .map(({ type, label }) => ({
      label,
      plans: groups.get(type) || [],
    }))
    .filter((group) => group.plans.length > 0);
};

export const PlanTypeSelect: React.FC<{
  insurancePolicy: {
    id: string;
    planType: {
      id: string;
      name: string;
    } | null;
  };
}> = ({ insurancePolicy }) => {
  const user = useUser();
  const { data } = useAuth();
  const [selectedPlanTypeId, setSelectedPlanTypeId] = useState<string | null>(
    insurancePolicy.planType?.id ?? null
  );
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [setInsurancePolicyPlanType] = useMutation(
    SET_INSURANCE_POLICY_PLAN_TYPE,
    {
      onCompleted: () => {
        setShowConfirmDialog(false);
      },
      onError: (error) => {
        console.error("Failed to update plan type:", error);
        // Reset to the original plan type on error
        setSelectedPlanTypeId(insurancePolicy.planType?.id ?? null);
        toast.error("Failed to update plan type");
      },
      refetchQueries: [GET_INSURANCE_POLICY],
    }
  );

  const { data: templateData } = useQuery(GET_PLAN_BENEFIT_TEMPLATES, {
    variables: { planTypeId: selectedPlanTypeId },
    skip: !selectedPlanTypeId,
  });

  const handlePlanTypeChange = (value: string) => {
    // Store the new selection temporarily
    setSelectedPlanTypeId(value);
    setShowConfirmDialog(true);
  };

  const handleConfirmDialogClose = () => {
    // Reset to the original plan type when dialog is closed without confirming
    setSelectedPlanTypeId(insurancePolicy.planType?.id ?? null);
    setShowConfirmDialog(false);
  };

  const handleConfirm = async (applyTemplates: boolean) => {
    try {
      await setInsurancePolicyPlanType({
        variables: {
          id: insurancePolicy.id,
          planTypeId: selectedPlanTypeId ? selectedPlanTypeId : null, // If empty string set to null
          applyTemplates,
        },
        onCompleted: () => {
          toast.success("Plan type updated");
          setShowConfirmDialog(false);
        },
      });
    } catch (error) {
      // Reset to the original plan type on error
      setSelectedPlanTypeId(insurancePolicy.planType?.id ?? null);
      console.error("Failed to update plan type:", error);
    }
  };

  const renderBenefitTemplatePreview = () => {
    if (!templateData?.planBenefitTemplates?.length) return null;

    return (
      <div className="mt-4">
        <h3 className="text-sm font-medium mb-2">
          Available Benefit Templates:
        </h3>
        <div className="space-y-4">
          {templateData.planBenefitTemplates.map((template: any) => (
            <div key={template.id} className="border rounded p-3">
              <div className="font-medium">
                {template.providerServiceConfiguration?.name ||
                  "Default Template"}
              </div>
              <div className="grid grid-cols-2 gap-2 text-sm mt-2">
                {template.networkStatus !== null && (
                  <div>
                    Network Status:{" "}
                    {template.networkStatus ? "In Network" : "Out of Network"}
                  </div>
                )}
                {template.coverageLevel && (
                  <div>Coverage Level: {template.coverageLevel}</div>
                )}
                {template.copay !== null && (
                  <div>Copay: {formatUSD(template.copay)}</div>
                )}
                {template.coinsurance !== null && (
                  <div>Coinsurance: {Number(template.coinsurance) * 100}%</div>
                )}
                {template.deductibleApplies !== null && (
                  <div>
                    Deductible Applies:{" "}
                    {template.deductibleApplies ? "Yes" : "No"}
                  </div>
                )}
                {template.maxDeductible !== null && (
                  <div>Max Deductible: {formatUSD(template.maxDeductible)}</div>
                )}
                {template.maxOutOfPocket !== null && (
                  <div>
                    Max Out of Pocket: {formatUSD(template.maxOutOfPocket)}
                  </div>
                )}
                {template.maxVisits !== null && (
                  <div>Max Visits: {template.maxVisits}</div>
                )}
                {template.authRequired !== null && (
                  <div>
                    Auth Required: {template.authRequired ? "Yes" : "No"}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const planTypes = user?.organization?.planTypes || [];
  const selectedPlanType = planTypes.find((pt) => pt.id === selectedPlanTypeId);

  return (
    <>
      <Select
        value={selectedPlanTypeId ?? undefined}
        onValueChange={handlePlanTypeChange}
      >
        <SelectTrigger
          className={cn(selectedPlanType ? "" : "!text-gray-500 font-normal")}
        >
          <SelectValue placeholder="Plan Type Unknown">
            {selectedPlanType?.name ?? "Plan Type Unknown"}
          </SelectValue>
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="">Unknown</SelectItem>
          {groupPlanTypes(planTypes).map((group) => (
            <SelectGroup key={group.label}>
              <SelectLabel className="px-2 py-1.5 text-xs font-semibold text-gray-500 bg-gray-50">
                {group.label}
              </SelectLabel>
              {group.plans.map((planType) => (
                <SelectItem
                  key={planType.id}
                  value={planType.id}
                  // className="pl-8"
                >
                  <div className="flex items-center justify-between w-full">
                    <span>{planType.name}</span>
                  </div>
                </SelectItem>
              ))}
            </SelectGroup>
          ))}
        </SelectContent>
      </Select>
      <AlertDialog open={showConfirmDialog}>
        <AlertDialogContent className="min-w-[600px]">
          <AlertDialogHeader>
            <AlertDialogTitle>Update Plan Type</AlertDialogTitle>
            <AlertDialogDescription>
              <div className="space-y-4">
                <div>
                  Are you sure you want to change the plan type for this
                  insurance policy to{" "}
                  <span className="font-medium">
                    {selectedPlanType?.name ?? "Unknown"}
                  </span>
                  ?
                </div>

                {selectedPlanType?.insuranceType === InsuranceType.MEDIGAP && (
                  <div className="rounded-md bg-blue-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <InformationCircleIcon
                          className="h-5 w-5 text-blue-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3 flex-1 md:flex items-center">
                        <p className="text-sm text-blue-700">
                          Medicare Supplement plans coordinate with Medicare to
                          cover some or all of Medicare's cost sharing.
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                {templateData?.planBenefitTemplates?.length > 0 && (
                  <div className="mt-2">
                    This plan type has benefit templates available. Would you
                    like to apply them?
                  </div>
                )}
                {renderBenefitTemplatePreview()}
              </div>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={handleConfirmDialogClose}>
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction onClick={() => handleConfirm(false)}>
              Update Plan Type Only
            </AlertDialogAction>
            {templateData?.planBenefitTemplates?.length > 0 && (
              <AlertDialogAction onClick={() => handleConfirm(true)}>
                Update Plan Type & Apply Templates
              </AlertDialogAction>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
