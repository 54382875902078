import React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useUser } from "../../user-context";
import {
  GetEditPatient,
  GetEditPatientVariables,
  GetEditPatient_patient as Patient,
  GetEditPatient_patient_insurancePolicies_payer as Payer,
} from "../../generated/GetEditPatient";
import { mapNullable, toDate } from "../../utils";
import { SubmitButton } from "../../components";
import { Layout, LoadingLayout } from "../layout";
import { useForm } from "react-hook-form";
import { GET_PAYERS } from "../payers";
import { GetPayers, GetPayersVariables } from "../../generated/GetPayers";
import { BirthSex } from "../../generated/globalTypes";
import {
  UpdatePatientAndCoverages,
  UpdatePatientAndCoveragesVariables,
} from "../../generated/UpdatePatientAndCoverages";
import { PatientForm } from "./patientForm";
import { Option } from "../patients/eligibilities/new";

export const EDIT_PATIENT_FRAGMENT = gql`
  fragment EditPatientFragment on Patient {
    id
    firstName
    lastName
    displayName
    dateOfBirth
    birthSex
    email
    emailInvalid
    organizationId
    insurancePolicies {
      id
      memberId
      priority
      groupId
      groupName
      active
      effectiveDate
      renewalDate
      terminationDate
      deletedAt
      payer {
        id
        name
      }
      accountCoverages {
        id
        account {
          id
          accountType {
            id
            name
          }
        }
        integrationLinks {
          id
          integration {
            id
            name
          }
        }
      }
    }
    accounts {
      id
      accountType {
        id
        name
      }
    }
  }
`;

export const GET_PATIENT = gql`
  ${EDIT_PATIENT_FRAGMENT}
  query GetEditPatient($id: String!) {
    patient(where: { id: $id }) {
      ...EditPatientFragment
    }
  }
`;

const UPDATE_PATIENT = gql`
  ${EDIT_PATIENT_FRAGMENT}
  mutation UpdatePatientAndCoverages(
    $id: String!
    $firstName: String!
    $lastName: String!
    $dateOfBirth: DateTime!
    $birthSex: BirthSex
    $email: String
    $emailInvalid: Boolean!
    $insurancePolicies: InsurancePolicyUpdateManyWithoutPatientNestedInput!
  ) {
    updateOnePatient(
      where: { id: $id }
      data: {
        firstName: { set: $firstName }
        lastName: { set: $lastName }
        dateOfBirth: { set: $dateOfBirth }
        birthSex: { set: $birthSex }
        email: { set: $email }
        emailInvalid: { set: $emailInvalid }
        insurancePolicies: $insurancePolicies
      }
    ) {
      ...EditPatientFragment
    }
  }
`;

export type PatientFormData = {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  birthSex: {
    value: BirthSex;
    label: string;
  };
  email: string | null;
  insurancePolicies: {
    id: string;
    key: string;
    payer: Option;
    active: boolean;
    memberId: string;
    groupName: string | null;
    groupId: string | null;
    effectiveDate: string | null;
    renewalDate: string | null;
    terminationDate: string | null;
    deletedAt: string | null;
    archived: boolean;
    accountIds: string[];
  }[];
};

const EditPatientForm: React.FC<
  React.PropsWithChildren<{
    patient: Patient;
    payers: Payer[];
  }>
> = ({ patient, payers }) => {
  const user = useUser()!;
  const [updatePatient, updatePatientResult] = useMutation<
    UpdatePatientAndCoverages,
    UpdatePatientAndCoveragesVariables
  >(UPDATE_PATIENT);

  const navigate = useNavigate();

  const defaultValues = {
    firstName: patient.firstName,
    lastName: patient.lastName,
    dateOfBirth: mapNullable(toDate)(patient.dateOfBirth) ?? undefined,
    birthSex: {
      value: patient.birthSex ?? undefined,
      label:
        patient.birthSex === BirthSex.FEMALE
          ? "Female"
          : patient.birthSex === BirthSex.MALE
          ? "Male"
          : undefined,
    },
    email: patient.email,
    insurancePolicies: patient.insurancePolicies
      .map((ip) => {
        const payer = ip.payer
          ? {
              value: ip.payer.id,
              label: ip.payer.name,
            }
          : undefined;
        return {
          id: ip.id,
          key: ip.id,
          payer,
          memberId: ip.memberId ?? "",
          groupId: ip.groupId ?? "",
          groupName: ip.groupName ?? "",
          active: ip.active,
          effectiveDate: ip.effectiveDate
            ? toDate(ip.effectiveDate)
            : undefined,
          renewalDate: ip.renewalDate ? toDate(ip.renewalDate) : undefined,
          terminationDate: ip.terminationDate
            ? toDate(ip.terminationDate)
            : undefined,
          existing: true,
          archived: !!ip.deletedAt,
          deletedAt: ip.deletedAt,
          accountIds: ip.accountCoverages.map((ac) => ac.account.id),
        };
      })
      // Sort archived policies to the end
      .sort((a, b) => {
        if (a.archived && !b.archived) {
          return 1;
        }
        if (!a.archived && b.archived) {
          return -1;
        }
        return 0;
      }),
  };
  const methods = useForm<PatientFormData>({
    reValidateMode: "onChange",
    defaultValues,
  });

  const onSubmit = async (data: PatientFormData) => {
    const defaultVerificationWorkflowStatus =
      user.activeLocation.defaultVerificationWorkflowStatus ??
      user.activeLocation.verificationWorkflowStatuses.find(
        (s) => s.stage === "Todo"
      )!;

    const existingAccountCoverages = patient.insurancePolicies.flatMap((ip) =>
      ip.accountCoverages.map((ac) => ({
        ...ac,
        insurancePolicyId: ip.id,
      }))
    );

    updatePatient({
      variables: {
        id: patient.id,
        firstName: data.firstName,
        lastName: data.lastName,
        dateOfBirth: data.dateOfBirth,
        birthSex: data.birthSex.value ?? null,
        email: !!data.email ? data.email : null,
        emailInvalid: patient.emailInvalid
          ? patient.email === data.email
          : false,
        insurancePolicies: {
          upsert: data.insurancePolicies.map((ip) => {
            const existingPolicyAccountCoverages =
              existingAccountCoverages.filter(
                (ac) => ac.insurancePolicyId === ip.id
              );

            // Only allow deleting account coverages that don't have integration links
            const accountCoveragesToDelete =
              existingPolicyAccountCoverages.filter(
                (ac) =>
                  !ip.accountIds.includes(ac.account.id) &&
                  ac.integrationLinks.length === 0
              );

            const accountCoveragesToCreate = ip.accountIds.filter(
              (accountId) =>
                !existingPolicyAccountCoverages.some(
                  (ac) => ac.account.id === accountId
                )
            );

            const accountCoveragesToUpdate =
              existingPolicyAccountCoverages.filter((ac) =>
                ip.accountIds.includes(ac.account.id)
              );

            return {
              where: {
                id: ip.id,
              },
              create: {
                memberId: ip.memberId,
                groupId: ip.groupId,
                groupName: ip.groupName,
                payer: {
                  ...(ip.payer
                    ? {
                        connect: {
                          id: ip.payer.value,
                        },
                      }
                    : {}),
                },
                active: ip.active,
                effectiveDate: !!ip.effectiveDate ? ip.effectiveDate : null,
                renewalDate: !!ip.renewalDate ? ip.renewalDate : null,
                terminationDate: ip.active
                  ? null
                  : !!ip.terminationDate
                  ? ip.terminationDate
                  : null,
                deletedAt: ip.archived ? ip.deletedAt ?? new Date() : null,
                organization: {
                  connect: {
                    id: patient.organizationId,
                  },
                },
                verificationWorkflowStatus: {
                  connect: {
                    id: defaultVerificationWorkflowStatus.id,
                  },
                },
                accountCoverages: {
                  create: accountCoveragesToCreate.map((accountId) => ({
                    memberId: ip.memberId,
                    groupId: ip.groupId ? ip.groupId : null,
                    groupName: ip.groupName ? ip.groupName : null,
                    payer: { connect: { id: ip.payer.value } },
                    active: ip.active,
                    validFrom: ip.effectiveDate ? ip.effectiveDate : null,
                    validTo: ip.renewalDate
                      ? ip.renewalDate
                      : ip.terminationDate
                      ? ip.terminationDate
                      : null,
                    deletedAt: ip.archived ? ip.deletedAt ?? new Date() : null,
                    account: {
                      connect: {
                        id: accountId,
                      },
                    },
                  })),
                },
              },
              update: {
                memberId: {
                  set: ip.memberId,
                },
                groupId: {
                  set: ip.groupId,
                },
                groupName: { set: ip.groupName },
                payer: {
                  ...(ip.payer
                    ? {
                        connect: {
                          id: ip.payer.value,
                        },
                      }
                    : {}),
                },
                active: { set: ip.active },
                effectiveDate: {
                  set: !!ip.effectiveDate ? ip.effectiveDate : null,
                },
                renewalDate: { set: !!ip.renewalDate ? ip.renewalDate : null },
                terminationDate: {
                  set: ip.active
                    ? null
                    : !!ip.terminationDate
                    ? ip.terminationDate
                    : null,
                },
                deletedAt: {
                  set: ip.archived ? ip.deletedAt ?? new Date() : null,
                },
                accountCoverages: {
                  create: accountCoveragesToCreate.map((accountId) => ({
                    memberId: ip.memberId,
                    groupId: ip.groupId ? ip.groupId : null,
                    groupName: ip.groupName ? ip.groupName : null,
                    payer: { connect: { id: ip.payer.value } },
                    active: ip.active,
                    validFrom: ip.effectiveDate ? ip.effectiveDate : null,
                    validTo: ip.renewalDate
                      ? ip.renewalDate
                      : ip.terminationDate
                      ? ip.terminationDate
                      : null,
                    deletedAt: ip.archived ? ip.deletedAt ?? new Date() : null,
                    account: {
                      connect: {
                        id: accountId,
                      },
                    },
                  })),
                  update: accountCoveragesToUpdate.map((ac) => ({
                    where: {
                      id: ac.id,
                    },
                    data: {
                      memberId: { set: ip.memberId },
                      groupId: { set: ip.groupId ? ip.groupId : null },
                      groupName: { set: ip.groupName ? ip.groupName : null },
                      payer: { connect: { id: ip.payer.value } },
                      active: { set: ip.active },
                      validFrom: {
                        set: ip.effectiveDate ? ip.effectiveDate : null,
                      },
                      validTo: {
                        set: ip.renewalDate
                          ? ip.renewalDate
                          : ip.terminationDate
                          ? ip.terminationDate
                          : null,
                      },
                      deletedAt: {
                        set: ip.archived ? ip.deletedAt ?? new Date() : null,
                      },
                    },
                  })),
                  delete: accountCoveragesToDelete.map((ac) => ({
                    id: ac.id,
                  })),
                },
              },
            };
          }),
        },
      },
      onCompleted: async () => {
        toast.success("Patient Updated");
        navigate(`/patients/${patient.id}`);
      },
      onError: () => {
        toast.error("Update failed.");
      },
    });
  };

  const errors = methods.formState.errors;

  return (
    <PatientForm
      patient={patient}
      methods={methods}
      payers={payers}
      onSubmit={onSubmit}
      formActions={
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <Link
            to={`/patients/${patient.id}`}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </Link>
          <div>
            <SubmitButton loading={updatePatientResult.loading} type="submit">
              Save
            </SubmitButton>
          </div>
        </div>
      }
    />
  );
};

export const EditPatient: React.FC<React.PropsWithChildren<unknown>> = () => {
  const user = useUser()!;
  const params = useParams<{ patientId: string }>();
  const patientId = params.patientId!;
  const { data, loading } = useQuery<GetEditPatient, GetEditPatientVariables>(
    GET_PATIENT,
    {
      variables: { id: patientId },
    }
  );
  const { data: payersData } = useQuery<GetPayers, GetPayersVariables>(
    GET_PAYERS,
    {
      variables: {
        organizationId: user.organization.id,
        locationId: user.activeLocation.id,
      },
    }
  );
  if (loading || !data) return <LoadingLayout header="Edit Patient" />;

  const patient = data.patient!;
  const payers = payersData?.payers ?? [];

  return (
    <Layout
      header={
        <div className="flex flex-col gap-4">
          <nav className="flex items-center justify-between pt-4">
            <div className="flex items-center gap-2">
              <Link
                className="font-medium text-gray-500 hover:text-gray-700"
                to="/patients"
              >
                Patients
              </Link>
              <svg
                className="h-5 w-5 flex-shrink-0 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              <Link
                className="font-medium text-gray-500 hover:text-gray-700"
                to={`/patients/${patient.id}`}
              >
                {patient.displayName}
              </Link>
              <svg
                className="h-5 w-5 flex-shrink-0 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              <div className="font-medium text-gray-700">Edit Patient</div>
            </div>
          </nav>
          <div className="flex justify-between">
            <h1 className="text-2xl font-semibold text-gray-900">
              Edit {patient.displayName}
            </h1>
          </div>
        </div>
      }
      content={
        <div className="py-8">
          <EditPatientForm patient={patient} payers={payers} />
        </div>
      }
    />
  );
};
