import { zodResolver } from "@hookform/resolvers/zod";
import * as HoverCard from "@radix-ui/react-hover-card";
import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  FieldArray,
  FieldArrayWithId,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import * as z from "zod";

import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CheckCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import { TextInput } from "@tremor/react";
import { toast } from "react-toastify";
import { EligibilityStatusBadge } from "..";
import { Badge, SubmitButton, Tooltip } from "../../../components";
import { OvalSpinner } from "../../../components/loading";
import { Button } from "../../../components/ui/button";
import { Checkbox } from "../../../components/ui/checkbox";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
} from "../../../components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../../../components/ui/form";
import { CurrencyInput, Input } from "../../../components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import {
  CalculateEstimate,
  CalculateEstimateVariables,
  CalculateEstimate_calculateEstimate as EstimateCalculation,
} from "../../../generated/CalculateEstimate";
import {
  CreateEstimateFromForm,
  CreateEstimateFromFormVariables,
} from "../../../generated/CreateEstimateFromForm";
import {
  GetEstimateFormData_appointment as Appointment,
  GetEstimateFormData_appointment_insurancePolicies_mostRecentCoverageBenefits as CoverageBenefit,
  GetEstimateFormData_appointment_estimates as Estimate,
  GetEstimateFormData_appointment_insurancePolicies as InsurancePolicy,
} from "../../../generated/GetEstimateFormData";
import { GetFeeSchedules_feeSchedules as FeeSchedule } from "../../../generated/GetFeeSchedules";
import { GetLocationChargeTemplates_chargeTemplates as ChargeTemplate } from "../../../generated/GetLocationChargeTemplates";
import {
  GetRecommendedBenefitForCharge,
  GetRecommendedBenefitForChargeVariables,
} from "../../../generated/GetRecommendedBenefitForCharge";
import { GetSaltedAppointment } from "../../../generated/GetSaltedAppointment";
import { InsuranceType } from "../../../generated/globalTypes";
import {
  LookupAllowedAmounts,
  LookupAllowedAmountsVariables,
} from "../../../generated/LookupAllowedAmounts";
import {
  COVERAGE_BENEFIT_FIELDS,
  LOOKUP_ALLOWED_AMOUNTS,
} from "../../../graphql";
import { useIsMounted } from "../../../hooks";
import {
  cn,
  decimalToPercentage,
  formatPercentage,
  formatUSD,
  isDefined,
  mapNullable,
  percentageToDecimal,
  toCents,
  toDollarStr,
} from "../../../utils";
import { ServiceTypeCode } from "../../patients/eligibilities/types";
import { NetworkBadge } from "../../patients/networkBadge";
import { PlanTypeSelect } from "../../patients/plan-type-select";
import { ChargemasterBadge } from "../../shared/visit-bill-display-card";
import { getCoordinationRuleDisplay } from "./coordination-rule-display";
import {
  AppointmentDisplay,
  CALCULATE_ESTIMATE,
  CodeSearchCommand,
  CREATE_ESTIMATE,
} from "./estimate-dialog";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";

type InsuranceServiceLine = {
  insurancePolicyId: string;
  serviceLines: ServiceLine[];
};
type ServiceLine = {
  chargemaster: {
    id: string;
    code: string;
    modifier1: string;
    modifier2: string;
    modifier3: string;
    modifier4: string;
    description: string | null;
    chargemasterGroupId: string | null;
  };
  units: number | null;
  description?: string | null;
  coverageBenefitId: string | null;
  copay: number | null;
  coinsurance: number | null;
  remainingDeductible: number | null;
  deductibleApplies: boolean | null;
  remainingOutOfPocket: number | null;
  allowedAmount: number | null;
  scheduledServiceFee: {
    id: string;
    feeSchedule: {
      id: string;
      name: string;
    };
  } | null;
};

const FormSchema = z.object({
  charges: z.array(
    z.object({
      chargemaster: z.object({
        id: z.string(),
        code: z.string(),
        modifier1: z.string(),
        modifier2: z.string(),
        modifier3: z.string(),
        modifier4: z.string(),
        description: z.string().nullable(),
        chargemasterGroupId: z.string().nullable(),
      }),
    })
  ),
  chargeTemplateId: z.string().optional(),
  feeScheduleId: z.string().optional(),
  //   insurancePolicyIds: z.array(z.string()),
  insuranceServiceLines: z.array(
    z.object({
      policyId: z.string().nullable(),
      enabled: z.string(),
      serviceLines: z.array(
        z.object({
          // If the service line was from existing estimate
          existing: z.boolean(),
          chargemaster: z.object({
            id: z.string(),
            code: z.string(),
            modifier1: z.string(),
            modifier2: z.string(),
            modifier3: z.string(),
            modifier4: z.string(),
            description: z.string().optional(),
            chargemasterGroupId: z.string().optional(),
          }),
          units: z.string(),
          coverageBenefitId: z.string().optional(),
          copay: z.string().optional(),
          coinsurance: z.string().optional(),
          remainingDeductible: z.string().optional(),
          deductibleApplies: z.string().optional(),
          remainingOutOfPocket: z.string().optional(),
          allowedAmount: z.string().optional(),
          scheduledServiceFeeId: z.string().optional(),
          scheduledServiceFee: z
            .object({
              id: z.string(),
              feeSchedule: z.object({
                id: z.string(),
                name: z.string(),
              }),
            })
            .optional(),
        })
      ),
    })
  ),
});

const isDefinedInput = (val: string | null | undefined) =>
  isDefined(val) && val !== "";

// const GET_CHARGEMASTER = gql`
//   query GetChargemaster($id: String!) {
//     chargemaster(where: { id: $id }) {
//       id
//       code
//       modifier1
//       modifier2
//       description
//     }
//   }
// `;

const CopayInput: React.FC<{
  field: FieldArray<
    z.infer<
      typeof FormSchema
    >["insuranceServiceLines"][number]["serviceLines"][number]
  >;
  name: `insuranceServiceLines.${number}.serviceLines.${number}.copay`;
  benefits: {
    coverageBenefit: CoverageBenefit;
    recommended: boolean;
  }[];
}> = ({ field, name, benefits }) => {
  const form = useFormContext<z.infer<typeof FormSchema>>();
  const [open, setOpen] = useState(false);
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <Popover
            open={open}
            onOpenChange={(open) => {
              // Skip opening the popover if there are no benefits
              if (open && benefits.length === 0) {
                return;
              }
              setOpen(open);
            }}
          >
            <PopoverTrigger asChild>
              <FormControl>
                <CurrencyInput
                  decimalsLimit={2}
                  onValueChange={(val) => {
                    field.onChange(val ?? "");
                  }}
                  defaultValue={field.value}
                  value={field.value}
                  className={!isDefinedInput(field.value) ? "bg-gray-100" : ""}
                />
              </FormControl>
            </PopoverTrigger>
            <PopoverContent
              className="p-0"
              onOpenAutoFocus={(e) => {
                e.preventDefault();
              }}
            >
              <Command>
                <CommandEmpty>No benefits found.</CommandEmpty>
                <CommandGroup>
                  {benefits.map(({ coverageBenefit, recommended }) => (
                    <CommandItem
                      value={coverageBenefit.id}
                      key={coverageBenefit.id}
                      onSelect={() => {
                        form.setValue(
                          name,
                          mapNullable(toDollarStr)(coverageBenefit.copay) ??
                            undefined
                        );
                        setOpen(false);
                      }}
                    >
                      <CheckCircleIcon
                        className={cn(
                          "mr-2 h-4 w-4 text-indigo-500",
                          recommended ? "opacity-100" : "opacity-0"
                        )}
                      />
                      <div className="flex items-center justify-between w-full">
                        <div>
                          {coverageBenefit.providerServiceConfiguration.name}
                        </div>
                        {mapNullable(formatUSD)(coverageBenefit.copay) ?? (
                          <span className="text-gray-500">N/A</span>
                        )}
                      </div>
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

const CoinsuranceInput: React.FC<{
  field: FieldArray<
    z.infer<
      typeof FormSchema
    >["insuranceServiceLines"][number]["serviceLines"][number]
  >;
  name: `insuranceServiceLines.${number}.serviceLines.${number}.coinsurance`;
  benefits: {
    coverageBenefit: CoverageBenefit;
    recommended: boolean;
  }[];
}> = ({ field, name, benefits }) => {
  const form = useFormContext<z.infer<typeof FormSchema>>();
  const [open, setOpen] = useState(false);
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <Popover
            open={open}
            onOpenChange={(open) => {
              // Skip opening the popover if there are no benefits
              if (open && benefits.length === 0) {
                return;
              }
              setOpen(open);
            }}
          >
            <PopoverTrigger asChild>
              <FormControl>
                <Input
                  type="number"
                  min={0}
                  max={100}
                  step={0.1}
                  adornment="%"
                  adornmentPosition="end"
                  className={!isDefinedInput(field.value) ? "bg-gray-100" : ""}
                  {...field}
                />
              </FormControl>
            </PopoverTrigger>
            <PopoverContent
              className="p-0"
              onOpenAutoFocus={(e) => {
                e.preventDefault();
              }}
            >
              <Command>
                <CommandEmpty>No benefits found.</CommandEmpty>
                <CommandGroup>
                  {benefits.map(({ coverageBenefit, recommended }) => (
                    <CommandItem
                      value={coverageBenefit.id}
                      key={coverageBenefit.id}
                      onSelect={() => {
                        form.setValue(
                          name,
                          mapNullable(decimalToPercentage)(
                            coverageBenefit.coinsurance
                          ) ?? undefined
                        );
                        setOpen(false);
                      }}
                    >
                      <CheckCircleIcon
                        className={cn(
                          "mr-2 h-4 w-4 text-indigo-500",
                          recommended ? "opacity-100" : "opacity-0"
                        )}
                      />
                      <div className="flex items-center justify-between w-full">
                        <div>
                          {coverageBenefit.providerServiceConfiguration.name}
                        </div>
                        {mapNullable(formatPercentage)(
                          coverageBenefit.coinsurance
                        ) ?? <span className="text-gray-500">N/A</span>}
                      </div>
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

const DeductibleInput: React.FC<{
  field: FieldArray<
    z.infer<
      typeof FormSchema
    >["insuranceServiceLines"][number]["serviceLines"][number]
  >;
  name: `insuranceServiceLines.${number}.serviceLines.${number}.remainingDeductible`;
  benefits: {
    coverageBenefit: CoverageBenefit;
    recommended: boolean;
  }[];
}> = ({ field, name, benefits }) => {
  const form = useFormContext<z.infer<typeof FormSchema>>();
  const [open, setOpen] = useState(false);
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <Popover
            open={open}
            onOpenChange={(open) => {
              // Skip opening the popover if there are no benefits
              if (open && benefits.length === 0) {
                return;
              }
              setOpen(open);
            }}
          >
            <PopoverTrigger asChild>
              <FormControl>
                <CurrencyInput
                  decimalsLimit={2}
                  onValueChange={(val) => {
                    field.onChange(val ?? "");
                  }}
                  defaultValue={field.value}
                  value={field.value}
                  className={!isDefinedInput(field.value) ? "bg-gray-100" : ""}
                />
              </FormControl>
            </PopoverTrigger>
            <PopoverContent
              className="p-0"
              onOpenAutoFocus={(e) => {
                e.preventDefault();
              }}
            >
              <Command>
                <CommandEmpty>No benefits found.</CommandEmpty>
                <CommandGroup>
                  {benefits.map(({ coverageBenefit, recommended }) => (
                    <CommandItem
                      value={coverageBenefit.id}
                      key={coverageBenefit.id}
                      onSelect={() => {
                        form.setValue(
                          name,
                          mapNullable(toDollarStr)(
                            coverageBenefit.combinedCoverageBenefit
                              .adjustedRemainingDeductible
                          ) ?? undefined
                        );
                        setOpen(false);
                      }}
                    >
                      <CheckCircleIcon
                        className={cn(
                          "mr-2 h-4 w-4 text-indigo-500",
                          recommended ? "opacity-100" : "opacity-0"
                        )}
                      />
                      <div className="flex items-center justify-between w-full">
                        <div>
                          {coverageBenefit.providerServiceConfiguration.name}
                        </div>
                        {mapNullable(formatUSD)(
                          coverageBenefit.combinedCoverageBenefit
                            .adjustedRemainingDeductible
                        ) ?? <span className="text-gray-500">N/A</span>}
                      </div>
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

const OutOfPocketInput: React.FC<{
  field: FieldArray<
    z.infer<
      typeof FormSchema
    >["insuranceServiceLines"][number]["serviceLines"][number]
  >;
  name: `insuranceServiceLines.${number}.serviceLines.${number}.remainingOutOfPocket`;
  benefits: {
    coverageBenefit: CoverageBenefit;
    recommended: boolean;
  }[];
}> = ({ field, name, benefits }) => {
  const form = useFormContext<z.infer<typeof FormSchema>>();
  const [open, setOpen] = useState(false);
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <Popover
            open={open}
            onOpenChange={(open) => {
              // Skip opening the popover if there are no benefits
              if (open && benefits.length === 0) {
                return;
              }
              setOpen(open);
            }}
          >
            <PopoverTrigger asChild>
              <FormControl>
                <CurrencyInput
                  decimalsLimit={2}
                  onValueChange={(val) => {
                    field.onChange(val ?? "");
                  }}
                  defaultValue={field.value}
                  value={field.value}
                  className={!isDefinedInput(field.value) ? "bg-gray-100" : ""}
                />
              </FormControl>
            </PopoverTrigger>
            <PopoverContent
              className="p-0"
              onOpenAutoFocus={(e) => {
                e.preventDefault();
              }}
            >
              <Command>
                <CommandEmpty>No benefits found.</CommandEmpty>
                <CommandGroup>
                  {benefits.map(({ coverageBenefit, recommended }) => (
                    <CommandItem
                      value={coverageBenefit.id}
                      key={coverageBenefit.id}
                      onSelect={() => {
                        form.setValue(
                          name,
                          mapNullable(toDollarStr)(
                            coverageBenefit.combinedCoverageBenefit
                              .adjustedRemainingOutOfPocket
                          ) ?? undefined
                        );
                        setOpen(false);
                      }}
                    >
                      <CheckCircleIcon
                        className={cn(
                          "mr-2 h-4 w-4 text-indigo-500",
                          recommended ? "opacity-100" : "opacity-0"
                        )}
                      />
                      <div className="flex items-center justify-between w-full">
                        <div>
                          {coverageBenefit.providerServiceConfiguration.name}
                        </div>
                        {mapNullable(formatUSD)(
                          coverageBenefit.combinedCoverageBenefit
                            .adjustedRemainingOutOfPocket
                        ) ?? <span className="text-gray-500">N/A</span>}
                      </div>
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

const LineItemFormRow: React.FC<{
  policyIdx: number;
  priorityOrder: number;
  index: number;
  appointment: Appointment;
  policy: InsurancePolicy | null;
  field: FieldArrayWithId<
    z.infer<typeof FormSchema>["insuranceServiceLines"][number],
    "serviceLines"
  >;
  className?: string;
  allowedAmountsLoading: boolean;
  visitChargemasterGroupIds: string[];
}> = ({
  policyIdx,
  priorityOrder,
  index,
  appointment,
  policy,
  field,
  className,
  allowedAmountsLoading,
  visitChargemasterGroupIds,
}) => {
  const apollo = useApolloClient();
  const form = useFormContext<z.infer<typeof FormSchema>>();

  const isPrimary = priorityOrder === 1;

  const [recommendedBenefit, setRecommendedBenefit] =
    useState<CoverageBenefit | null>(null);
  const mostRecentCoverageBenefits = policy
    ? policy.appointmentInNetwork
      ? policy.inNetworkBenefits
      : policy.outOfNetworkBenefits
    : [];
  const coverageBenefits = mostRecentCoverageBenefits.filter(
    (cb) =>
      cb.providerServiceConfiguration.serviceType !==
      ServiceTypeCode.HealthBenefitPlanCoverage
  );

  const onCoverageBenefitChange = (coverageBenefitId: string) => {
    const combinedCoverageBenefit = coverageBenefits.find(
      (cb) => cb.id === coverageBenefitId
    )?.combinedCoverageBenefit;
    if (combinedCoverageBenefit) {
      form.setValue(
        `insuranceServiceLines.${policyIdx}.serviceLines.${index}.coverageBenefitId`,
        coverageBenefitId
      );
      form.setValue(
        `insuranceServiceLines.${policyIdx}.serviceLines.${index}.copay`,
        mapNullable(toDollarStr)(combinedCoverageBenefit.copay) ?? ""
      );
      form.setValue(
        `insuranceServiceLines.${policyIdx}.serviceLines.${index}.coinsurance`,
        mapNullable(decimalToPercentage)(combinedCoverageBenefit.coinsurance) ??
          ""
      );
      form.setValue(
        `insuranceServiceLines.${policyIdx}.serviceLines.${index}.remainingDeductible`,
        mapNullable(toDollarStr)(
          combinedCoverageBenefit.adjustedRemainingDeductible
        ) ?? ""
      );
      form.setValue(
        `insuranceServiceLines.${policyIdx}.serviceLines.${index}.deductibleApplies`,
        combinedCoverageBenefit.deductibleApplies?.toString()
      );
      form.setValue(
        `insuranceServiceLines.${policyIdx}.serviceLines.${index}.remainingOutOfPocket`,
        mapNullable(toDollarStr)(
          combinedCoverageBenefit.adjustedRemainingOutOfPocket
        ) ?? ""
      );
    }
  };

  const getRecommendedBenefits = (
    chargemasterGroupId: string,
    visitChargemasterGroupIds: string[],
    update: boolean
  ) => {
    if (policy) {
      apollo
        .query<
          GetRecommendedBenefitForCharge,
          GetRecommendedBenefitForChargeVariables
        >({
          query: GET_RECOMMENDED_BENEFIT_FOR_CHARGE,
          variables: {
            appointmentId: appointment.id,
            insurancePolicyId: policy.id,
            chargemasterGroupId,
            visitChargemasterGroupIds,
          },
        })
        .then(({ data }) => {
          const recommendedConfigId =
            data?.getRecommendedBenefitForCharge?.providerServiceConfiguration
              ?.id;
          const recommended = coverageBenefits.find(
            (cb) => cb.providerServiceConfiguration.id === recommendedConfigId
          );
          setRecommendedBenefit(recommended ?? null);

          // If there's a recommended benefit, select it for the line item
          if (recommended && update) {
            onCoverageBenefitChange(recommended.id);
          }
        });
    }
  };

  useEffect(() => {
    if (field.chargemaster.chargemasterGroupId) {
      getRecommendedBenefits(
        field.chargemaster.chargemasterGroupId,
        visitChargemasterGroupIds,
        // Don't update benefit if form initialized with this estimated service line
        !field.existing
      );
    }
  }, [field.chargemaster.id]);

  const scheduledServiceFee = field.scheduledServiceFee;

  const benefits = mostRecentCoverageBenefits.map((cb) => ({
    coverageBenefit: cb,
    recommended:
      cb.providerServiceConfiguration.id ===
      recommendedBenefit?.providerServiceConfiguration?.id,
  }));

  // If this is billed with Medicare
  const medicarePolicy = appointment.insurancePolicies.find(
    (p) => p.planType?.insuranceType === InsuranceType.MEDICARE
  );

  // If COB with Medicare check if there's a coordination rule
  if (medicarePolicy && !isPrimary && policy?.planType) {
    const coordinationRule = getCoordinationRuleDisplay({
      planType: policy.planType,
    });
    if (coordinationRule) {
      return (
        <tr
          key={field.id}
          className={cn("border-b border-gray-200", className)}
        >
          <td className="max-w-0 py-2 px-2 text-sm">
            <div className="font-medium text-gray-900">
              <ChargemasterBadge chargemaster={field.chargemaster} />
            </div>
          </td>
          <td className="max-w-0 py-2 px-2 text-sm">
            <FormField
              control={form.control}
              name={`insuranceServiceLines.${0}.serviceLines.${index}.units`}
              render={({ field }) => (
                <Tooltip
                  trigger={
                    <Input
                      type="number"
                      step={1}
                      min={0}
                      disabled={true}
                      className="bg-gray-100"
                      {...field}
                    />
                  }
                  content={<>Using units from primary policy</>}
                />
              )}
            />
          </td>
          <td colSpan={7} className="py-2 px-2">
            {coordinationRule}
          </td>
        </tr>
      );
    }
  }

  return (
    <tr key={field.id} className={cn("border-b border-gray-200", className)}>
      <td className="max-w-0 py-2 px-2 text-sm">
        <div className="font-medium text-gray-900">
          <ChargemasterBadge chargemaster={field.chargemaster} />
        </div>
      </td>
      <td className="w-16 py-2 px-2 text-sm">
        {isPrimary ? (
          <FormField
            control={form.control}
            name={`insuranceServiceLines.${policyIdx}.serviceLines.${index}.units`}
            render={({ field }) => (
              <Input
                type="number"
                step={1}
                min={0}
                className={cn(
                  !isDefinedInput(field.value)
                    ? "bg-gray-100 border-red-500"
                    : ""
                )}
                {...field}
              />
            )}
          />
        ) : (
          // Show the allowed amount from the primary policy
          <Tooltip
            trigger={
              <FormField
                control={form.control}
                name={`insuranceServiceLines.${0}.serviceLines.${index}.units`}
                render={({ field }) => (
                  <Input
                    type="number"
                    step={1}
                    min={0}
                    disabled={true}
                    className={cn(
                      !isDefinedInput(field.value)
                        ? "bg-gray-100 border-red-500"
                        : ""
                    )}
                    {...field}
                  />
                )}
              />
            }
            content={<>Using units from primary policy</>}
          />
        )}
      </td>
      <td className="w-72 py-2 px-2 text-sm text-gray-500 table-cell">
        {coverageBenefits.length === 0 ? (
          <TextInput
            value={""}
            placeholder="No benefits tracked"
            className="bg-gray-50 text-gray-500 border-gray-200 border-dashed" // Subtle styling for no benefits
            disabled={true}
          />
        ) : (
          <FormField
            control={form.control}
            name={`insuranceServiceLines.${policyIdx}.serviceLines.${index}.coverageBenefitId`}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Select
                    onValueChange={(id) => {
                      onCoverageBenefitChange(id);
                      field.onChange(id);
                    }}
                    defaultValue={field.value}
                    value={field.value}
                  >
                    <FormControl>
                      <SelectTrigger
                        className={cn(
                          "truncate",
                          !isDefinedInput(field.value) &&
                            "border-dashed border-gray-300 bg-gray-50 text-gray-500", // Subtle styling for unmapped
                          field.value && "border-solid" // Solid border when mapped
                        )}
                      >
                        <SelectValue
                          placeholder={
                            <span className="text-gray-500 italic">
                              Select a benefit (optional)
                            </span>
                          }
                        />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="">
                        <span className="text-gray-500 italic">
                          No benefit mapped
                        </span>
                      </SelectItem>
                      {coverageBenefits.map((cb) => (
                        <SelectItem
                          key={index + "-" + cb.id}
                          value={cb.id}
                          className={cn(
                            "relative",
                            cb.empty && "text-gray-500",
                            cb.nonCoveredBenefit && "text-gray-500"
                          )}
                        >
                          <div className="flex items-center justify-between w-full gap-2">
                            <div className="flex items-center truncate">
                              {recommendedBenefit?.providerServiceConfiguration
                                ?.id === cb.providerServiceConfiguration.id && (
                                <CheckCircleIcon className="text-indigo-500 mr-2 h-4 flex-shrink-0" />
                              )}
                              <span className="truncate">
                                {cb.providerServiceConfiguration.name}
                              </span>
                            </div>
                            <div className="flex-shrink-0">
                              {cb.empty && (
                                <span className="text-xs text-gray-500 bg-gray-100 px-1.5 py-0.5 rounded">
                                  No benefits
                                </span>
                              )}
                              {cb.nonCoveredBenefit && (
                                <span className="text-xs text-gray-500 bg-gray-100 px-1.5 py-0.5 rounded">
                                  Not covered
                                </span>
                              )}
                            </div>
                          </div>
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
              </FormItem>
            )}
          />
        )}
      </td>
      <td className="w-24 py-2 px-2 text-right flex justify-end text-sm text-gray-500 table-cell">
        <FormField
          control={form.control}
          name={`insuranceServiceLines.${policyIdx}.serviceLines.${index}.copay`}
          render={({ field }) => (
            <CopayInput
              field={field}
              name={`insuranceServiceLines.${policyIdx}.serviceLines.${index}.copay`}
              benefits={benefits}
            />
          )}
        />
      </td>
      <td className="py-2 px-2 text-right flex justify-end text-sm text-gray-500 sm:pr-0">
        <FormField
          control={form.control}
          name={`insuranceServiceLines.${policyIdx}.serviceLines.${index}.coinsurance`}
          render={({ field }) => {
            return (
              <CoinsuranceInput
                field={field}
                name={`insuranceServiceLines.${policyIdx}.serviceLines.${index}.coinsurance`}
                benefits={benefits}
              />
            );
          }}
        />
      </td>
      <td className="w-24 py-2 px-2 text-right text-sm text-gray-500 sm:pr-0">
        <FormField
          control={form.control}
          name={`insuranceServiceLines.${policyIdx}.serviceLines.${index}.remainingDeductible`}
          render={({ field }) => (
            <DeductibleInput
              field={field}
              name={`insuranceServiceLines.${policyIdx}.serviceLines.${index}.remainingDeductible`}
              benefits={benefits}
            />
          )}
        />
      </td>
      <td className="w-20 py-2 px-2 text-center text-sm text-gray-500 sm:pr-0">
        <FormField
          control={form.control}
          name={`insuranceServiceLines.${policyIdx}.serviceLines.${index}.deductibleApplies`}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Checkbox
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  {...field}
                  defaultChecked={field.value === "true"}
                  checked={field.value === "true"}
                  onCheckedChange={(checked) => {
                    return field.onChange(checked.toString());
                  }}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </td>
      <td className="w-24 py-2 px-2 text-center text-sm text-gray-500 sm:pr-0">
        <FormField
          control={form.control}
          name={`insuranceServiceLines.${policyIdx}.serviceLines.${index}.remainingOutOfPocket`}
          render={({ field }) => (
            <OutOfPocketInput
              field={field}
              name={`insuranceServiceLines.${policyIdx}.serviceLines.${index}.remainingOutOfPocket`}
              benefits={benefits}
            />
          )}
        />
      </td>
      <td className="w-24 py-2 px-2 text-right text-sm text-gray-500 sm:pr-0">
        <div className="flex items-center gap-1">
          {isPrimary ? (
            <FormField
              control={form.control}
              name={`insuranceServiceLines.${policyIdx}.serviceLines.${index}.allowedAmount`}
              rules={{ required: true }}
              render={({ field }) =>
                allowedAmountsLoading ? (
                  <Input
                    type="number"
                    min={0}
                    max={100}
                    step={0.1}
                    adornment={<OvalSpinner className="w-2 h-2 -ml-1" />}
                    adornmentPosition="start"
                    className={
                      !isDefinedInput(field.value) ? "bg-gray-100" : ""
                    }
                    {...field}
                  />
                ) : (
                  <Tooltip
                    trigger={
                      <CurrencyInput
                        decimalsLimit={2}
                        onValueChange={(val) => {
                          field.onChange(val ?? "");
                        }}
                        defaultValue={field.value}
                        value={field.value}
                        className={cn(
                          !isDefinedInput(field.value)
                            ? "bg-gray-100 border-red-500"
                            : ""
                        )}
                      />
                    }
                    content={
                      scheduledServiceFee ? (
                        <>
                          Using price from{" "}
                          {scheduledServiceFee.feeSchedule.name} fee schedule
                        </>
                      ) : (
                        <>Not using a fee schedule</>
                      )
                    }
                  />
                )
              }
            />
          ) : (
            // Show the allowed amount from the primary policy
            <FormField
              control={form.control}
              name={`insuranceServiceLines.${0}.serviceLines.${index}.allowedAmount`}
              rules={{ required: true }}
              render={({ field }) => (
                <Tooltip
                  trigger={
                    allowedAmountsLoading ? (
                      <Input
                        type="number"
                        min={0}
                        max={100}
                        step={0.1}
                        adornment={<OvalSpinner className="w-2 h-2 -ml-1" />}
                        adornmentPosition="start"
                        disabled={true}
                        className={
                          !isDefinedInput(field.value) ? "bg-gray-100" : ""
                        }
                        {...field}
                      />
                    ) : (
                      <CurrencyInput
                        decimalsLimit={2}
                        onValueChange={(val) => {
                          field.onChange(val ?? "");
                        }}
                        defaultValue={field.value}
                        value={field.value}
                        disabled={true}
                        className={cn(
                          !isDefinedInput(field.value)
                            ? "bg-gray-100 border-red-500"
                            : ""
                        )}
                      />
                    )
                  }
                  content={<>Using allowed amount from primary policy</>}
                />
              )}
            />
          )}
        </div>
      </td>
    </tr>
  );
};

const PolicyServiceLineFormRows: React.FC<{
  field: FieldArrayWithId<z.infer<typeof FormSchema>, "insuranceServiceLines">;
  policyIdx: number;
  policiesLength: number;
  className?: string;
  policy: InsurancePolicy | null;
  appointment: Appointment;
  priorityOrder: number;
  swapPolicies: (fromIdx: number, toIdx: number) => void;
  allowedAmountsLoading: boolean;
}> = ({
  field,
  policyIdx,
  policiesLength,
  className,
  policy,
  appointment,
  priorityOrder,
  swapPolicies,
  allowedAmountsLoading,
}) => {
  const form = useFormContext<z.infer<typeof FormSchema>>();

  const serviceLines = useFieldArray({
    control: form.control,
    name: `insuranceServiceLines.${policyIdx}.serviceLines`,
  });
  const isSelected = field.enabled === "true";
  const mostRecentEligibility = policy?.mostRecentEligibilityRequest;

  const visitChargemasterGroupIds = serviceLines.fields
    .map((field) => field.chargemaster.chargemasterGroupId)
    .filter(isDefined)
    .sort();

  return (
    <Fragment>
      <tr className="border-b border-gray-200">
        <td colSpan={9}>
          <div className="flex gap-2 items-center py-2 w-full">
            <FormField
              control={form.control}
              name={`insuranceServiceLines.${policyIdx}.enabled`}
              render={({ field }) => (
                <FormItem>
                  <FormControl className="flex items-center">
                    <Checkbox
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      {...field}
                      defaultChecked={field.value === "true"}
                      onCheckedChange={(checked) => {
                        field.onChange(checked.toString());
                      }}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={`insuranceServiceLines.${policyIdx}.policyId`}
              render={({ field }) => (
                <FormItem className="hidden">
                  <FormControl>
                    <Input placeholder="Enter Policy Id" {...field} />
                  </FormControl>
                </FormItem>
              )}
            />

            {policy ? (
              <>
                {priorityOrder > 0 && (
                  <span className="flex h-5 w-5 shrink-0 items-center justify-center rounded-md border bg-gray-100 border-gray-50 text-[0.625rem] font-medium text-gray-400">
                    {priorityOrder}
                  </span>
                )}

                <div>{policy?.payer?.name}</div>

                <div className="text-gray-500 italic">{policy?.memberId}</div>

                {policy.planType && <PlanTypeSelect insurancePolicy={policy} />}

                {mostRecentEligibility ? (
                  <EligibilityStatusBadge
                    eligibilityRequest={mostRecentEligibility}
                    patientId={appointment.account.patient.id}
                    insurancePolicyId={policy.id}
                  />
                ) : (
                  <Badge text="Unverified" variant="info" clickable={false} />
                )}
                {policy && (
                  <NetworkBadge
                    orgPayerNetworkStatus={policy.appointmentInNetwork}
                  />
                )}

                <div className="grow flex gap-2 justify-end">
                  {policyIdx > 0 && (
                    <button
                      type="button"
                      onClick={() => {
                        swapPolicies(policyIdx, policyIdx - 1);
                      }}
                    >
                      <ArrowUpIcon className="h-4 w-4 text-gray-500 hover:text-gray-900" />
                    </button>
                  )}
                  {policyIdx < policiesLength - 1 && (
                    <button
                      type="button"
                      onClick={() => {
                        swapPolicies(policyIdx, policyIdx + 1);
                      }}
                    >
                      <ArrowDownIcon className="h-4 w-4 text-gray-500 hover:text-gray-900" />
                    </button>
                  )}
                </div>
              </>
            ) : (
              <div className="text-gray-500 italic">Cash Pay</div>
            )}
          </div>
        </td>
      </tr>
      {serviceLines.fields.map((field, index) => {
        return (
          <LineItemFormRow
            key={field.id}
            field={field}
            index={index}
            policyIdx={policyIdx}
            priorityOrder={priorityOrder}
            appointment={appointment}
            policy={policy}
            className={className}
            allowedAmountsLoading={allowedAmountsLoading}
            visitChargemasterGroupIds={visitChargemasterGroupIds}
          />
        );
      })}
    </Fragment>
  );
};

const GET_RECOMMENDED_BENEFIT_FOR_CHARGE = gql`
  ${COVERAGE_BENEFIT_FIELDS}
  query GetRecommendedBenefitForCharge(
    $insurancePolicyId: String!
    $appointmentId: String!
    $chargemasterGroupId: String!
    $visitChargemasterGroupIds: [String!]!
  ) {
    getRecommendedBenefitForCharge(
      insurancePolicyId: $insurancePolicyId
      appointmentId: $appointmentId
      chargemasterGroupId: $chargemasterGroupId
      visitChargemasterGroupIds: $visitChargemasterGroupIds
    ) {
      ...CoverageBenefitFields
    }
  }
`;

const ChargesDifferAlert: React.FC<{
  setOpen: (open: boolean) => void;
  onConfirm: () => void;
}> = ({ setOpen, onConfirm }) => (
  <div className="rounded-md bg-yellow-50 py-1 px-2">
    <div className="flex flex-col">
      <div className="flex items-center">
        <div className="flex items-center flex-shrink-0">
          <button
            type="button"
            className="group"
            onClick={() => {
              setOpen(false);
            }}
          >
            <XIcon
              className="h-5 w-5 text-yellow-400 group:hover:text-yellow-500"
              aria-hidden="true"
            />
          </button>
        </div>
        <div className="ml-3 flex-1 md:flex items-center md:justify-between">
          <p className="text-sm text-yellow-700">
            The charges used in the original estimate differ than those entered
            for this DOS.
          </p>
          <p className="mt-3 text-sm md:ml-6 md:mt-0">
            <button
              type="button"
              className="my-1 px-4 py-2 rounded-md whitespace-nowrap font-medium text-yellow-700 hover:text-yellow-600 hover:bg-yellow-500"
              onClick={onConfirm}
            >
              Re-estimate
            </button>
          </p>
        </div>
      </div>
    </div>
  </div>
);

const BenefitsDifferAlert: React.FC<{
  setOpen: (open: boolean) => void;
  onConfirm: () => void;
}> = ({ setOpen, onConfirm }) => (
  <div className="rounded-md bg-yellow-50 py-1 px-2">
    <div className="flex flex-col">
      <div className="flex items-center">
        <div className="flex items-center flex-shrink-0">
          <button
            type="button"
            className="group"
            onClick={() => {
              setOpen(false);
            }}
          >
            <XIcon
              className="h-5 w-5 text-yellow-400 group:hover:text-yellow-500"
              aria-hidden="true"
            />
          </button>
        </div>
        <div className="ml-3 flex-1 md:flex items-center md:justify-between">
          <p className="text-sm text-yellow-700">
            The benefits used in the original estimate have changed.
          </p>
          <p className="mt-3 text-sm md:ml-6 md:mt-0">
            <button
              type="button"
              className="my-1 px-4 py-2 rounded-md whitespace-nowrap font-medium text-yellow-700 hover:text-yellow-600 hover:bg-yellow-500"
              onClick={onConfirm}
            >
              Re-estimate
            </button>
          </p>
        </div>
      </div>
    </div>
  </div>
);

const PoliciesChangedAlert: React.FC<{
  setOpen: (open: boolean) => void;
  onConfirm: () => void;
}> = ({ setOpen, onConfirm }) => (
  <div className="rounded-md bg-yellow-50 py-1 px-2">
    <div className="flex flex-col">
      <div className="flex items-center">
        <div className="flex items-center flex-shrink-0">
          <button
            type="button"
            className="group"
            onClick={() => {
              setOpen(false);
            }}
          >
            <XIcon
              className="h-5 w-5 text-yellow-400 group:hover:text-yellow-500"
              aria-hidden="true"
            />
          </button>
        </div>
        <div className="ml-3 flex-1 md:flex items-center md:justify-between">
          <p className="text-sm text-yellow-700">
            New policies have been added to this account since the estimate was
            created.
          </p>
          <p className="mt-3 text-sm md:ml-6 md:mt-0">
            <button
              type="button"
              className="my-1 px-4 py-2 rounded-md whitespace-nowrap font-medium text-yellow-700 hover:text-yellow-600 hover:bg-yellow-500"
              onClick={onConfirm}
            >
              Re-estimate
            </button>
          </p>
        </div>
      </div>
    </div>
  </div>
);

const GET_SALTED_APPOINTMENT = gql`
  query GetSaltedAppointment($id: String!) {
    appointment(where: { id: $id }) {
      id
      start
      end
      appointmentLabelings {
        appointmentLabel {
          id
          name
        }
      }
      provider {
        displayName
      }
      account {
        patient {
          id
          displayName
          dateOfBirth
          birthSex
        }
        accountType {
          name
        }
      }
    }
  }
`;

export const SaltedCharges: React.FC<{
  appointmentId: string;
}> = ({ appointmentId }) => {
  const { data } = useQuery<GetSaltedAppointment>(GET_SALTED_APPOINTMENT, {
    variables: {
      id: appointmentId,
    },
  });

  return (
    <HoverCard.Root openDelay={100}>
      <HoverCard.Trigger>
        {
          <span className="inline-flex bg-blue-100 items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-gray-800 border hover:bg-blue-200">
            SALT
          </span>
        }
      </HoverCard.Trigger>
      <HoverCard.Content
        side="top"
        sideOffset={5}
        align="center"
        alignOffset={-200}
        className="z-50"
      >
        <div className="flex flex-col bg-white text-gray-900 drop-shadow-xl text-sm rounded-md p-4 border">
          {data?.appointment && (
            <div>
              <span className="text-lg pb-2 font-semibold">
                Charges used from patient's previous appointment
              </span>
              <AppointmentDisplay
                appointment={data.appointment}
                salted={true}
              />
            </div>
          )}
        </div>
      </HoverCard.Content>
    </HoverCard.Root>
  );
};

export const EstimateForm: React.FC<{
  appointment: Appointment;
  existingEstimate: Estimate | null;
  defaultChargeTemplate: ChargeTemplate | null;
  chargeTemplates: ChargeTemplate[];
  defaultFeeSchedule: FeeSchedule | null;
  feeSchedules: FeeSchedule[];
  defaultInsuranceServiceLines: InsuranceServiceLine[];
  defaultInsurancePolicyIds: string[];
  saltedBillId: string | null;
  saltedAppointmentId: string | null;
  chargesEntered: boolean;
  pastRenewalDateTimestamp: string | null;
  resetForm: () => void;
  onComplete?: () => void;
}> = ({
  appointment,
  existingEstimate,
  defaultChargeTemplate,
  chargeTemplates,
  defaultFeeSchedule,
  feeSchedules,
  defaultInsuranceServiceLines,
  defaultInsurancePolicyIds,
  saltedBillId,
  saltedAppointmentId,
  chargesEntered,
  pastRenewalDateTimestamp,
  resetForm,
  onComplete,
}) => {
  const apollo = useApolloClient();
  const [codeSearchOpen, setCodeSearchOpen] = useState(false);

  const chargesDifferFromBill = !!existingEstimate?.chargesDifferFromBill;
  const policiesChanged = !!existingEstimate?.policiesChanged;
  const [chargesDifferAlertOpen, setChargesDifferAlertOpen] = useState(
    chargesDifferFromBill
  );
  const [benefitsDifferAlertOpen, setBenefitsDifferAlertOpen] = useState(
    !!existingEstimate?.recommendedBenefitsDiffer
  );
  const [chargesChanged, setChargesChanged] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [policiesChangedAlertOpen, setPoliciesChangedAlertOpen] =
    useState(policiesChanged);

  const [estimate, setEstimate] = useState<
    Omit<EstimateCalculation, "__typename">
  >({
    insuranceResponsibilities: [],
    totalPatientResponsibility: 0,
    copayAmount: 0,
    coinsuranceAmount: 0,
    deductibleAmount: 0,
    outOfPocketAmount: 0,
    otherAmount: 0,
  });

  const [createEstimate, createEstimateResult] = useMutation<
    CreateEstimateFromForm,
    CreateEstimateFromFormVariables
  >(CREATE_ESTIMATE);

  const defaultCharges = defaultInsuranceServiceLines
    .at(0)
    ?.serviceLines?.map((line) => ({
      chargemaster: {
        id: line.chargemaster.id,
        code: line.chargemaster.code,
        description: line.chargemaster.description,
        modifier1: line.chargemaster.modifier1,
        modifier2: line.chargemaster.modifier2,
        modifier3: line.chargemaster.modifier3,
        modifier4: line.chargemaster.modifier4,
        chargemasterGroupId: line.chargemaster.chargemasterGroupId,
      },
      units: line.units,
    }));

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    reValidateMode: "onChange",
    defaultValues: {
      charges: defaultCharges,
      chargeTemplateId: defaultChargeTemplate?.id,
      feeScheduleId: defaultFeeSchedule?.id,
      //   insurancePolicyIds: defaultInsurancePolicyIds,
      insuranceServiceLines: defaultInsuranceServiceLines.map((policy) => ({
        policyId: policy.insurancePolicyId,
        enabled: defaultInsurancePolicyIds.includes(policy.insurancePolicyId)
          ? "true"
          : "false",
        serviceLines: policy.serviceLines.map((line) => ({
          existing: !!existingEstimate,
          chargemaster: {
            ...line.chargemaster,
            description: line.chargemaster.description ?? undefined,
            chargemasterGroupId:
              line.chargemaster.chargemasterGroupId ?? undefined,
          },
          units: line.units?.toString(),
          coverageBenefitId: line.coverageBenefitId ?? undefined,
          copay: mapNullable(toDollarStr)(line.copay) ?? undefined,
          coinsurance:
            mapNullable(decimalToPercentage)(line.coinsurance?.toString()) ??
            undefined,
          remainingDeductible:
            mapNullable(toDollarStr)(line.remainingDeductible) ?? undefined,
          deductibleApplies: line.deductibleApplies?.toString(),
          remainingOutOfPocket:
            mapNullable(toDollarStr)(line.remainingOutOfPocket) ?? undefined,
          allowedAmount:
            mapNullable((allowedAmount: number) =>
              toDollarStr(allowedAmount / (line.units ?? 1))
            )(line.allowedAmount) ?? undefined,
        })),
      })),
    },
  });

  const data = form.watch();

  const allPolicyIds = data.insuranceServiceLines.map(
    (policy) => policy.policyId
  );
  const selectedPolicyIds = data.insuranceServiceLines
    .filter((policy) => policy.enabled === "true")
    .map((policy) => policy.policyId);
  const isValid =
    selectedPolicyIds.length > 0 &&
    data.charges.length > 0 &&
    data.insuranceServiceLines.every(
      (policy) =>
        policy.enabled === "false" ||
        policy.serviceLines.every(
          (line) =>
            isDefinedInput(line.chargemaster.id) &&
            isDefinedInput(line.units) &&
            isDefinedInput(line.allowedAmount)
        )
    );
  const primaryId = selectedPolicyIds.at(0);
  const feeScheduleId = data.feeScheduleId;
  const insuranceServiceLines = form.watch("insuranceServiceLines");
  const primaryPolicy = insuranceServiceLines.find(
    (policy) => policy.policyId === primaryId
  );

  const [isCalculating, setIsCalculating] = useState(false);
  const latestRequestRef = useRef<number | null>(null);

  useEffect(() => {
    if (isValid) {
      const requestId = Date.now();
      latestRequestRef.current = requestId;
      setIsCalculating(true);

      const primaryServiceLines =
        data.insuranceServiceLines.find(
          (policy) => policy.policyId === primaryId
        )?.serviceLines ?? [];
      apollo
        .query<CalculateEstimate, CalculateEstimateVariables>({
          query: CALCULATE_ESTIMATE,
          fetchPolicy: "network-only",
          variables: {
            policyIds: allPolicyIds.map((id) => {
              const planType = appointment.insurancePolicies.find(
                (p) => p.id === id
              )?.planType;
              return {
                insurancePolicyId: id,
                cashPay: !id,
                active: selectedPolicyIds.includes(id),
                planType: planType
                  ? {
                      id: planType.id,
                      insuranceType: planType.insuranceType,
                      medicareType: planType.medicareType,
                      medigapPlanType: planType.medigapPlanType,
                      medicaidType: planType.medicaidType,
                      tricareType: planType.tricareType,
                    }
                  : null,
              };
            }),
            inputs: data.insuranceServiceLines.flatMap((policy) => {
              return policy.serviceLines.map((line) => {
                const primaryLine = primaryServiceLines.find(
                  (psl) => psl.chargemaster.id === line.chargemaster.id
                );
                return {
                  insurancePolicyId: policy.policyId,
                  chargemasterId: line.chargemaster.id,
                  units: parseInt(primaryLine?.units ?? line.units!),
                  allowedAmount: mapNullable((val: string) =>
                    toCents(parseFloat(val))
                  )(primaryLine?.allowedAmount ?? line.allowedAmount)!,
                  copay: mapNullable((val: string) => toCents(parseFloat(val)))(
                    line.copay
                  ),
                  coinsurance: mapNullable((val: string) =>
                    percentageToDecimal(val)
                  )(line.coinsurance),
                  remainingDeductible: mapNullable((val: string) =>
                    toCents(parseFloat(val))
                  )(line.remainingDeductible),
                  deductibleApplies: isDefined(line.deductibleApplies)
                    ? line.deductibleApplies === "true"
                    : null,
                  remainingOutOfPocket: mapNullable((val: string) =>
                    toCents(parseFloat(val))
                  )(line.remainingOutOfPocket),
                  coverageBenefitId: line.coverageBenefitId
                    ? line.coverageBenefitId
                    : null,
                  scheduledServiceFeeId:
                    primaryLine?.scheduledServiceFeeId ??
                    line.scheduledServiceFeeId ??
                    null,
                };
              });
            }),
          },
        })
        .then(({ data }) => {
          if (latestRequestRef.current === requestId) {
            setEstimate(data.calculateEstimate ?? estimate);
            setIsCalculating(false);
          }
        })
        .catch(() => {
          if (latestRequestRef.current === requestId) {
            setIsCalculating(false);
          }
        });
    }
  }, [JSON.stringify(data)]);

  const insurancePolicies = useFieldArray({
    control: form.control,
    name: "insuranceServiceLines",
  });
  const charges = useFieldArray({
    control: form.control,
    name: "charges",
  });

  // Only use the primary's charges
  const estimatedCharges = insurancePolicies.fields.at(0)?.serviceLines ?? [];
  const estimatedChargeChargemasterIds = estimatedCharges
    .map((ec) => ec.chargemaster.chargemasterGroupId)
    .filter(isDefined);

  const removeCharge = (chargemasterId: string) => {
    const chargeValues = form.getValues("charges");
    const insurancePoliciesValues = form.getValues("insuranceServiceLines");
    chargeValues.forEach((charge, idx) => {
      if (charge.chargemaster.id === chargemasterId) {
        charges.remove(idx);
      }
    });

    // Remove the charge from the insurance service lines
    const updatedInsurancePolicies = insurancePoliciesValues.map((policy) => ({
      ...policy,
      serviceLines: policy.serviceLines
        .filter((line) => line.chargemaster.id !== chargemasterId)
        .map((line) => ({
          ...line,
          // If replacing with undefined, doesn't clear out the existing value for the line so use ""
          copay: line.copay ?? "",
          coinsurance: line.coinsurance ?? "",
          remainingDeductible: line.remainingDeductible ?? "",
          deductibleApplies: line.deductibleApplies ?? "",
          remainingOutOfPocket: line.remainingOutOfPocket ?? "",
          allowedAmount: line.allowedAmount ?? "",
        })),
    }));
    insurancePolicies.replace(updatedInsurancePolicies);

    setChargesChanged(true);
  };

  const onFeeScheduleChange = (feeScheduleId: string) => {
    const feeSchedule = feeSchedules.find((ct) => ct.id === feeScheduleId);
    // If a fee schedule is selected, update the allowed amounts
    lookupAllowedAmounts(feeSchedule?.id ?? null);
  };

  const onChargeTemplateChange = (chargeTemplateId: string) => {
    const chargeTemplate = chargeTemplates.find(
      (ct) => ct.id === chargeTemplateId
    );
    // If a charge template is selected, update the charges to use the template
    if (chargeTemplate) {
      const templateCharges = chargeTemplate.chargeTemplateCharges;
      form.setValue(
        "charges",
        templateCharges.map((ctc) => ({
          chargemaster: ctc.chargemasterGroup.primaryChargemaster,
        }))
      );

      const updatedInsurancePolicies = form
        .getValues("insuranceServiceLines")
        .map((policy) => ({
          ...policy,
          // Default line items to empty
          serviceLines: templateCharges.map((ctc) => ({
            existing: false,
            chargemaster: {
              ...ctc.chargemasterGroup.primaryChargemaster,
              description:
                ctc.chargemasterGroup.primaryChargemaster.description ??
                undefined,
              chargemasterGroupId:
                ctc.chargemasterGroup.primaryChargemaster.chargemasterGroupId ??
                undefined,
            },
            units: ctc.units.toString(),
            coverageBenefitId: undefined,
            copay: undefined,
            coinsurance: undefined,
            remainingDeductible: undefined,
            deductibleApplies: undefined,
            remainingOutOfPocket: undefined,
            allowedAmount: undefined,
            scheduledServiceFee: undefined,
          })),
        }));

      form.resetField("insuranceServiceLines", {
        defaultValue: updatedInsurancePolicies,
      });

      lookupAllowedAmounts(
        feeScheduleId ?? null,
        templateCharges.map((c) => c.chargemasterGroup.id)
      );

      setChargesChanged(true);
    }
  };

  const estimatedChargesWithoutAllowedAmount = estimatedCharges.filter(
    (c) => !isDefinedInput(c.allowedAmount)
  );

  const [allowedAmountsLoading, setAllowedAmountsLoading] = useState(false);

  const lookupAllowedAmounts = (
    feeScheduleId: string | null,
    chargemasterGroupIds?: string[]
  ) => {
    setAllowedAmountsLoading(true);
    apollo
      .query<LookupAllowedAmounts, LookupAllowedAmountsVariables>({
        query: LOOKUP_ALLOWED_AMOUNTS,
        variables: {
          feeScheduleId,
          chargemasterGroupIds:
            chargemasterGroupIds ?? estimatedChargeChargemasterIds,
        },
      })
      .then(({ data, loading }) => {
        const fees = data.lookupAllowedAmounts;

        let i = 0;
        for (const policy of form.getValues("insuranceServiceLines")) {
          let j = 0;
          for (const line of policy.serviceLines) {
            const fee = fees.find(
              (fee) =>
                fee.chargemasterGroupId ===
                line.chargemaster.chargemasterGroupId
            );

            // If only specific fee was looked up then don't clear other fees
            if (
              !fee &&
              line.chargemaster.chargemasterGroupId &&
              chargemasterGroupIds?.includes(
                line.chargemaster.chargemasterGroupId
              )
            ) {
              // Do nothing
            } else {
              const includes =
                !isDefined(chargemasterGroupIds) ||
                (line.chargemaster.chargemasterGroupId &&
                  chargemasterGroupIds?.includes(
                    line.chargemaster.chargemasterGroupId
                  ));
              if (includes) {
                form.setValue(
                  `insuranceServiceLines.${i}.serviceLines.${j}.allowedAmount`,
                  mapNullable(toDollarStr)(fee?.allowedAmount)!
                );
                form.setValue(
                  `insuranceServiceLines.${i}.serviceLines.${j}.scheduledServiceFeeId`,
                  fee?.scheduledServiceFee?.id
                );
                form.setValue(
                  `insuranceServiceLines.${i}.serviceLines.${j}.scheduledServiceFee`,
                  fee?.scheduledServiceFee ?? undefined
                );
              }
            }
            j++;
          }
          i++;
        }
        setAllowedAmountsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setAllowedAmountsLoading(false);
      });
  };

  const isMounted = useIsMounted();
  //   When the primary policy changes, lookup the allowed amount and set it for all policies
  useEffect(() => {
    if (isMounted() && estimatedChargesWithoutAllowedAmount.length > 0) {
      lookupAllowedAmounts(feeScheduleId ?? null);
    }
  }, [
    feeScheduleId,
    isMounted(),
    // JSON.stringify(estimatedChargesWithoutAllowedAmount.map((c) => c.code)),
  ]);

  // On initial render, lookup allowed amounts if primary policy exists and there are missing allowed amounts
  useEffect(() => {
    if (!isMounted() && estimatedChargesWithoutAllowedAmount.length > 0) {
      lookupAllowedAmounts(feeScheduleId ?? null);
    }
  }, [
    isMounted(),
    feeScheduleId,
    JSON.stringify(
      estimatedChargesWithoutAllowedAmount.map((c) => c.chargemaster.id)
    ),
  ]);

  const totalAllowedAmount = (primaryPolicy?.serviceLines ?? [])
    .filter(
      (line) => isDefined(line.allowedAmount) && line.allowedAmount !== ""
    )
    .reduce((sum, line) => {
      const allowed = 100 * parseFloat(line?.allowedAmount ?? "0");
      const units = parseInt(line?.units ?? "0");
      return sum + units * allowed;
    }, 0);
  const totalInsuranceResponsibility =
    estimate.insuranceResponsibilities.reduce(
      (sum, resp) => sum + resp.insurance,
      0
    );

  const handleConfirm = () => {
    const primaryServiceLines =
      data.insuranceServiceLines.find((policy) => policy.policyId === primaryId)
        ?.serviceLines ?? [];
    const chargeTemplateId = data.chargeTemplateId
      ? data.chargeTemplateId
      : null;
    const feeScheduleId = data.feeScheduleId ? data.feeScheduleId : null;
    createEstimate({
      variables: {
        appointmentId: appointment.id,
        chargeTemplateId,
        feeScheduleId,
        saltedBillId:
          chargesChanged || chargeTemplateId || chargesEntered
            ? null
            : saltedBillId,
        policyIds: allPolicyIds.map((id) => ({
          insurancePolicyId: id,
          cashPay: !id,
          active: selectedPolicyIds.includes(id),
        })),
        isManual: true,
        inputs: data.insuranceServiceLines.flatMap((policy) => {
          return policy.serviceLines.map((line) => {
            const primaryLine = primaryServiceLines.find(
              (psl) => psl.chargemaster.id === line.chargemaster.id
            );
            return {
              insurancePolicyId: policy.policyId,
              chargemasterId: line.chargemaster.id,
              // Use the primary's units if the policy is not the primary
              units: parseInt(primaryLine?.units ?? line.units!),
              // Use the primary's allowed amount if the policy is not the primary
              allowedAmount: mapNullable((val: string) =>
                toCents(parseFloat(val))
              )(primaryLine?.allowedAmount ?? line.allowedAmount)!,
              copay: mapNullable((val: string) => toCents(parseFloat(val)))(
                line.copay
              ),
              coinsurance: mapNullable((val: string) =>
                percentageToDecimal(val)
              )(line.coinsurance),
              remainingDeductible: mapNullable((val: string) =>
                toCents(parseFloat(val))
              )(line.remainingDeductible),
              deductibleApplies: isDefined(line.deductibleApplies)
                ? line.deductibleApplies === "true"
                : null,
              remainingOutOfPocket: mapNullable((val: string) =>
                toCents(parseFloat(val))
              )(line.remainingOutOfPocket),
              coverageBenefitId: line.coverageBenefitId
                ? line.coverageBenefitId
                : null,
              // Use the primary's scheduled fee if the policy is not the primary
              scheduledServiceFeeId:
                primaryLine?.scheduledServiceFeeId ??
                line.scheduledServiceFeeId ??
                null,
            };
          });
        }),
      },
      onCompleted: (data) => {
        toast.success("Estimate created");
        onComplete?.();
      },
      onError: () => {
        toast.error("Failed to create estimate");
      },
    });
    setIsDialogOpen(false);
  };

  return (
    <div className="text-left relative">
      {/* Background color split screen for large screens */}
      <div
        className="absolute left-0 top-0 hidden h-full w-2/3 bg-white lg:block -z-10"
        aria-hidden="true"
      />
      <div
        className="absolute right-0 top-0 hidden h-full w-1/3 bg-gray-50 lg:block -z-10"
        aria-hidden="true"
      />

      <div className="relative mx-auto grid grid-cols-1 py-0 lg:gap-x-16 lg:grid-cols-3 px-8">
        <section
          aria-labelledby="estimate-summary"
          className="bg-gray-50 px-4 py-4 sm:px-6 order-2 lg:col-start-3 lg:row-start-1 lg:bg-transparent lg:px-0 lg:pb-16"
        >
          <div className="mx-auto max-w-lg lg:max-w-none">
            <h2
              id="estimate-summary"
              className="text-lg font-medium text-gray-900 flex gap-2 items-center"
            >
              Estimate Summary
              {isCalculating && (
                <OvalSpinner className="h-4 w-4 text-gray-700" />
              )}
            </h2>

            <dl className="space-y-6 border-b border-gray-200 pt-4 text-sm font-medium text-gray-900 lg:block">
              <div className="flex items-center justify-between pb-4">
                <dt className="text-gray-600 text-base">
                  Total Allowed Amount
                </dt>
                <dd>{formatUSD(totalAllowedAmount)}</dd>
              </div>
            </dl>

            <ul
              role="list"
              className="divide-y divide-gray-200 text-sm font-medium text-gray-900"
            >
              {estimate.insuranceResponsibilities
                .filter((resp) => resp.insurancePolicy)
                .map((resp, i) => (
                  <li
                    key={resp.insurancePolicy!.id}
                    className="flex items-start space-x-2 py-2"
                  >
                    <div className="flex-auto text-left space-y-1">
                      <h3>{resp.insurancePolicy!.payer.name}</h3>
                      <div className="flex gap-2">
                        <p className="text-gray-500">
                          {resp.insurancePolicy!.memberId}
                        </p>
                        <p className="text-gray-500">
                          {i === 0 ? "Primary" : "Secondary"}
                        </p>
                      </div>
                    </div>
                    <p className="flex-none text-base font-medium">
                      {formatUSD(-resp.insurance)}
                    </p>
                  </li>
                ))}
            </ul>

            <dl className="space-y-2 text-sm font-medium text-gray-900 lg:block">
              <div className="flex items-center justify-between border-t border-gray-200 py-4">
                <dt className="text-base">Total Insurance Responsibility</dt>
                <dd className="text-base">
                  {formatUSD(-totalInsuranceResponsibility)}
                </dd>
              </div>
            </dl>

            <dl className="space-y-2 border-t border-gray-200 pt-2 text-sm font-medium text-gray-900 lg:block">
              <div className="flex items-center justify-between">
                <dt className="text-gray-600">Copay</dt>
                <dd>{formatUSD(estimate.copayAmount)}</dd>
              </div>

              <div className="flex items-center justify-between">
                <dt className="text-gray-600">Coinsurance</dt>
                <dd>{formatUSD(estimate.coinsuranceAmount)}</dd>
              </div>

              <div className="flex items-center justify-between">
                <dt className="text-gray-600">Towards Deductible</dt>
                <dd>{formatUSD(estimate.deductibleAmount)}</dd>
              </div>

              <div className="flex items-center justify-between">
                <dt className="text-gray-600">
                  Covered by Out of Pocket Limit
                </dt>
                <dd>{formatUSD(estimate.outOfPocketAmount)}</dd>
              </div>

              <div className="flex items-center justify-between">
                <dt className="text-gray-600">Other</dt>
                <dd>{formatUSD(estimate.otherAmount ?? 0)}</dd>
              </div>

              <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                <dt className="text-base">Total Patient Responsibility</dt>
                <dd className="text-base">
                  {formatUSD(estimate.totalPatientResponsibility)}
                </dd>
              </div>
            </dl>

            <div className="py-8">
              <SubmitButton
                type="button"
                disabled={!isValid}
                loading={createEstimateResult.loading}
                onClick={() => {
                  if (Boolean(pastRenewalDateTimestamp)) {
                    setIsDialogOpen(true);
                  } else {
                    handleConfirm();
                  }
                }}
              >
                Save {formatUSD(estimate.totalPatientResponsibility)} Estimate
              </SubmitButton>
            </div>
          </div>
        </section>

        <Form {...form}>
          <form className="space-y-2 col-span-2">
            <section aria-labelledby="charges">
              <div className="flex flex-col gap-2">
                {chargesDifferAlertOpen && (
                  <ChargesDifferAlert
                    setOpen={setChargesDifferAlertOpen}
                    onConfirm={() => {
                      resetForm();
                    }}
                  />
                )}
                {benefitsDifferAlertOpen && (
                  <BenefitsDifferAlert
                    setOpen={setBenefitsDifferAlertOpen}
                    onConfirm={() => {
                      resetForm();
                    }}
                  />
                )}
                {policiesChangedAlertOpen && (
                  <PoliciesChangedAlert
                    setOpen={setPoliciesChangedAlertOpen}
                    onConfirm={() => {
                      resetForm();
                    }}
                  />
                )}
              </div>
              <div className="grid grid-cols-1 gap-2">
                <div>
                  <div className="flex items-center space-x-2">
                    <label
                      htmlFor="charges"
                      className="text-sm font-medium text-gray-700"
                    >
                      Charges
                    </label>
                    {saltedAppointmentId &&
                      !chargesChanged &&
                      !chargesEntered &&
                      !data.chargeTemplateId && (
                        <SaltedCharges appointmentId={saltedAppointmentId} />
                      )}
                  </div>
                  <div className="mt-1">
                    <div className="flex items-center gap-2 flex-wrap">
                      {charges.fields.map((field, idx) => {
                        const modifiers = [
                          field.chargemaster.modifier1,
                          field.chargemaster.modifier2,
                          field.chargemaster.modifier3,
                          field.chargemaster.modifier4,
                        ]
                          .filter((m) => !!m)
                          .join(" | ");
                        return (
                          <ChargemasterBadge
                            key={field.id}
                            chargemaster={field.chargemaster}
                            trigger={
                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-gray-800 border hover:bg-gray-50">
                                {field.chargemaster.code}
                                {modifiers && ` - ${modifiers}`}
                                <button
                                  type="button"
                                  className="group relative ml-1 -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20"
                                  onClick={() =>
                                    removeCharge(field.chargemaster.id)
                                  }
                                >
                                  <span className="sr-only">Remove</span>
                                  <svg
                                    viewBox="0 0 14 14"
                                    className="h-3.5 w-3.5 stroke-gray-600/50 group-hover:stroke-gray-600/75"
                                  >
                                    <path d="M4 4l6 6m0-6l-6 6" />
                                  </svg>
                                  <span className="absolute -inset-1" />
                                </button>
                              </span>
                            }
                          />
                        );
                      })}
                      <Button
                        type="button"
                        variant="secondary"
                        size="sm"
                        className="text-xs px-2 py-1 h-6"
                        onClick={() => {
                          setCodeSearchOpen(true);
                        }}
                      >
                        Add Charge +
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-2 pt-1">
                <div>
                  <label
                    htmlFor="charges"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Or select Charge Template
                  </label>
                  <div className="mt-1">
                    <FormField
                      control={form.control}
                      name="chargeTemplateId"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Select
                              onValueChange={(id) => {
                                onChargeTemplateChange(id);
                                // Pass on the change event to the react-hook-form field
                                field.onChange(id);
                              }}
                              defaultValue={field.value}
                            >
                              <FormControl>
                                <SelectTrigger>
                                  {field.value ? (
                                    <SelectValue placeholder="Select a charge template to use" />
                                  ) : (
                                    "Select a charge template to use"
                                  )}
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                {chargeTemplates.map((template) => (
                                  <SelectItem
                                    key={template.id}
                                    value={template.id}
                                  >
                                    {template.name}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="feeScheduleId"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Fee Schedule
                  </label>
                  <div className="mt-1">
                    <FormField
                      control={form.control}
                      name="feeScheduleId"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Select
                              onValueChange={(id) => {
                                onFeeScheduleChange(id);
                                // Pass on the change event to the react-hook-form field
                                field.onChange(id);
                              }}
                              defaultValue={field.value}
                            >
                              <FormControl>
                                <SelectTrigger>
                                  {field.value ? (
                                    <SelectValue placeholder="Select a fee schedule to use" />
                                  ) : (
                                    "Select a fee schedule to use"
                                  )}
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                {feeSchedules.map((feeSchedule) => (
                                  <SelectItem
                                    key={feeSchedule.id}
                                    value={feeSchedule.id}
                                  >
                                    {feeSchedule.name}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
              </div>
            </section>

            <div className="mt-4 flow-root overflow-auto">
              <div className="mx-auto">
                <table className="w-full text-left">
                  <thead className="border-b border-gray-300 text-gray-900">
                    <tr>
                      <th
                        scope="col"
                        className="py-1 px-2 text-left text-sm font-semibold text-gray-900"
                      >
                        Charge
                      </th>
                      <th
                        scope="col"
                        className="py-1 px-2 text-left text-sm font-semibold text-gray-900 w-16"
                      >
                        Units
                      </th>
                      <th
                        scope="col"
                        className="py-1 px-2 text-left text-sm font-semibold text-gray-900 table-cell w-72"
                      >
                        Benefit
                      </th>
                      <th
                        scope="col"
                        className="py-1 px-2 text-left text-sm font-semibold text-gray-900 table-cell w-24"
                      >
                        Copay
                      </th>
                      <th
                        scope="col"
                        className="py-1 px-2 text-left text-sm font-semibold text-gray-900 table-cell"
                      >
                        Coinsurance
                      </th>
                      <th
                        scope="col"
                        className="py-1 px-2 text-left text-sm font-semibold text-gray-900 table-cell w-24"
                      >
                        Deductible
                      </th>
                      <th
                        scope="col"
                        className="py-1 px-2 text-left text-sm font-semibold text-gray-900 table-cell w-20"
                      >
                        Ded Applies
                      </th>
                      <th
                        scope="col"
                        className="py-1 px-2 text-left text-sm font-semibold text-gray-900 table-cell w-24"
                      >
                        OOP
                      </th>
                      <th
                        scope="col"
                        className="py-1 px-2 text-left text-sm font-semibold text-gray-900 w-24 sm:pr-0"
                      >
                        Allowed Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {insurancePolicies.fields.map((field, index) => {
                      const currentPolicy =
                        appointment.insurancePolicies.find(
                          (p) => p.id === field.policyId
                        ) ?? null;
                      const priorityOrder =
                        selectedPolicyIds.findIndex(
                          (id) => id === field.policyId
                        ) + 1;

                      return (
                        <PolicyServiceLineFormRows
                          key={field.id}
                          field={field}
                          policy={currentPolicy}
                          policyIdx={index}
                          policiesLength={insurancePolicies.fields.length}
                          swapPolicies={insurancePolicies.swap}
                          className={cn(
                            "border-b border-gray-200",
                            priorityOrder > 0 ? "" : "hidden"
                          )}
                          appointment={appointment}
                          priorityOrder={priorityOrder}
                          allowedAmountsLoading={allowedAmountsLoading}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </form>
        </Form>
      </div>
      {codeSearchOpen && (
        <CodeSearchCommand
          open={codeSearchOpen}
          setOpen={setCodeSearchOpen}
          onSelect={(cm) => {
            if (cm) {
              const chargemaster = {
                ...cm.primaryChargemaster,
                description: cm.primaryChargemaster.description ?? undefined,
                chargemasterGroupId:
                  cm.primaryChargemaster.chargemasterGroupId ?? undefined,
              };
              charges.append({
                chargemaster: cm.primaryChargemaster,
              });
              form.setValue("chargeTemplateId", "");
              form.setValue(
                "insuranceServiceLines",
                data.insuranceServiceLines.map((policy) => {
                  return {
                    ...policy,
                    serviceLines: [
                      ...policy.serviceLines,
                      {
                        existing: false,
                        chargemaster,
                        units: "1",
                        coverageBenefitId: undefined,
                        copay: "",
                        coinsurance: "",
                        remainingDeductible: "",
                        deductibleApplies: "",
                        remainingOutOfPocket: "",
                        allowedAmount: "",
                        scheduledServiceFeeId: undefined,
                      },
                    ],
                  };
                })
              );
              // Lookup allowed amounts of new code
              lookupAllowedAmounts(feeScheduleId ?? null, [cm.id]);

              setChargesChanged(true);
            }
          }}
        />
      )}
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              Policy will be renewed on {pastRenewalDateTimestamp}
            </DialogTitle>
          </DialogHeader>
          <div>
            One of the patient's policies is set to renew before their
            appointment. This estimate will be based on benefits that may change
            at the time of appointment.
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsDialogOpen(false)}>
              Cancel
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setIsDialogOpen(false);
                handleConfirm();
              }}
            >
              Estimate Anyway
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};
