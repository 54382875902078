import { gql, useMutation } from "@apollo/client";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { SettingsLayout } from "..";
import { Card, Spinner } from "../../../components";
import { Button } from "../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import { Switch } from "../../../components/ui/switch";
import { Textarea } from "../../../components/ui/textarea";
import {
  GetMyOrganization_me_organization_locations as Location,
  GetMyOrganization_me_organization as Organization,
} from "../../../generated/GetMyOrganization";
import {
  CancellationPolicyPreviewSection,
  PolicyMarkdown,
} from "../../../portal/pages/payments/patient-visit-page";

const UPDATE_LOCATION_SETTINGS = gql`
  mutation UpdateLocationSettings(
    $id: String!
    $name: String!
    $address1: String!
    $city: String!
    $state: String!
    $country: String!
    $postalCode: String!
    $cancellationPolicy: String
    $cancellationPolicyPreview: String
  ) {
    updateOneLocation(
      where: { id: $id }
      data: {
        name: { set: $name }
        address1: { set: $address1 }
        city: { set: $city }
        state: { set: $state }
        country: { set: $country }
        postalCode: { set: $postalCode }
        cancellationPolicy: { set: $cancellationPolicy }
        cancellationPolicyPreview: { set: $cancellationPolicyPreview }
      }
    ) {
      id
      name
      address1
      city
      state
      country
      postalCode
      cancellationPolicy
      cancellationPolicyPreview
    }
  }
`;

type LocationForm = {
  name: string;
  address1: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  cancellationPolicyEnabled: boolean;
  cancellationPolicy?: string;
  cancellationPolicyPreview?: string;
};

const CancellationPolicyPreview: React.FC<{
  policy: string;
  preview?: string;
}> = ({ policy, preview }) => {
  const [showDialog, setShowDialog] = useState(false);

  // Get first paragraph for preview if no preview is provided
  const previewText = preview || policy.split("\n")[0];

  return (
    <>
      <div className="border rounded-md p-4 bg-gray-50">
        <CancellationPolicyPreviewSection
          policy={policy}
          preview={previewText}
        />
      </div>

      <Dialog open={showDialog} onOpenChange={setShowDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Cancellation Policy Preview</DialogTitle>
          </DialogHeader>
          <div className="mt-4 text-sm text-gray-600 space-y-1.5">
            <PolicyMarkdown markdown={policy} />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const LocationForm: React.FC<{
  organization: Organization;
  location: Location;
}> = ({ organization, location }) => {
  const [updateLocation, updateLocationResult] = useMutation(
    UPDATE_LOCATION_SETTINGS
  );

  const form = useForm<LocationForm>({
    defaultValues: {
      name: location.name,
      address1: location.address1,
      city: location.city,
      state: location.state,
      country: location.country,
      postalCode: location.postalCode,
      cancellationPolicyEnabled: !!location.cancellationPolicy,
      cancellationPolicy: location.cancellationPolicy ?? "",
      cancellationPolicyPreview: location.cancellationPolicyPreview ?? "",
    },
  });
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    reset,
  } = form;

  const cancellationPolicyEnabled = watch("cancellationPolicyEnabled");
  const cancellationPolicy = watch("cancellationPolicy");

  useEffect(() => {
    // Default the preview to first 80 chars if not set
    if (!form.getValues("cancellationPolicyPreview") && cancellationPolicy) {
      form.setValue(
        "cancellationPolicyPreview",
        cancellationPolicy.slice(0, 80)
      );
    }
  }, [cancellationPolicy]);

  const onSubmit = (data: LocationForm) => {
    console.log(data);
    updateLocation({
      variables: {
        id: location.id,
        name: data.name,
        address1: data.address1,
        city: data.city,
        state: data.state,
        country: data.country,
        postalCode: data.postalCode,
        cancellationPolicy: data.cancellationPolicyEnabled
          ? data.cancellationPolicy || null
          : null,
        cancellationPolicyPreview: data.cancellationPolicyEnabled
          ? data.cancellationPolicyPreview || null
          : null,
      },
      onCompleted: () => {
        toast.success("Settings updated");
      },
      onError: (error) => {
        toast.error("Error updating settings");
      },
    });
  };

  return (
    <SettingsLayout
      title={location.name}
      description="Manage location settings."
    >
      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-4">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Name
                  </label>
                  <div className="mt-1">
                    <input
                      {...register("name")}
                      className="appearance-none
                          block
                          w-full
                          px-3
                          py-2
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          placeholder-gray-400
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                        "
                    />
                  </div>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="country"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Country / Region
                  </label>
                  <select
                    required
                    {...register("country")}
                    className="appearance-none
                          block
                          w-full
                          px-3
                          py-2
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          placeholder-gray-400
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                        "
                  >
                    <option value="United States">United States</option>
                    <option value="Canada">Canada</option>
                    <option value="Mexico">Mexico</option>
                  </select>
                </div>

                <div className="col-span-6">
                  <label
                    htmlFor="address1"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Street address
                  </label>
                  <input
                    required
                    type="text"
                    {...register("address1")}
                    className="appearance-none
                          block
                          w-full
                          px-3
                          py-2
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          placeholder-gray-400
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                        "
                  />
                </div>

                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium text-gray-700"
                  >
                    City
                  </label>
                  <input
                    type="text"
                    required
                    {...register("city")}
                    className="appearance-none
                          block
                          w-full
                          px-3
                          py-2
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          placeholder-gray-400
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                        "
                  />
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label
                    htmlFor="state"
                    className="block text-sm font-medium text-gray-700"
                  >
                    State / Province
                  </label>
                  <input
                    type="text"
                    required
                    {...register("state")}
                    className="appearance-none
                          block
                          w-full
                          px-3
                          py-2
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          placeholder-gray-400
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                        "
                  />
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label
                    htmlFor="postalCode"
                    className="block text-sm font-medium text-gray-700"
                  >
                    ZIP / Postal
                  </label>
                  <input
                    type="text"
                    required
                    {...register("postalCode")}
                    className="appearance-none
                          block
                          w-full
                          px-3
                          py-2
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          placeholder-gray-400
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                        "
                  />
                </div>
              </div>
              <div className="border-t pt-6">
                <div className="flex items-center justify-between mb-4">
                  <div>
                    <h3 className="text-lg font-medium text-gray-900">
                      Cancellation Policy
                    </h3>
                    <p className="text-sm text-gray-500">
                      Enable and configure your location's cancellation policy
                    </p>
                  </div>
                  <FormField
                    control={control}
                    name="cancellationPolicyEnabled"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </div>

                {cancellationPolicyEnabled && (
                  <div className="space-y-4">
                    <FormField
                      control={control}
                      name="cancellationPolicy"
                      rules={{
                        required: {
                          value: true,
                          message:
                            "Cancellation policy is required when enabled",
                        },
                        maxLength: {
                          value: 2000,
                          message:
                            "Cancellation policy must be less than 2000 characters",
                        },
                        validate: {
                          hasContent: (value) =>
                            (value?.trim().length ?? 0) > 0 ||
                            "Cancellation policy cannot be empty",
                        },
                      }}
                      render={({ field }) => (
                        <FormItem>
                          <div className="flex justify-between items-center">
                            <FormLabel>Policy Text</FormLabel>
                            <span className="text-sm text-muted-foreground">
                              {field.value?.length ?? 0}/2000
                            </span>
                          </div>
                          <FormControl>
                            <Textarea
                              {...field}
                              placeholder="Enter your cancellation policy"
                              className="min-h-[200px] whitespace-pre-wrap font-mono"
                            />
                          </FormControl>
                          <FormDescription className="space-y-1">
                            <p>
                              Format your policy text with these guidelines:
                            </p>
                            <ul className="list-disc list-inside pl-4 space-y-1">
                              <li>
                                Use{" "}
                                <a
                                  href="https://www.markdownguide.org/basic-syntax/"
                                  target="_blank"
                                  className="text-blue-500 hover:text-blue-600"
                                >
                                  Markdown formatting
                                </a>{" "}
                                to format your policy text.
                              </li>
                              <li>Each heading should be on its own line</li>
                              <li>Use clear, concise language</li>
                              <li>
                                Include any fees or charges associated with
                                cancellations
                              </li>
                              <li>Specify the timeframe for cancellations</li>
                            </ul>
                          </FormDescription>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={control}
                      name="cancellationPolicyPreview"
                      rules={{
                        maxLength: {
                          value: 200,
                          message: "Preview must be less than 200 characters",
                        },
                      }}
                      render={({ field }) => (
                        <FormItem>
                          <div className="flex justify-between items-center">
                            <FormLabel>Preview Text</FormLabel>
                            <span className="text-sm text-muted-foreground">
                              {field.value?.length ?? 0}/200
                            </span>
                          </div>
                          <FormControl>
                            <Textarea
                              {...field}
                              placeholder="Enter a short preview of your cancellation policy"
                              className="whitespace-pre-wrap"
                              onChange={(e) => {
                                // Prevent typing more than 200 chars
                                if (e.target.value.length <= 200) {
                                  field.onChange(e);
                                }
                              }}
                            />
                          </FormControl>
                          <FormDescription>
                            This preview will be shown to patients before they
                            expand the full policy. If not provided, the first
                            paragraph of the policy will be used.
                          </FormDescription>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    {cancellationPolicy && (
                      <div>
                        <div className="my-2">Cancellation Policy Preview</div>
                        <CancellationPolicyPreview
                          policy={cancellationPolicy}
                          preview={form.getValues("cancellationPolicyPreview")}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Card>
          <div className="pt-2 pr-1 text-right">
            <div className="flex justify-end items-center gap-2">
              {updateLocationResult.loading && (
                <Spinner className="text-indigo-600 h-5" />
              )}
              <Button type="submit" disabled={updateLocationResult.loading}>
                Save Changes
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </SettingsLayout>
  );
};

export const WorkspaceLocationSettings: React.FC<{
  organization: Organization;
}> = ({ organization }) => {
  const { locationId } = useParams<{ locationId: string }>();
  const location = organization.locations.find((l) => l.id === locationId)!;
  return (
    <LocationForm
      key={locationId}
      location={location}
      organization={organization}
    />
  );
};
