import { useMutation } from "@apollo/client";
import {
  BriefcaseIcon,
  CloudIcon,
  MinusIcon,
  UserIcon,
} from "@heroicons/react/outline";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@radix-ui/react-hover-card";
import { ColumnDef } from "@tanstack/react-table";
import { compareAsc, format, formatRelative, parseISO } from "date-fns";
import { TrendingDownIcon, TrendingUpIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner, Tooltip } from "../../components";
import { Badge } from "../../components/ui/badge";
import { Card } from "../../components/ui/card";
import { Checkbox } from "../../components/ui/checkbox";
import { DataTableColumnHeader } from "../../components/ui/table-helpers/data-table-column-header";
import {
  GetEstimationWorkflowAppointments_estimationWorkflowAppointments as Appointment,
  GetEstimationWorkflowAppointments_estimationWorkflowAppointments_estimationWorkflowStatus as EstimationWorkflowStatus,
  GetEstimationWorkflowAppointments_estimationWorkflowAppointments_insurancePolicies as InsurancePolicy,
} from "../../generated/GetEstimationWorkflowAppointments";
import { InsurancePolicyVerificationStatus } from "../../generated/globalTypes";
import {
  RequestEligibility,
  RequestEligibilityVariables,
} from "../../generated/RequestEligibility";
import { useUser } from "../../user-context";
import { formatUSD, isDefined, mapNullable } from "../../utils";
import { REQUEST_APPOINTMENT_ELIGIBILITY } from "../appointments";
import { MemberIdCell, PolicyStatusCellRow } from "../verifications/columns";
import { REQUEST_ELIGIBILITY } from "../worklists/policies/split-pane";
import { EstimationWorkflowStatusIndicator } from "./list";

export type EstimationWorklistRow = {
  id: string;
  // The timestamp of the last workflow status change
  timestamp: Date | null;
  appointment: Appointment;
  // The name of the estimation workflow status
  status: string;
  providerName: string | null;
  estimationWorkflowStatus: EstimationWorkflowStatus;
  patientName: string;
  appointmentLabels: string[];
  start: Date | null;
  lastEstimationError: string | null;
  estimate: number | null;
  insurancePolicies: InsurancePolicy[];
  payer: string | null;
  memberId: string | null;
  verificationStatus: InsurancePolicyVerificationStatus | null;
  syncStatus: EstimateSyncStatus;
};

export type EstimateSyncStatus =
  | "Not Synced"
  | "Previously Synced"
  | "Synced"
  | "Unable to Sync";

export const PatientCell: React.FC<{
  patientId: string;
  patientName: string;
  accounts: {
    accountType: { name: string | null } | null;
  }[];
}> = ({ patientId, patientName, accounts }) => {
  const accountsDisplay = accounts
    .map((account) => account.accountType?.name)
    .filter(isDefined)
    .join(", ");
  return (
    <div className="flex flex-col">
      <Link
        to={`/patients/${patientId}`}
        className="text-sm text-indigo-500 hover:text-indigo-400"
      >
        {patientName}
      </Link>
      <div className="flex items-center gap-1">
        {accountsDisplay && (
          <div className="flex items-center gap-1 text-xs">
            <BriefcaseIcon className="h-4" />
            <span className="text-ellipsis truncate max-w-[12em]">
              {accountsDisplay}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export const AppointmentCell: React.FC<{
  appointment: Pick<
    Appointment,
    "start" | "end" | "provider" | "appointmentLabelings"
  > | null;
}> = ({ appointment }) => {
  return (
    <div>
      <div className="text-sm text-gray-900">
        {appointment ? (
          <div className="flex flex-col text-sm text-gray-900 truncate">
            <div>
              {format(parseISO(appointment.start), "MM/dd/yyyy h:mm aa")}
              {isDefined(appointment.end) &&
                `- ${format(parseISO(appointment.end), "h:mm aa")}`}
            </div>
            <div className="flex items-center gap-1">
              {appointment.provider && (
                <div className="flex items-center gap-1 text-xs">
                  <UserIcon className="h-4" />
                  <span className="text-ellipsis truncate max-w-[10em]">
                    {appointment.provider?.displayName}
                  </span>
                </div>
              )}
              <div className="flex items-center gap-1">
                {appointment.appointmentLabelings.map((labeling) => (
                  <Badge
                    key={labeling.appointmentLabel.id}
                    className="px-1 py-[1px] font-normal"
                  >
                    {labeling.appointmentLabel.name}
                  </Badge>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="text-sm text-gray-700 italic">
            No upcoming appointments
          </div>
        )}
      </div>
    </div>
  );
};

const EligibilityStatusCell: React.FC<{
  appointment: Appointment;
  insurancePolicy: InsurancePolicy;
  patientId: string;
}> = ({ appointment, insurancePolicy, patientId }) => {
  const user = useUser()!;
  const [requestEligibility, requestEligibilityResult] = useMutation<
    RequestEligibility,
    RequestEligibilityVariables
  >(REQUEST_ELIGIBILITY, {
    onCompleted: () => {
      toast.success("Eligibility request successful");
    },
    onError: () => {
      toast.error("Failed to check eligibility");
    },
  });
  const [requestAppointmentEligibility, requestAppointmentEligibilityResult] =
    useMutation(REQUEST_APPOINTMENT_ELIGIBILITY, {
      onCompleted: (data) => {
        toast.success("Eligibility request successful");
      },
      onError: (error) => {
        toast.error("Failed to check eligibility");
      },
    });

  const eligibilityLoading =
    requestAppointmentEligibilityResult.loading ||
    requestEligibilityResult.loading;

  const eligibilityEnabled =
    insurancePolicy.payer.eligibilityEnabled &&
    (appointment.provider?.eligibilityEnabled ||
      user.activeLocation.defaultEligibilityProvider);
  const defaultProvider = user.activeLocation.defaultEligibilityProvider;

  const onSubmit = async (insurancePolicyId: string) => {
    if (appointment.provider?.eligibilityEnabled) {
      await requestAppointmentEligibility({
        variables: {
          appointmentId: appointment.id,
          insurancePolicyId,
          referenceDate: new Date(),
        },
      });
    } else {
      await requestEligibility({
        variables: {
          insurancePolicyId,
          providerId: defaultProvider!.id,
        },
      });
    }
  };

  return (
    <div className="flex items-center gap-1">
      <PolicyStatusCellRow
        insurancePolicy={insurancePolicy}
        patientId={patientId}
      />
      <button
        className="flex items-center rounded-full p-1 hover:bg-gray-100 hover:text-gray-600 disabled:opacity-50 disabled:cursor-not-allowed"
        onClick={(e) => {
          e.stopPropagation();
          onSubmit(insurancePolicy.id);
        }}
        disabled={eligibilityLoading || !eligibilityEnabled}
      >
        <Spinner
          className="h-4 w-4 text-gray-500"
          spinning={eligibilityLoading}
        />
      </button>
    </div>
  );
};

const EstimationErrorCell: React.FC<{
  row: EstimationWorklistRow;
}> = ({ row }) => {
  const error = row.lastEstimationError;
  const patientId = row.appointment.account.patient.id;
  switch (error) {
    case EstimationError.NoInsurancePolicy:
      return (
        <Tooltip
          trigger={
            <Link
              to={`/patients/${patientId}/edit`}
              className="inline-flex justify-center items-center min-w-[8em] rounded-md border border-transparent px-2.5 py-1.5 text-xs text-gray-500 hover:text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed"
            >
              No Policy
            </Link>
          }
          content={<>No insurance policy used</>}
        />
      );
    case EstimationError.NoBenefitsTracked:
      const primary = row.insurancePolicies.at(0);
      const link = primary
        ? `/patients/${patientId}/insurances/${primary.id}`
        : `/patients/${patientId}`;
      return (
        <Tooltip
          trigger={
            <Link
              to={link}
              className="inline-flex justify-center items-center min-w-[8em] rounded-md border border-transparent px-2.5 py-1.5 text-xs text-gray-500 hover:text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed"
            >
              No Benefits
            </Link>
          }
          content={<>No benefits tracked for policy</>}
        />
      );
    case EstimationError.NoBenefitsMapped:
      return (
        <Tooltip
          trigger={
            <Link
              to={`/rules/benefit-mappings/new`}
              className="inline-flex justify-center items-center min-w-[8em] rounded-md border border-transparent px-2.5 py-1.5 text-xs text-gray-500 hover:text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed"
            >
              Benefits Unmatched
            </Link>
          }
          content={<>No benefits mapping rule matched</>}
        />
      );
    case EstimationError.MissingAllowedAmount:
      return (
        <Tooltip
          trigger={
            <Link
              to={`/chargemaster`}
              className="inline-flex justify-center items-center min-w-[8em] rounded-md border border-transparent px-2.5 py-1.5 text-xs text-gray-500 hover:text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed"
            >
              Missing Allowed Amount
            </Link>
          }
          content={<>One of the charges is missing an allowed amount</>}
        />
      );
  }
  return <>{error}</>;
};

export const SyncStatusBadge: React.FC<{
  visitCollectionRequest?: {
    amount: number;
    lastSavedToIntegrationAt: string | null;
    savedToIntegration: {
      name: string;
    } | null;
    savedToIntegrationBy: {
      firstName: string;
      lastName: string;
    } | null;
  } | null;
  previousVisitCollectionRequest?: {
    amount: number;
    lastSavedToIntegrationAt: string | null;
    savedToIntegration: {
      name: string;
    } | null;
    savedToIntegrationBy: {
      firstName: string;
      lastName: string;
    } | null;
  } | null;
}> = ({ visitCollectionRequest, previousVisitCollectionRequest }) => {
  const amountChanged =
    visitCollectionRequest?.amount !== undefined &&
    previousVisitCollectionRequest?.amount !== undefined &&
    visitCollectionRequest.amount !== previousVisitCollectionRequest.amount;

  const increased =
    amountChanged &&
    visitCollectionRequest!.amount > previousVisitCollectionRequest!.amount;

  const decreased =
    amountChanged &&
    visitCollectionRequest!.amount < previousVisitCollectionRequest!.amount;

  // Check if we have a previous synced version first
  const hasPreviousSyncedVersion =
    previousVisitCollectionRequest?.lastSavedToIntegrationAt;
  const currentVersionSynced = visitCollectionRequest?.lastSavedToIntegrationAt;

  // If there's no previous version and current isn't synced, show Not Synced
  if (!hasPreviousSyncedVersion && !currentVersionSynced) {
    return (
      <div className="flex">
        <Badge
          variant="outline"
          className="flex gap-1 items-center border-gray-300 bg-gray-50 text-gray-500"
        >
          <CloudIcon className="h-5 w-5 text-gray-500" />
          <div>Not synced</div>
        </Badge>
      </div>
    );
  }
  if (!currentVersionSynced && amountChanged) {
    // If we have a previous synced version but current isn't synced, show Previous Version Synced
    return (
      <HoverCard>
        <HoverCardTrigger>
          <div className="flex">
            <Badge
              variant="outline"
              className="flex gap-1 items-center border-yellow-300 bg-yellow-50 text-yellow-500"
            >
              <CloudIcon className="h-5 w-5" />
              <div>Previous Version Synced</div>
            </Badge>
          </div>
        </HoverCardTrigger>
        <HoverCardContent side="top" sideOffset={4} className="max-w-96 z-[99]">
          <Card className="bg-white">
            <div className="flex flex-col gap-2 p-2 text-wrap">
              <div className="flex flex-col gap-1">
                <div className="text-sm text-gray-600">
                  Previous version synced to{" "}
                  {previousVisitCollectionRequest?.savedToIntegration?.name}{" "}
                  {previousVisitCollectionRequest.lastSavedToIntegrationAt && (
                    <>
                      {formatRelative(
                        parseISO(
                          previousVisitCollectionRequest!
                            .lastSavedToIntegrationAt!
                        ),
                        new Date()
                      )}
                    </>
                  )}
                  {previousVisitCollectionRequest?.savedToIntegrationBy
                    ?.firstName ? (
                    <>
                      by{" "}
                      {
                        previousVisitCollectionRequest?.savedToIntegrationBy
                          ?.firstName
                      }
                      {previousVisitCollectionRequest?.savedToIntegrationBy
                        ?.lastName ?? ""}{" "}
                    </>
                  ) : null}
                </div>
              </div>
              {amountChanged && (
                <dl className="space-y-6 px-2 py-1 rounded-md text-sm bg-blue-50 text-blue-700 lg:block">
                  <div className="flex items-center justify-between gap-4">
                    <dd className="flex items-center gap-2">
                      {increased && (
                        <TrendingUpIcon className="h-4 w-4 text-blue-500" />
                      )}
                      {decreased && (
                        <TrendingDownIcon className="h-4 w-4 text-blue-500" />
                      )}
                      <span className="font-semibold">
                        Amount updated from{" "}
                        {formatUSD(previousVisitCollectionRequest!.amount)} to{" "}
                        {formatUSD(visitCollectionRequest!.amount)}
                      </span>
                    </dd>
                  </div>
                </dl>
              )}
            </div>
          </Card>
        </HoverCardContent>
      </HoverCard>
    );
  }

  return (
    <HoverCard>
      <HoverCardTrigger>
        <div className="flex">
          <Badge
            variant="outline"
            className="flex gap-1 items-center border-green-300 bg-green-50 text-green-500"
          >
            <CloudIcon className="h-5 w-5" />
            <div>Synced</div>
          </Badge>
        </div>
      </HoverCardTrigger>
      <HoverCardContent side="top" sideOffset={4} className="max-w-96 z-[99]">
        <Card>
          <div className="flex flex-col gap-2 p-2 text-wrap">
            <div className="text-sm text-gray-600">
              Last synced to {visitCollectionRequest?.savedToIntegration?.name}{" "}
              {visitCollectionRequest?.savedToIntegrationBy?.firstName ? (
                <>
                  by {visitCollectionRequest?.savedToIntegrationBy?.firstName}{" "}
                  {visitCollectionRequest?.savedToIntegrationBy?.lastName ?? ""}{" "}
                </>
              ) : null}
              {visitCollectionRequest?.lastSavedToIntegrationAt && (
                <>
                  {formatRelative(
                    parseISO(visitCollectionRequest.lastSavedToIntegrationAt),
                    new Date()
                  )}
                </>
              )}
            </div>
          </div>
        </Card>
      </HoverCardContent>
    </HoverCard>
  );
};

const SyncStatusCell: React.FC<{ row: EstimationWorklistRow }> = ({ row }) => {
  if (!isDefined(row.estimate)) {
    return (
      <div className="flex justify-center">
        <MinusIcon className="h-5 w-5 text-gray-300" />
      </div>
    );
  }

  const currentVisitCollectionRequest =
    row.appointment.mostRecentVisitCollectionRequest ?? null;
  const previousVisitCollectionRequest =
    row.appointment.visitCollectionRequests.find(
      (request) =>
        request.id !== currentVisitCollectionRequest?.id &&
        isDefined(request.lastSavedToIntegrationAt)
    ) ?? null;

  return (
    <div className="flex justify-center">
      <SyncStatusBadge
        visitCollectionRequest={currentVisitCollectionRequest}
        previousVisitCollectionRequest={previousVisitCollectionRequest}
      />
    </div>
  );
};

export const columns: ColumnDef<EstimationWorklistRow>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        onClick={(e) => {
          e.stopPropagation();
        }}
        aria-label="Select row"
        className="translate-y-[2px]"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: "start",
    accessorKey: "start",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Appointment" />
    ),
    cell: ({ row }) => {
      const appointment = row.original.appointment;
      return (
        <div className="[&:first-letter]:capitalize">
          {formatRelative(parseISO(appointment.start), new Date())}
          {isDefined(appointment.end) &&
            `- ${format(parseISO(appointment.end), "h:mm aa")}`}
        </div>
      );
    },
  },
  {
    accessorKey: "status",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Status" />
    ),
    // Sort by the timestamp of the last status change
    sortingFn: (rowA, rowB, columnId) => {
      if (!rowA.original.timestamp || !rowB.original.timestamp) return 0;
      return compareAsc(rowA.original.timestamp, rowB.original.timestamp);
    },
    cell: ({ row }) => {
      const appointment = row.original.appointment;
      const timestamp = row.original.timestamp;
      if (!timestamp) {
        return (
          <div className="flex gap-1 items-center">
            <EstimationWorkflowStatusIndicator
              status={appointment.estimationWorkflowStatus}
            />
            {appointment.estimationWorkflowStatus.name}
          </div>
        );
      }
      return (
        <Tooltip
          trigger={
            <div className="flex gap-1 items-center">
              <EstimationWorkflowStatusIndicator
                status={appointment.estimationWorkflowStatus}
              />
              {appointment.estimationWorkflowStatus.name}
            </div>
          }
          content={<>{formatRelative(timestamp, new Date())}</>}
        />
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    id: "patientName",
    accessorKey: "patientName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Patient" />
    ),
    cell: ({ row }) => {
      const appointment = row.original.appointment;
      const patient = appointment.account.patient;
      return (
        <PatientCell
          patientId={patient.id}
          patientName={row.original.patientName}
          accounts={[appointment.account]}
        />
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(
        row.original.appointment.account.accountType?.name ?? ""
      );
    },
  },
  {
    accessorKey: "appointmentLabels",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Appointment Labels" />
    ),
    filterFn: (row, id, value) => {
      return row.original.appointmentLabels.some((label) =>
        value.includes(label)
      );
    },
  },
  {
    accessorKey: "providerName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Provider" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    id: "payerName",
    accessorKey: "payerName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Payer" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex flex-col gap-1.5">
          {row.original.insurancePolicies.map((policy) => (
            <div key={policy.id}>{policy.payer.name}</div>
          ))}
        </div>
      );
    },
    filterFn: (row, id, value) => {
      const payers = row.original.insurancePolicies.map(
        (policy) => policy.payer.name
      );
      return payers.some((payer) => value.includes(payer));
    },
  },
  {
    id: "memberId",
    accessorKey: "memberId",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Member Id" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex flex-col gap-1.5">
          {row.original.insurancePolicies.map((policy) => (
            <MemberIdCell
              key={policy.id}
              insurancePolicy={policy}
              patientId={row.original.appointment.account.patient.id}
            />
          ))}
        </div>
      );
    },
    filterFn: (row, id, value) => {
      const memberIds = row.original.insurancePolicies.map(
        (policy) => policy.memberId
      );
      return memberIds.some((memberId) => value.includes(memberId));
    },
  },
  {
    id: "verificationStatus",
    accessorKey: "verificationStatus",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Eligibility Status" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex flex-col gap-1.5">
          {row.original.insurancePolicies.map((policy) => (
            <EligibilityStatusCell
              key={policy.id}
              insurancePolicy={policy}
              appointment={row.original.appointment}
              patientId={row.original.appointment.account.patient.id}
            />
            // <div className="flex items-center gap-1">
            //   <PolicyStatusCellRow
            //     key={policy.id}
            //     insurancePolicy={policy}
            //     patientId={row.original.appointment.account.patient.id}
            //   />
            //   <button
            //     className="flex items-center rounded-full p-1 hover:bg-gray-100 hover:text-gray-600"
            //     onClick={(e) => {
            //       e.stopPropagation();
            //       // onSubmit(insurancePolicy.id);
            //     }}
            //     // disabled={eligibilityLoading}
            //   >
            //     <Spinner
            //       className="h-4 w-4 text-gray-500"
            //       // spinning={eligibilityLoading}
            //       spinning={false}
            //     />
            //   </button>
            // </div>
          ))}
        </div>
      );
    },
    filterFn: (row, id, value) => {
      const verifcationStatuses = row.original.insurancePolicies.map(
        (policy) => policy.insurancePolicyVerificationStatus
      );
      return verifcationStatuses.some((status) => value.includes(status));
    },
  },
  {
    accessorKey: "lastEstimationError",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Last Estimation Error" />
    ),
    cell: ({ row }) => {
      return <EstimationErrorCell row={row.original} />;
    },
  },
  {
    accessorKey: "estimate",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Estimate" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex justify-center">
          {mapNullable(formatUSD)(row.original.estimate) ?? (
            <MinusIcon className="w-4 text-gray-300" />
          )}
        </div>
      );
    },
  },
  {
    id: "syncStatus",
    accessorKey: "syncStatus",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Sync Status" />
    ),
    cell: ({ row }) => <SyncStatusCell row={row.original} />,
    enableHiding: true,
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
];

// TODO: add to gql schema
export enum EstimationError {
  NoInsurancePolicy = "NoInsurancePolicy",
  NoBenefitsTracked = "NoBenefitsTracked",
  NoChargeTemplate = "NoChargeTemplate",
  NoBenefitsMapped = "NoBenefitsMapped",
  MissingAllowedAmount = "MissingAllowedAmount",
}
